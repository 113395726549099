<template>
  <!-- Dialogo para indicar errores -->
  <v-dialog
    v-model="parametros.dialogError"
    max-width="400"
  >
    <v-card>
      <!-- <v-progress-linear
        v-if="mostrarLoader"
        :value="progreso"
        color="primary"
        height="10"
      ></v-progress-linear> -->
    	<v-card-text>
  			<v-card class="elevation-0 text-center pt-4">
  				<span class="text-headline" style="white-space: pre-line">
  				 	{{ parametros.mensaje }}
  				</span>
  			</v-card>
    	</v-card-text>
    	<v-card-actions>
		    <v-btn 
          :color="parametros.color" 
          dark 
          block 
          @click="parametros.dialogError = false" 
          class="text-capitalize elevation-8"
          large
          > 
            Ok!
          </v-btn>
    	</v-card-actions>
    </v-card>
  </v-dialog>
</template>


<script>
import axios from 'axios';
	export default {

    data() {
      return {
        mostrarLoader: false, // Controla si el loader está visible
        progreso: 0,         // Valor del progreso (0 a 100)
      };
    },

		props:[
			'parametros'
	  ],
	}

</script>