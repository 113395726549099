<template>
  <v-dialog
    v-model="dialogEfectividad.estatus"
    max-width="1800"
    persistent
  >
    <v-card class="shadowCard">
		  <v-card-title primary-title>
		    <span class="text-subtitle-1">Reporte de efectividad</span>
		    <v-spacer></v-spacer>

        <v-btn 
          class="mr-2" 
          small dark color="green"
          @click="exportExcel( mensajesZuleima  , 'ASIGNACION_TWILIO')"
          tile
        >
          <v-icon small>mdi-microsoft-excel</v-icon>
        </v-btn>

        <v-btn
          color="primary"
          dark
          class="text-capitalize"
          @click="initialize()"
          small
          tile
        >
          <v-icon small>mdi-refresh</v-icon>
        </v-btn>
        
		  </v-card-title>

		  <v-card-text>
		  	<v-row justify="end">

		  		<v-col cols="12" md="4">
		  			<v-text-field
		  			  name="name"
		  			  label="Buscar"
		  			  id="id"
		  			  filled
		  			  dense
		  			  single-line
		  			  hide-details
		  			  v-model="fechaini"
		  			  type="date"
		  			>
		  			</v-text-field>
		  		</v-col>

		  		<v-col cols="12" md="8">
		  			<v-text-field
		  			  name="name"
		  			  label="Buscar"
		  			  id="id"
		  			  filled
		  			  dense
		  			  single-line
		  			  hide-details
		  			  append-icon="mdi-magnify"
		  			  v-model="search"
		  			>
		  			</v-text-field>
		  		</v-col>
		  	</v-row>

        <v-row>
          <v-col cols="12" class="black--text text-capitalize" >
            <b>T. Total:         </b> {{ promedioTiempo }}<br/>
            <b>T. Inactividad:   </b> {{ tiempoInactivo }}<br/>
            <b>T. Activo:        </b> {{ tiempoActivo }}<br/>
            <b>Prom Activo:      </b> {{ promedioEfectivo }}<br/>
          </v-col>  
        </v-row>

		    <v-row>
		    	<v-col cols="12">
					  <v-data-table
					    :headers="headers"
					    :items="vendedoras"
					    class="elevation-0"
					    :search="search"
					    :mobile-breakpoint="100"
	            dense
					  >

              <template v-slot:item.utlimocontacto="{ item }">
                <v-icon color="green" small right v-if="item.minutos < 20">mdi-circle</v-icon>
                <v-icon color="yellow" small right v-if="item.minutos >= 20 && item.minutos <= 59 ">mdi-circle</v-icon>
                <v-icon color="red" small right v-if="item.minutos >= 60">mdi-circle</v-icon>
                <span @click="verMensajes( item )">{{ item.utlimocontacto }}</span>
              </template>

              <template v-slot:item.espera="{ item }">
                <v-btn 
                  color="orange" 
                  x-small
                  @click="tabla = item.espera_C, dialogContactos = true"
                  class="mr-2"
                  dark
                >
                  {{item.espera}}
                </v-btn>
              </template>

              <template v-slot:item.verdes="{ item }">
                <v-btn 
                  color="green" 
                  x-small
                  @click="tabla = item.verdes_C, dialogContactos = true"
                  class="mr-2"
                  dark
                >
                  {{item.verdes}}
                </v-btn>
              </template>

              <template v-slot:item.amarillos_mios="{ item }">
                <v-btn 
                  color="yellow" 
                  x-small
                  @click="tabla = item.amarillos_mios_C, dialogContactos = true"
                  class="mr-2"
                >
                  {{item.amarillos_mios}}
                </v-btn>
              </template>

              <template v-slot:item.rojos_mios="{ item }">
                <v-btn 
                  color="red" 
                  x-small
                  @click="tabla = item.rojos_mios_C, dialogContactos = true"
                  class="mr-2"
                  dark
                >
                  {{item.rojos_mios}}
                </v-btn>
              </template>


              <template v-slot:item.verdes_mios="{ item }">
                <v-btn 
                  color="green" 
                  x-small
                  @click="tabla = item.verdes_mios_C, dialogContactos = true"
                  class="mr-2"
                  dark
                >
                  {{item.verdes_mios}}
                </v-btn>
              </template>

              <template v-slot:item.amarillos="{ item }">
                <v-btn 
                  color="yellow" 
                  x-small
                  @click="tabla = item.amarillos_C, dialogContactos = true"
                  class="mr-2"
                >
                  {{item.amarillos}}
                </v-btn>
              </template>

              <template v-slot:item.rojos="{ item }">
                <v-btn 
                  color="red" 
                  x-small
                  @click="tabla = item.rojos_C, dialogContactos = true"
                  class="mr-2"
                  dark
                >
                  {{item.rojos}}
                </v-btn>
              </template>


					    <template v-slot:no-data>
					      <v-btn
					        color="primary"
					        @click="initialize"
					        small
					      >
					        Actualizar
					      </v-btn>
					    </template>

					  </v-data-table>
		    	</v-col>
		    </v-row>
		  </v-card-text>
		  <v-card-actions>
		  	<v-btn 
		  		color="black"
		  		dark
		  		small
		  		tile
		  		@click="dialogEfectividad.estatus = false"
		  	>
		  		<v-icon small left>mdi-close</v-icon>
		  		Cerrar
		  	</v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
		</v-card>

    <!-- Dialogo para eliminar -->
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
      	<v-card-text>
	      	<v-row justify="space-around">
	      		<v-col cols="6">
	      			<v-card class="elevation-0" height="100%" style="display: table;">
		      			<div class="text-subtitle-1" style="display: table-cell; vertical-align: middle;">
		      				<strong>Eliminar</strong> ¿Estás seguro que deseas eliminar los datos?
			      			<v-card-actions class="pt-6">
			      				<!-- Cerrar dialogo de confirmación y abrir el dialogo de editar la clase -->
			      			  <v-btn color="error" text  class="mr-2" @click="closeDelete()">No, cancelar</v-btn>
			      			  <!-- Guardar la información  -->
			      			  <v-btn color="#5C5C5C"  dark class="elevation-6"  @click="deleteItemConfirm()">Si</v-btn>
			      			</v-card-actions>
		      			</div>
	      			</v-card>
	      		</v-col>
	      		<v-col cols="5">
	      			<v-img src="@/assets/borrar.png"></v-img>
	      		</v-col>
		      </v-row>
      	</v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialogContactos"
      max-width="1000px"
      transition="dialog-transition"
    >
      <v-card>
        <v-card-title class="text-subtitle-1">
          Contactos 
        </v-card-title>
        <v-card-text>
          <v-data-table
            :headers="headersContactos"
            :items="tabla"
            class="elevation-0"
            :mobile-breakpoint="100"
            dense
          >
          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialogVerMensaje"
      max-width="700px"
      persistent
      transition="dialog-transition"
    >
      <v-card>
        <v-card-title class="text-subtitle-1">
          Último mensaje enviado 
        </v-card-title>
        <v-card-text v-if="mensaje" class="black--text">
          Usuario: {{ mensaje.usuario }}
          <br/>
          Fecha: {{ mensaje.fecha_ultimo_mensaje }}
          <br/>
          Mensaje: 
          <br/>
          <div>
            <!-- Tipo de texto es textooo -->
            <p 
              class="text" 
              v-if="mensaje.type == 1"
              style="white-space: pre-line"
              @contextmenu="show(mensaje, $event)"
            >
              {{ mensaje.mensaje }}
            </p>

            <!-- Tipo de texto es Imagennnnn -->
            <p 
              class="text" 
              v-if="mensaje.type == 2"
              style="white-space: pre-line"
              @click="verImagen( mensaje.MediaUrl0 )"
              type="button"
              @contextmenu="show(mensaje, $event)"
            >
              <img v-if="mensaje.mio == 0 && !mensaje.fake" :src="url + mensaje.MediaUrl0" alt="Red dot" width="300" />
              <img v-if="mensaje.mio == 1 && !mensaje.fake" :src="url2 + mensaje.MediaUrl0" alt="Red dot" width="300" />

              <!-- Ruta para imagenes fake -->
              <img v-if="mensaje.fake" :src="mensaje.imagenFake" alt="Red dot" width="300" />
            </p>

            <!-- Para cuando se envían stickers -->
            <p 
              class="text" 
              v-if="mensaje.type == 'sticker'"
              style="white-space: pre-line"
              @contextmenu="show(mensaje, $event)"
            >
              <img v-if="mensaje.mio == 0" :src="url + mensaje.MediaUrl0" alt="Red dot" width="150"/>
              <img v-if="mensaje.mio == 1" :src="url2 + mensaje.MediaUrl0" alt="Red dot" width="150"/>
            </p>

            <!-- Tipo PDF -->
            <p 
              class="text" 
              v-if="mensaje.type == 4"
              style="white-space: pre-line"
              @contextmenu="show(mensaje, $event)"
            >
              <v-chip
                color="#f1f3f4"
                label
                text-color="black"
              >
                <v-icon left>
                  mdi-file-pdf-box
                </v-icon>

              </v-chip>
                <embed v-if="mensaje.mio == 0 && !mensaje.fake" :src="url + mensaje.MediaUrl0 " type="application/pdf"  width="100%" height="350px" />
                <embed v-if="mensaje.mio == 1 && !mensaje.fake" :src="url2 + mensaje.MediaUrl0 " type="application/pdf" width="100%" height="350px" />

                <embed v-if="mensaje.fake" :src="`${mensaje.imagenFake}#toolbar=0`" type="application/pdf" width="100%" height="350px" />
            </p>

            <!-- Tipo Audio -->
            <p 
              class="text pa-0 pt-2 px-2 audio" 
              v-if="mensaje.type == 3"
              style="white-space: pre-line; width: 300px;"
              @contextmenu="show(mensaje, $event)"
            >
              <audio controls style="width: 100%;">
                <source v-if="mensaje.mio == 0" :src="url + mensaje.MediaUrl0" type="audio/ogg" >
                <source v-if="mensaje.mio == 1" :src="url2 + mensaje.MediaUrl0" :type="`audio/${mensaje.MediaUrl0.substring(mensaje.MediaUrl0.length - 3, mensaje.MediaUrl0.length) == 'mp4' ? 'mp4' : 'mp3'}`" >
              </audio>
            </p>

            <!-- Tipo video -->
            <p 
              class="text" 
              v-if="mensaje.type == 5"
              style="white-space: pre-line"
            >
              <video controls width="300">
                <source v-if="mensaje.mio == 0" :src="url + mensaje.MediaUrl0">
                <source v-if="mensaje.mio == 1" :src="url2 + mensaje.MediaUrl0">
              </video>
            </p>

            <!-- Mensaje -->
            <p 
              class="text mt-2" 
              v-if="mensaje.type == 2 && mensaje.mensaje"
              style="white-space: pre-line"
            >
              {{ mensaje.mensaje }}
            </p>

            <!-- Mensaje -->
            <p 
              class="text mt-2" 
              v-if="mensaje.type == 4 && mensaje.mensaje"
              style="white-space: pre-line"
            >
              {{ mensaje.mensaje }}
            </p>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-btn
            absolute
            top
            right
            icon
            @click="mensaje = null, dialogVerMensaje = false"
          ><v-icon>mdi-close</v-icon></v-btn>
        </v-card-actions>               
      </v-card>
    </v-dialog>

    <!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>

  </v-dialog>
</template>
<script>
import axios from 'axios';
import { mapGetters } from "vuex";
// Componentes
import Alerta from "@/components/alertas/Alerta.vue";
import carga from "@/components/alertas/carga.vue";

import validarErrores from "@/mixins/validarErrores";
import funcionesExcel from "@/mixins/funcionesExcel";

export default {

  mixins: [ validarErrores, funcionesExcel ],

  props: ['dialogEfectividad','escuela', 'nivel','leccion', 'contadorVendedoras'],

  components: {
    Alerta,
    carga,
  },

	data: () => ({
    // Alertas
    parametros:{
      dialogError: false,
      mensaje: '',
      color: ''
    },

    respuestaAlerta:false,
    loader: true,
    cargar: false,

    dialog: false,
    dialogDelete: false,

    fechaini: null,

		search:'',
    usuarios: [],
    planteles:[],
    headers: [
      { text: "Vendedora"        , value: "vendedora"         },
      { text: "Contactados"      , value: "contactados"       },
      { text: "Mensajes S"       , value: "mensajes_enviados" },
      { text: "Hora inicio"      , value: "primer_contacto"   },
      { text: "Hora final"       , value: "ultimo_contacto"   },
      { text: "T. Total"         , value: "tiempo_total"      },
      { text: "> Inactividad"    , value: "mayor_inactividad" },
      { text: "T. Inactivo"      , value: "tiempo_inactivo"   },
      { text: "T. Activo"        , value: "tiempo_activo"     },
    ],

    headersContactos:[
      { text: "Folio"         , value: "folio"                  },
      { text: "Telefono"      , value: "usuario"                },
      { text: "Nombre"        , value: "nombre_completo"        },
      { text: "Zona"          , value: "zona"                   },
      { text: "T. Espera"     , value: "tiempo_espera"          },
    ],
    tabla:[],
    dialogContactos: false,


    mensaje: null,
    dialogVerMensaje: false,
    url :'https://testfast.club/imagenes-whatsapp/',
    url2: 'https://escuelakpi.club/imagenes-whatsapp/',
    promedioTiempo: 0,
    tiempoEfectivo:0,
    tiempoActivo: 0,
    tiempoInactivo:0,

    vendedoras: [],
    promedioEfectivo:0,

    fechasUnicas: [],
    datosFast: [],
    datosInbi: [],
    dataTablaInscritos: [],
    mensajesZuleima:[]

  }),

  computed: {
  	...mapGetters('login',['getdatosUsuario']),
    formTitle () {
      return this.editedIndex === -1 ? 'Agregar Diapositiva' : 'Editar Diapositiva'
    },

  },

  watch: {
    dialogDelete (val) {
      val || this.closeDelete( )
    },

    fechaini ( val ){
      val || this.initialize( )
    }
  },

  async created () {
    // await this.initialize()
  },

  methods: {


    initialize() {
      this.cargar = true;
      this.vendedoras = []

      const payload = {
        escuela : this.escuela,
        fechaini: this.fechaini
      }

      return axios.post('reporte.efectividad', payload ).then(response => {

        this.promedioTiempo   = response.data.tiempoTotalfinal
        this.tiempoEfectivo   = response.data.tiempoEfectivo
        this.tiempoActivo     = response.data.tiempoActivo
        this.tiempoInactivo   = response.data.tiempoInactivo
        this.promedioEfectivo = response.data.promedioEfectivo

        // DATOS PARA LA GRAFICA

        this.mensajesZuleima  = response.data.mensajesZuleima

        this.vendedoras = response.data.vendedoras

        this.cargar = false;

      }).catch(error => {
        this.validarError(error.response.data.message);
      }).finally(() => {
        this.cargar = false;
      });
    },


    close () {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    closeDelete () {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    verMensajes( item ){
      this.mensaje = null
      this.mensaje = item.utlimomensaje
      this.dialogVerMensaje = true
    }

  },
}
</script>
<style scoped>
  .dropZone {
    width: 80%;
    height: 150px;
    position: relative;
    border: 2px dashed #eee;
  }

  .dropZone:hover {
    border: 2px solid #D8D8D8;
  }

  .dropZone:hover .dropZone-title {
    color: #1975A0;
  }

  .dropZone-info {
    color: #A8A8A8;
    position: absolute;
    top: 50%;
    width: 100%;
    transform: translate(0, -50%);
    text-align: center;
  }

  ..dropZone-title {
    color: #787878;
  }

  .dropZone input {
    position: absolute;
    cursor: pointer;
    top: 0px;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
  }

  .dropZone-upload-limit-info {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
  }

  .dropZone-over {
    background: #D8D8D8;
    opacity: 0.8;
  }

  .dropZone-uploaded {
    width: 80%;
    height: 200px;
    position: relative;
    border: 2px dashed #eee;
  }

  .dropZone-uploaded-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #D8D8D8;
    position: absolute;
    top: 50%;
    width: 100%;
    transform: translate(0, -50%);
    text-align: center;
  }

  .removeFile {
    width: 200px;
  }

  .chat {
    width: calc(100%);
  }

  .chat .messages-chat {
    padding: 25px 10px;
  }

  .chat .messages-chat .message {
    display:flex;
    align-items: center;
    margin-bottom: 2px;
  }

  .chat .messages-chat .message .photo {
    display: block;
    width: 45px;
    height: 45px;
    background: #E6E7ED;
    -moz-border-radius: 50px;
    -webkit-border-radius: 50px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .chat .messages-chat .text {
    margin: 0 35px;
    background-color: #fff;
    padding: 15px;
    border-radius: 12px;
  }

  .text-only {
    white-space: pre-line;

  }

  .time {
    font-size: 12px;
    color:lightgrey;
    margin-bottom:10px;
    margin-left: 38px;
  }

  .response-time {
    float: right;
    margin-right: 40px !important;
  }

  .response {
    float: right;
    margin-right: 0px !important;
    margin-left: auto; /* flexbox alignment rule */
    max-width: 90%;
  }

  .noresponse {
    max-width: 90%;
  }

  .noresponse .text{
    box-shadow: 0 20px 27px 0 rgba(0,0,0,0.05)  !important;
  }

  .response .text {
    background-color: #c5e6c1 !important;
    color: black;
    box-shadow: 0 20px 27px 0 rgba(0,0,0,0.05)  !important;

  }

  .audio {
    background-color: #f1f3f4 !important;
  }

  .response .audio {
    background-color: #f1f3f4 !important;
  }

  .footer-chat {
    width: calc(65% - 66px);
    height: 80px;
    display:flex;
    align-items: center;
    position:absolute;
    bottom: 0;
    background-color: transparent;
    border-top: 2px solid #EEE;

  }

  .chat .footer-chat .icon {
    margin-left: 30px;
    color:#C0C0C0;
    font-size: 14pt;
  }

  .chat .footer-chat .send {
    color:#fff;
    background-color: #4f6ebd;
    position: absolute;
    right: 50px;
    padding: 12px 12px 12px 12px;
    border-radius: 50px;
    font-size: 14pt;
  }

  .chat .footer-chat .name {
    margin: 0 0 0 20px;
    text-transform: uppercase;
    font-family:'Montserrat', sans-serif;
    font-size: 13pt;
    color:#515151;
  }

  .chat .footer-chat .right {
    position: absolute;
    right: 40px;
  }
</style>