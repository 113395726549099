var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"mt-4 ml-2",attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{staticClass:"shadowCard"},[_c('v-card-title',{attrs:{"primary-title":""}},[_c('span',{staticClass:"text-subtitle-1"},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-fingerprint")]),_vm._v("Comentarios nómina")],1),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","dark":"","small":"","tile":""},on:{"click":function($event){return _vm.initialize()}}},[_vm._v(" Actualizar ")])],1),_c('v-card-text',[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"3","lg":"2"}},[_c('v-autocomplete',{attrs:{"filled":"","dense":"","items":_vm.ciclos,"label":"Selecciona ciclo","hide-details":"","item-text":"ciclo","item-value":"id_ciclo"},model:{value:(_vm.ciclo),callback:function ($$v) {_vm.ciclo=$$v},expression:"ciclo"}})],1),_c('v-col',{attrs:{"cols":"12","md":"3","lg":"2"}},[_c('v-select',{attrs:{"items":[
					      		{ bloque: 'Semana 1', value: 1},
					      		{ bloque: 'Semana 2', value: 2},
					      		{ bloque: 'Semana 3', value: 3},
					      		{ bloque: 'Semana 4', value: 4}],"label":"# Semana","filled":"","dense":"","hide-details":"","item-text":"bloque","item-value":"value"},model:{value:(_vm.selectedHeaders),callback:function ($$v) {_vm.selectedHeaders=$$v},expression:"selectedHeaders"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-radio-group',{attrs:{"row":""},model:{value:(_vm.opcionFiltro),callback:function ($$v) {_vm.opcionFiltro=$$v},expression:"opcionFiltro"}},[_c('v-radio',{attrs:{"label":"Pendiente","value":0}}),_c('v-radio',{attrs:{"label":"Aceptados","value":1}}),_c('v-radio',{attrs:{"label":"Rechazados","value":2}}),_c('v-radio',{attrs:{"label":"Todos","value":3}})],1),_c('v-row',{attrs:{"justify":"space-between"}},[_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('div',{staticClass:"black--text"},[_vm._v(" Avance: "+_vm._s(_vm.nominaActual.filter( function (el) { return el.estatus_nomina == 1; } ).length ? ( (_vm.nominaActual.filter( function (el) { return el.estatus_nomina == 1; } ).length / _vm.nominaActual.length ) *100).toFixed(2) : 0)+" % ")])]),_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-text-field',{attrs:{"label":"Buscar","dense":"","single-line":"","filled":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-data-table',{staticClass:"elevation-0",attrs:{"headers":_vm.headers,"items":_vm.filterEntradas,"search":_vm.search,"mobile-breakpoint":100,"dense":""},scopedSlots:_vm._u([{key:"item.nombre_completo",fn:function(ref){
					      		var item = ref.item;
return [_c('span',{class:item.estatus_nomina == 1 ? 'green--text' : item.estatus_nomina == 2 ? 'red--text' : '',attrs:{"type":"button"},on:{"click":function($event){return _vm.verComentarios( item )}}},[_vm._v(" "+_vm._s(item.nombre_completo)+" ")])]}},{key:"item.tipo_pago",fn:function(ref){
					      		var item = ref.item;
return [(item.tipo_pago == 2)?_c('v-chip',{attrs:{"small":"","color":"green","dark":""}},[_vm._v("Hora")]):_vm._e(),(item.tipo_pago == 1)?_c('v-chip',{attrs:{"small":"","color":"blue","dark":""}},[_vm._v("Dia")]):_vm._e(),(item.tipo_pago == null || item.tipo_pago == 0)?_c('v-chip',{attrs:{"small":"","color":"orange","dark":""}},[_vm._v("Sin Tipo Pago")]):_vm._e()]}},{key:"item.confirmacion_comisiones",fn:function(ref){
					      		var item = ref.item;
return [((item.confirmacion_comisiones == 3))?_c('v-chip',{attrs:{"small":"","color":"green","dark":""}},[_vm._v("Aceptadas")]):_vm._e(),((item.confirmacion_comisiones == 4))?_c('v-chip',{attrs:{"small":"","color":"blue","dark":""}},[_vm._v("Rechazadas")]):_vm._e(),(item.puesto == 'ENCARGADA DE SUCURSAL' && (item.confirmacion_comisiones == null || item.tipo_pago == 0))?_c('v-chip',{attrs:{"small":"","color":"orange","dark":""}},[_vm._v("Pendiente")]):_vm._e()]}},{key:"item.monto_laborado",fn:function(ref){
					      		var item = ref.item;
return [_c('span',{attrs:{"type":"button"},on:{"click":function($event){return _vm.goPrenomina(item.nombre_completo )}}},[_vm._v(_vm._s(item.monto_laborado))])]}},{key:"item.monto_horas_extras",fn:function(ref){
					      		var item = ref.item;
return [_c('span',{attrs:{"type":"button"},on:{"click":function($event){return _vm.goHorasExtras(item.nombre_completo )}}},[_vm._v(_vm._s(item.monto_horas_extras))])]}},{key:"item.comisiones_semana",fn:function(ref){
					      		var item = ref.item;
return [_c('span',{attrs:{"type":"button"},on:{"click":function($event){return _vm.goComisiones(item.nombre_completo )}}},[_vm._v(_vm._s(item.comisiones_semana))])]}},{key:"item.monto_total_rebajes",fn:function(ref){
					      		var item = ref.item;
return [_c('span',{attrs:{"type":"button"},on:{"click":function($event){return _vm.goRebajes(item.nombre_completo )}}},[_vm._v(_vm._s(item.monto_total_rebajes))])]}},{key:"item.monto_total_bonos",fn:function(ref){
					      		var item = ref.item;
return [(item.puesto != 'Teacher')?_c('span',{on:{"click":function($event){return _vm.goBonos(item.nombre_completo )}}},[_vm._v(_vm._s(item.monto_total_bonos))]):_vm._e(),(item.puesto == 'Teacher')?_c('span',[_vm._v(_vm._s(item.monto_total_bonos))]):_vm._e()]}},{key:"item.monto_total",fn:function(ref){
					      		var item = ref.item;
return [_c('span',{attrs:{"type":"button"},on:{"click":function($event){_vm.formulaMontoTotal(item), _vm.dialogMontoTotal = true}}},[_vm._v(_vm._s(item.monto_total))])]}},{key:"no-data",fn:function(){return [_c('v-btn',{attrs:{"color":"primary","small":""},on:{"click":_vm.initialize}},[_vm._v(" Actualizar ")])]},proxy:true}])})],1)],1)],1)],1)],1)],1),(_vm.renglonNomina)?_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialogComentarios),callback:function ($$v) {_vm.dialogComentarios=$$v},expression:"dialogComentarios"}},[_c('v-card',[_c('v-card-title',{attrs:{"primary-title":""}},[_vm._v(" Comentarios de nómina ")]),_c('v-card-text',[_c('v-row',_vm._l((_vm.renglonNomina.comentarios),function(mensaje,i){return _c('v-col',{key:i,attrs:{"cols":"12"}},[_c('p',{class:("pa-2 ma-0 mb-4 " + (mensaje.id_usuario != _vm.getdatosUsuario.id_trabajador ? 'mensajeIzq' : 'mensajeDer'))},[_vm._v(" "+_vm._s(mensaje.comentarios)+" "),_c('br'),_vm._v(" "+_vm._s(mensaje.nombre_completo)+" ")])])}),1),_c('br')],1),_c('v-card-text',[_c('v-textarea',{attrs:{"filled":"","rows":3,"auto-grow":"","label":"respuesta al colaborador","hide-details":""},model:{value:(_vm.respuesta),callback:function ($$v) {_vm.respuesta=$$v},expression:"respuesta"}})],1),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"black","tile":"","dark":"","small":""},on:{"click":function($event){_vm.dialogComentarios = false}}},[_c('v-icon',{attrs:{"left":"","span":""}},[_vm._v("mdi-cancel")]),_vm._v(" Cancelar ")],1),_c('v-spacer'),_c('v-btn',{attrs:{"color":"success","tile":"","small":""},on:{"click":function($event){return _vm.responder( )}}},[_c('v-icon',{attrs:{"left":"","span":""}},[_vm._v("mdi-content-save")]),_vm._v(" Agregar comentario ")],1)],1)],1)],1):_vm._e(),(_vm.respuestaAlerta)?_c('Alerta',{attrs:{"parametros":_vm.parametros}}):_vm._e(),(_vm.cargar)?_c('carga'):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }