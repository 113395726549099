<template>
  <v-dialog
    v-model="alertaSinSeguimiento.estatus"
    max-width="400"
  >
    <v-card>
    	<v-card-text>
  			<v-card class="elevation-0 text-center pt-4">
  				<span class="text-headline" style="white-space: pre-line">
  				 	{{ alertaSinSeguimiento.mensaje }}
  				</span>
  			</v-card>
    	</v-card-text>
    	<v-card-actions>
		    <v-btn :color="alertaSinSeguimiento.color" dark block @click="alertaSinSeguimiento.estatus = false">Cerrar</v-btn>
    	</v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
import axios from 'axios';

	// Componentes
  import Alerta                from '@/components/alertas/Alerta.vue'
  import carga                 from '@/components/alertas/carga.vue';

  import validarErrores        from '@/mixins/validarErrores'
  import { mapGetters, mapActions }  from 'vuex';
  import funcionesExcel        from '@/mixins/funcionesExcel'


	export default {

		components:{
      Alerta,
      carga,
    },

    mixins: [ validarErrores, funcionesExcel ],

		props:[
			'alertaSinSeguimiento',
	  ],

    computed: {
      ...mapGetters('login', ['getdatosUsuario', 'getLogeado', 'getEscuela','drawer2','getEstatusLlamada','getNuevosProspectos']),
    },

    data: () => ({
    	// Alertas
    	parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      showConfetti: false,

      respuestaAlerta:false,
      loader: true,
      cargar: false,

      motivo: '',
      sonido: null,
      volumen: 0.1
    }),

    watch: {
    },



    methods:{
     
    }
	}
</script>

<style scoped>
  .textoPoppins{
    font-family: 'Poppins', sans-serif !important;
    font-size: 18px;
    font-weight: 400;
  }

  .slide-y-enter-active, .slide-y-leave-active {
    transition: transform 0.5s ease;
  }

  .slide-y-enter, .slide-y-leave-to {
    transform: translateY(100%);
  }

  .confetti-container {
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: 1000;
  }

  .confetti {
    position: absolute;
    width: 10px;
    height: 10px;
    background-color: #f00;
    animation: confetti-fall 3s linear forwards;
  }

  @keyframes confetti-fall {
    0% {
      transform: translateY(-100%) rotate(0deg);
      opacity: 1;
    }
    100% {
      transform: translateY(1000%) rotate(360deg);
      opacity: 0;
    }
  }
</style>
