<template>
  <v-container class="mt-5 ml-2">
    <v-snackbar
      v-model="snackbar"
      top
      :timeout="3000"
      color="error"
    >
      Selecciona los ciclos porfavor
      <v-btn text color="white" @click.native="snackbar = false">Close</v-btn>
    </v-snackbar>

    <v-row>
      <v-col cols="12">
        <v-data-table
          :headers="headers"
          :items="rigrupos"
          class="elevation-1"
          hide-default-footer
          :items-per-page="rigrupos.length"
          dense
          mobile-breakpoint="200"
          :height="500"
          fixed-header
          :search="search"
          sort-by="faltantes"
          sortDesc
        >
          <template v-slot:top>
            <v-toolbar
              flat
            >
              <v-toolbar-title>RI GRUPOS</v-toolbar-title>
              <v-divider
                class="mx-4"
                inset
                vertical
              ></v-divider>
              <v-spacer></v-spacer>
              <v-btn
                class="mr-2"
                small dark color="green"
                @click="exportar()">
                <v-icon>mdi-microsoft-excel</v-icon>
              </v-btn>
              <v-btn color="primary" @click="consultar()" small>Consultar</v-btn>
            </v-toolbar>
            <v-card-text class="pb-0">
              <v-row>
                <v-col cols="12" sm="6" lg="4" xl="3">
                  <v-autocomplete
                    outlined
                    dense
                    clearable
                    v-model="cicloInf"
                    :items="ciclos"
                    label="Selecciona ciclo actual"
                    persistent-hint
                    hide-details
                    return-object
                    item-text="ciclo"
                    item-value="id_ciclo"
                  >
                  </v-autocomplete>
                </v-col>
                <v-col cols="12" sm="6" lg="4" xl="3">
                  <v-autocomplete
                    outlined
                    dense
                    clearable
                    v-model="cicloSup"
                    :items="ciclos"
                    label="Selecciona siguiente ciclo"
                    persistent-hint
                    hide-details
                    return-object
                    item-text="ciclo"
                    item-value="id_ciclo"
                  >
                  </v-autocomplete>
                </v-col>
              </v-row>
            </v-card-text>
            <v-card-text>
              <v-text-field
                label="Buscar"
                outlined
                dense
                v-model="search"
                id="id"
              ></v-text-field>
            </v-card-text>
          </template>

          <template v-slot:no-data>
            <v-btn
              color="primary"
              @click="initialize"
              small
            >
              Actualizar
            </v-btn>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-overlay :value="overlay">
      <v-card
      	width="200"
        color="primary"
        dark
      >
        <v-card-text align="center" class="text-h6">
          Espera porfavor
          <v-progress-linear
            indeterminate
            color="white"
            class="mb-0 mt-2"
          ></v-progress-linear>
        </v-card-text>
      </v-card>
    </v-overlay>

    <v-dialog
      v-model="dialogError"
      hide-overlay
      persistent
      max-width="600"
    >
      <v-card
        color="error"
        dark
        style="position: relative"
      >
        <v-card-text class="pt-2">
          Error al recuperar los datos
          <br/>
          NOTA: {{error}}
        </v-card-text>
        <v-btn
        	color="primary"
        	x-small
          absolute
          top
          right
          fab
          class="mt-5 mr-0"
          @click="dialogError = false"
         ><v-icon>mdi-close</v-icon>
        </v-btn>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import axios from 'axios';
  import XLSX from 'xlsx'
  import { mapGetters } from 'vuex'
  export default {
    data: () => ({
    	search:'',
      snackbar:false,
      headers: [
        { text: 'Grupo'              , value: 'grupo' },
        { text: 'Alumnos Actuales'   , value: 'alumnos_ciclo_actual' },
        { text: 'Alumnos Persiste'   , value: 'alumnos_siguiente_ciclo' },
        { text: 'Faltantes'          , value: 'faltantes'},
      ],
      desserts: [],
      ciclos:[],
      cicloInf:null,
      cicloSup:null,
      planteles:[],
      plantel:null,
      cicloAnterior:[],
      cicloSiguiente:[],
      rigrupos:[],
      total:0,
      faltantes:0,
      siguientes:0,
      porcentaje:0,
      idcicloERPinf:0,
      idcicloERPSup:0,
      overlay:false,
      dialogError: false,
      error:'',
    }),

    watch: {
      cicloInf () {
        if(this.cicloInf){
          var num_search = parseInt(this.cicloInf.ciclo.substr(5,3)) + 1
          for(const i in this.ciclos){
            var cadena_search = this.ciclos[i].ciclo.substr(0,8)
            if (cadena_search.search(num_search) != -1) {
              this.cicloSup = this.ciclos[i]
            }
          }

          if(this.cicloSup){
          	this.overlay = true
            this.rigrupos = []
            this.consultar()
          }
        }
      }
    },

    computed:{
      ...mapGetters('login', ['getdatosUsuario']),
    },

    created () {
      console.log(this.getdatosUsuario.idpuesto)
      this.initialize()
    },

    methods: {
      initialize () {
        this.ciclos = []
        this.planteles = []
        this.$http.get('kpi.planteles.all').then(response=>{
          for(const i in response.data){
            var payload = {
              plantel: response.data[i].plantel,
              alumnosActual: 0,
              alumnosSiguiente: 0,
              faltan: 0,
              porcentaje: 0,
            }
            this.planteles.push(payload)
          }

          this.$http.get('kpi.ciclos.all').then(response=>{
            for(const i in response.data){
              if (!(response.data[i].ciclo.search('FE') != -1)) {
                if(response.data[i].ciclo.search('CICLO') != -1){
                  this.ciclos.push(response.data[i])
                }
              }
            }
          }).catch(error=>{console.log(error)})
        }).catch(error=>{console.log(error)})
      },

      async consultar () {
        this.overlay = true
        this.rigrupos = []
        // Primero hay que consultar los grupos del ciclo actual
        await this.getGrupos()
      },

      getGrupos () {
        return this.$http.get('kpi.ri.grupos/'  + this.cicloInf.id_ciclo + '/' + this.cicloInf.id_ciclo_relacionado + '/' + this.cicloSup.id_ciclo + '/' + this.cicloSup.id_ciclo_relacionado ).then(response=>{
          if(this.getdatosUsuario.idpuesto == 19 &&  ![568, 7, 161].includes( this.getdatosUsuario.iderp ) ){
            for(const i in response.data){
              const plantel = this.getdatosUsuario.planteles.find(el=> el.idplantel == response.data[i].id_plantel)
              if(plantel){
                console.log('payload', payload)
                var payload = {
                  plantel: response.data[i].plantel,
                  alumnosActual: 0,
                  alumnosSiguiente: 0,
                  faltan: 0,
                  porcentaje: 0,
                }
                this.rigrupos.push(response.data[i])
              }
            }
          }else{
            this.rigrupos = response.data
          }
        	this.overlay = false
        }).catch(error=>{
        	this.error = error.body.message
        	this.dialogError = true
        	this.overlay = false
        })
      },

      s2ab(s) {
        var buf = new ArrayBuffer(s.length);
        var view = new Uint8Array(buf);
        for (var i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
        return buf;
      },

      exportar(){
        let data = XLSX.utils.json_to_sheet(this.rigrupos)
        const workbook = XLSX.utils.book_new()
        const filename = 'RI_grupos'
        XLSX.utils.book_append_sheet(workbook, data, filename)

        var wbout = XLSX.write(workbook, {
          bookType: 'xls',
          bookSST: false,
          type: 'binary'
        });

        const file = new File([new Blob([this.s2ab(wbout)])], filename + '.xls')

        let formData = new FormData();
        // //se crea el objeto y se le agrega como un apendice el archivo
        formData.append('file',file);
        // /*getDatosRiesgo the form data*/

        this.$http.post('pdfs',formData).then(response=> {
          window.location = axios.defaults.baseURL + 'pdfs/' + filename + '.xls'
        }).catch(error=> {
          alert(error.body)
          console.log(error);
        });

      },


    },
  }
</script>
