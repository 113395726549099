<template>
	<v-dialog
    v-model="reporteFechas.status"
    hide-overlay
    persistent
    max-width="1000"
  >
    <v-card>
      <v-card-title primary-title class="text-subtitle-1">
        Reporte por rango de fechas
      </v-card-title>
      <v-card-text >

      	<v-row>
      		<v-col cols="12" md="6" lg="4">
		        <v-text-field
		          v-model="fechaini"
		          dense
		          filled
		          type="date"
		          label="Fecha inicio"
		          clearable
		        ></v-text-field>
      		</v-col>

      		<v-col cols="12" md="6" lg="4">
		        <v-text-field
		          v-model="fechafin"
		          dense
		          filled
		          type="date"
		          label="Fecha final"
		          clearable
		        ></v-text-field>
      		</v-col>
      		<v-col cols="12" md="6" lg="4">
      			<v-btn
              color="orange"
              dark
              @click="initialize"
              small
              tile
            >
              <v-icon small>mdi-refresh</v-icon>
            </v-btn>
      		</v-col>
      	</v-row>

        <v-data-table
          :headers="headersCerrados"
          :items="reporte"
          class="elevation-0"
          dense
          :search="search"
        >
        </v-data-table>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn 
        	color="black"
        	dark 
        	tile
        	@click="reporteFechas.status = false"
        >Cancelar</v-btn>
      </v-card-actions>
    </v-card>

    <!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>

  </v-dialog>
</template>
<script>
import axios from 'axios';

	// Componentes
  import Alerta                from '@/components/alertas/Alerta.vue'
  import carga                 from '@/components/alertas/carga.vue';

  import validarErrores        from '@/mixins/validarErrores'
  import { mapGetters, mapActions }  from 'vuex';
  import funcionesExcel        from '@/mixins/funcionesExcel'


	export default {

		components:{
      Alerta,
      carga,
    },

    mixins: [ validarErrores, funcionesExcel ],

		props:[
			'reporteFechas',
	  ],

    computed: {
      ...mapGetters('login', ['getdatosUsuario', 'getLogeado', 'getEscuela','drawer2','getEstatusLlamada','getNuevosProspectos']),
    },

    data: () => ({

    	// Alertas
    	parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      loader: true,
      cargar: false,

      teacherActivos:[],

      headersCerrados: [
        { text: 'Nombre',         value: 'nombre_completo' },  
        { text: 'Asignados',      value: 'asignados'       },  
        { text: 'Contestados',    value: 'contestados'     },  
        { text: 'Cerrados',       value: 'cerrados'        },  
      ],
      search:'',
      curso: null,
      opcion: 1,

      fechaini: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
			fechafin: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),

    }),

    created(){
			this.initialize()
		},
    
    computed:{

    },

    methods:{

    	initialize( ){
    		let payload = {
    			fechaini: this.fechaini,
    			fechafin: this.fechafin,
    		}

				this.cargar  = true
        this.reporte = []

        this.$http.post('tickets.reporte.usuario.fecha', payload).then(response=>{
          this.reporte = response.data
          this.cargar = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
    	},


      cerrar(){
      	this.reporteFechas.status = false
      	this.$emit('initialize')
      },

      exportar( ){

        this.exportExcel( this.prospectosCerrados  , 'prospectosCerrados')
      }
    }
	}
</script>
