<template>
	<v-container class="mt-4 ml-2" fluid>
		<v-row>
			<v-col cols="12">
				<v-card class="shadowCard">

					<!-- Titulo -->
					<v-card-title primary-title>
						<span class="text-subtitle-1"><v-icon left>mdi-fingerprint</v-icon>Mi Nómina</span>
						<v-spacer></v-spacer>
						<v-btn
							color="primary"
							dark
							@click="initialize(), getCiclosFecha()"
							small
							rounded
						>
							Actualizar
						</v-btn>
					</v-card-title>

					<!-- FILTROS -->
					<v-card-text>
						<!-- <v-row>
							<v-col cols="12" md="4" lg="2">
								<v-text-field
								label="Fecha"
								type="date"
								filled
								dense
								hide-details
								v-model="fecha"
								></v-text-field>
							</v-col>
						</v-row> -->
						<br />
	          <v-row>
	          	<v-col cols="12">
	          		<v-data-table
	            		:headers="headers"
	            		:items="filterEntradas"
	            		class="elevation-0"
	            		:search="search"
	            		:page.sync="page"
	            		@page-count="pageCount = $event"
	            		:mobile-breakpoint="100"
	            		dense
	            		item-key="idchecador"
	          		>
	            		<!-- Valores que ocupan botons, chips, iconos, etc -->
	            		<template v-slot:item.tipo_pago="{ item }">
	            			<v-chip v-if="item.tipo_pago == 2" small color="green" dark >Hora</v-chip>
	            			<v-chip v-if="item.tipo_pago == 1" small color="blue" dark >Dia</v-chip>
	            			<v-chip v-if="item.tipo_pago == null || item.tipo_pago == 0" small color="orange" dark >Sin Tipo Pago</v-chip>
	            		</template>

	            		<template v-slot:item.confirmacion_comisiones="{ item }">
	            			<v-chip v-if="(item.confirmacion_comisiones == 3)" small color="green" dark >Aceptadas</v-chip>
	            			<v-chip v-if="(item.confirmacion_comisiones == 4)" small color="blue" dark >Rechazadas</v-chip>
	            			<v-chip v-if="item.puesto == 'ENCARGADA DE SUCURSAL' && (item.confirmacion_comisiones == null || item.tipo_pago == 0)" small color="orange" dark >Pendiente</v-chip>
	            		</template>

	            		<template v-slot:item.monto_laborado="{ item }">
	            			{{ item.monto_laborado }} <v-icon right class="ml-2" color="blue" small @click="formulaMontoLaborado(item), dialogMontoLaborado = true">mdi-information</v-icon>
	            		</template>

	            		<template v-slot:item.monto_horas_extras="{ item }">
	            			{{ item.monto_horas_extras }} <v-icon right class="ml-2" color="blue" small @click="formulaMontoHorasExtras(item), dialogHorasExtras = true">mdi-information</v-icon> 
	            		</template>

	            		<template v-slot:item.comisiones_semana="{ item }">
	            			{{ item.comisiones_semana }} <v-icon right class="ml-2" color="blue" small @click="dialogComisiones = true">mdi-information</v-icon>
	            		</template>

	            		<template v-slot:item.monto_total_rebajes="{ item }">
	            			{{ item.monto_total_rebajes }} <v-icon right class="ml-2" color="blue" small @click="formulaMontoRebajes(item), dialogMontoRebajes = true">mdi-information</v-icon>
	            		</template>

	            		<!-- <template v-slot:item.monto_total_bonos="{ item }"> -->
	            			<!-- {{ item.monto_total_bonos }} <v-icon right class="ml-2" color="blue" small @click="goBonos(item.nombre_completo )">mdi-information</v-icon> -->
	            		<!-- </template> -->

	            		<template v-slot:item.monto_total="{ item }">
	            			{{ item.monto_total }}
	            		</template>

	            		<template v-slot:item.asistencias="{ item }">
	            			<v-icon
		            			small
		            			class="mr-2"
		            			color="blue"
		            			@click="mostrarAsistencias(item)"
		            			>
		            			mdi-file-document
		            		</v-icon>
	            		</template>

		            	<template v-slot:no-data>
		            		<v-btn
			            		color="primary"
			            		@click="initialize"
			            		small
		            		>
		            		Actualizar
		            		</v-btn>
		            	</template>

		          	</v-data-table>
	        		</v-col>

	        		<v-col cols="12" md="8">
	        			<v-alert
						      outlined
						      type="warning"
						      prominent
						      border="left"
						    >
						      Si cuentas con algún detalle en tu nómina, favor de levantar un ticket explicando la situación!
						    </v-alert>
	        		</v-col>
	      		</v-row>
		    	</v-card-text>
				</v-card> 
			</v-col>
			<v-dialog v-model="dialogAceptar" max-width="500px">
				<v-card>
					<v-card-text>
							<v-row>
							<v-col cols="6">
								<v-card class="elevation-0" height="100%" style="display: table">
									<div
									class="text-h6"
									style="display: table-cell; vertical-align: middle"
									>
									<strong>Aceptar:</strong> ¿Estás seguro que deseas guardar la nómina?
									<v-card-actions class="pt-6">
										<!-- Cerrar dialogo de confirmación y abrir el dialogo de editar la clase -->
										<v-btn
										color="error"
										text
										large
										class="mr-2"
										@click="dialogAceptar=false"
										>No, cancelar</v-btn
										>
										<!-- Guardar la información  -->
										<v-btn
										color="#5C5C5C"
										dark
										class="elevation-6"
										large
										@click="dialogAceptar = false, save()"
										>Si</v-btn
										>
									</v-card-actions>
								</div>
								</v-card>
							</v-col>
							<v-col cols="6">
								<v-img src="@/assets/contrato.jpg"></v-img>
							</v-col>
						</v-row>
					</v-card-text>
				</v-card>
			</v-dialog>

			<v-dialog v-model="dialogActualizar" max-width="500px">
				<v-card>
					<v-card-text>
						<v-row>
							<v-col cols="6">
								<v-card class="elevation-0" height="100%" style="display: table">
									<div
										class="text-h6"
										style="display: table-cell; vertical-align: middle"
									>
										<strong>Advertencia:</strong> ¿Ya existe una nómina guardada de esta semana, deseas sobrescribirla?
										<v-card-actions class="pt-6">
											<!-- Cerrar dialogo de confirmación y abrir el dialogo de editar la clase -->
											<v-btn
												color="error"
												text
												large
												class="mr-2"
												@click="dialogActualizar=false"
											>No, cancelar</v-btn
											>
											<!-- Guardar la información  -->
											<v-btn
											color="#5C5C5C"
												dark
												class="elevation-6"
												large
												@click="dialogActualizar = false, updateNominaSemanal()"
											>Si</v-btn
											>
										</v-card-actions>
									</div>
								</v-card>
							</v-col>
							<v-col cols="6">
								<v-img src="@/assets/advertencia_nomina.gif"></v-img>
							</v-col>
						</v-row>
					</v-card-text>
				</v-card>
			</v-dialog>

			<v-dialog v-model="dialogAsistencias" max-width="1300px">
				<v-card>
					<v-card-title>
						<span class="text-h6">Asistencias:</span>
						<v-spacer></v-spacer>
						<v-btn
							color="red"
							dark
							rounded
							small
							icon
							@click="dialogAsistencias = false"
						>
							<v-icon>mdi-close</v-icon>
						</v-btn>
					</v-card-title>

					<v-col cols="12" md="6">
						<label></label>
						<v-text-field
							name="name"
							label="Buscar"
							id="id"
							filled
							dense
							single-line
							hide-details
							append-icon="mdi-magnify"
							v-model="search2"
						></v-text-field>
					</v-col>

					<v-card-text class="black--text">
						<v-row>
							<v-col cols="12">
								<v-data-table
									:headers="headersAsistencias"
									:items="asistencias"
									:search="search2"
									class="elevation-0"
									dense
								>
								</v-data-table>
							</v-col>
						</v-row>
					</v-card-text>
				</v-card>
			</v-dialog>
			<!--Dialogs de Formulas de Montos -->
			<v-dialog
				v-model="dialogMontoLaborado"
				hide-overlay
				persistent
				max-width="500"
			>
				<v-card>
					<v-card-title class="text-h5">
						Desglose Monto Jornada Laboral
					</v-card-title>
					<v-card-text>
						<v-textarea
							v-model="formula_monto_laborado"
							rows="3"
							auto-grow
							label="Sueldo Diario  X  Cantidad Dias Laborados"
							filled
							hide-details
							readonly
						/>
					</v-card-text>
					<v-card-actions>
						<v-btn
							color="black"
							tile
							dark
							small
							@click="dialogMontoLaborado = false"
						>
							<v-icon small left>mdi-cancel</v-icon>
							Cancelar
						</v-btn>
					</v-card-actions>
				</v-card>
			</v-dialog>
			<v-dialog
				v-model="dialogHorasExtras"
				hide-overlay
				persistent
				max-width="500"
			>
				<v-card>
					<v-card-title class="text-h5">
						Desglose Monto Horas Extras
					</v-card-title>
					<v-card-text>
						<v-textarea
							v-model="formula_horas_extras"
							rows="3"
							auto-grow
							label="Sueldo Hora  X  Cantidad Horas Extras"
							filled
							hide-details
							readonly
						/>
					</v-card-text>
					<v-card-actions>
						<v-btn
							color="black"
							tile
							dark
							small
							@click="dialogHorasExtras = false"
						>
							<v-icon small left>mdi-cancel</v-icon>
							Cancelar
						</v-btn>
					</v-card-actions>
				</v-card>
			</v-dialog>

			<v-dialog
				v-model="dialogMontoRebajes"
				hide-overlay
				persistent
				max-width="500"
			>
				<v-card>
					<v-card-title class="text-h5">
						Desglose Monto Rebajes
					</v-card-title>
					<v-card-text>
						<v-textarea
							v-model="formula_monto_rebajes"
							rows="3"
							auto-grow
							label="Retardo + Rebaje + IMSS + Devolución + Caja Ahorro + Retención"
							filled
							hide-details
							readonly
						/>
					</v-card-text>
					<v-card-actions>
						<v-btn
							color="black"
							tile
							dark
							small
							@click="dialogMontoRebajes = false"
						>
							<v-icon small left>mdi-cancel</v-icon>
							Cancelar
						</v-btn>
					</v-card-actions>
				</v-card>
			</v-dialog>

			<v-dialog
				v-model="dialogComisiones"
				hide-overlay
				persistent
				max-width="1200"
			>
				<v-card>
					<v-card-title class="text-h5">
						Desglose Monto Comisiones
					</v-card-title>
					<v-card-text>

						<v-row align="center" class="no-gutters">
              <v-col cols="12" md="6" lg="4">
                <span class="black--text text-h8"
                  ><b>Total Comisiones:</b>
                  {{ totalComisiones }} 
                </span>
              </v-col>
            </v-row>
            <v-row align="center" class="no-gutters">
              <v-col cols="12" md="6" lg="4">
                <span class="black--text text-h8"
                  ><b>Cantidad Comisiones:</b>
                  {{ cantidadComisiones }} 
                </span>
              </v-col>
            </v-row>

						<v-row>
              <v-col cols="12">
                <v-data-table
                  :headers="headersComisiones"
                  :items="encargadascomisiones"
                  class="elevation-0"
                  :search="search"
                  :page.sync="page"
                  @page-count="pageCount = $event"
                  :mobile-breakpoint="100"
                  dense
                >
                </v-data-table>
              </v-col>
            </v-row>
					</v-card-text>
					<v-card-actions>
						<v-btn
							color="black"
							tile
							dark
							small
							@click="dialogComisiones = false"
						>
							<v-icon small left>mdi-cancel</v-icon>
							Cancelar
						</v-btn>
					</v-card-actions>
				</v-card>
			</v-dialog>

<v-dialog
v-model="dialogMontoBonos"
hide-overlay
persistent
max-width="500"
>
<v-card>
	<v-card-title class="text-h5">
		Desglose Monto Bonos
	</v-card-title>
	<v-card-text>
		<v-textarea
		v-model="formula_monto_bonos"
		rows="3"
		auto-grow
		label="Bonos Ventas + Bonos RI + Bonos Puntualidad"
		filled
		hide-details
		readonly
		/>
	</v-card-text>
	<v-card-actions>
		<v-btn
		color="black"
		tile
		dark
		small
		@click="dialogMontoBonos = false"
		>
		<v-icon small left>mdi-cancel</v-icon>
		Cancelar
	</v-btn>
</v-card-actions>
</v-card>
</v-dialog>
<v-dialog
v-model="dialogMontoTotal"
hide-overlay
persistent
max-width="500"
>
<v-card>
	<v-card-title class="text-h5">
		Desglose Monto Total
	</v-card-title>
	<v-card-text>
		<v-textarea
		v-model="formula_monto_total"
		rows="3"
		auto-grow
		label="(Monto Jornada Laboral + Monto Compensaciones + Monto Horas Extras + Monto Comisiones + Monto Bonos) - Monto Rebajes"
		filled
		hide-details
		readonly
		/>
	</v-card-text>
	<v-card-actions>
		<v-btn
		color="black"
		tile
		dark
		small
		@click="dialogMontoTotal = false"
		>
		<v-icon small left>mdi-cancel</v-icon>
		Cancelar
	</v-btn>
</v-card-actions>
</v-card>
</v-dialog>
</v-row>

<!-- Dialog de carga -->
<Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
<carga                v-if="cargar"/>

</v-container>
</template>
<script>
	import axios from 'axios';
	import { mapGetters } from 'vuex'
	// Componentes
	import Alerta                from '@/components/alertas/Alerta.vue'
	import carga                 from '@/components/alertas/carga.vue';

	import botonesRoles          from '@/components/roles/botonesRoles.vue';

	import validarErrores  from '@/mixins/validarErrores'
	import XLSX            from 'xlsx'
	import funcionesExcel  from '@/mixins/funcionesExcel'


	export default {
		components:{
			Alerta,
			carga,
			botonesRoles,
		},

		mixins: [ validarErrores, funcionesExcel ],

		data: () => ({
    	// Alertas
			parametros:{
				dialogError: false,
				mensaje: '',
				color: ''
			},

			respuestaAlerta:false,
			loader: true,
			cargar: false,

			encabezados: null,
			encabezados2: null,
			dialogAceptar: false,
			dialogActualizar: false,
			dialogAsistencias: false,
			dialogMontoLaborado: false,
			dialogHorasExtras: false,
			dialogMontoRebajes: false,
			dialogMontoBonos: false,
			dialogMontoTotal: false,
			ciclos:[],
			gruposRoles: [],
			pagos: [],
			ciclo:null,
			id_ciclo: 0,

      // Filtros: 
			// fecha: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
			fecha: "2024-05-31",
      // fechafin: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),

      // tabla
			search:'',
			search2:'',
			formula_monto_laborado:'',
			formula_horas_extras:'',
			formula_monto_rebajes:'',
			formula_monto_bonos:'',
			formula_monto_total:'',
			page: 1,
			page2: 1,
			pageCount: 0,
			itemsPerPage: 10,
			entradassalidas: [],
			nomina_pasada: [],
			nominaSemanal: [],
			autorizarNomina: [],
			nominaEstatus: [],
			nominaTeachersSabatino: [],
			nominaTeachersCatorcena: [],
			asistencias: [],
			estatus: null,
			entradassalidasteachers: [],
			horasDobles:[],
			horasTotales: 0,
			headers: [
        // { text: 'ID'                    , value: 'id'                   },
				// { text: 'Empleado'              , value: 'nombre_completo'      },
				// { text: 'Puesto'                , value: 'puesto'               },
				{ text: 'Monto Jornada Laboral' , value: 'monto_laborado'       },
				{ text: 'Monto Horas Extras'    , value: 'monto_horas_extras'   },
				{ text: 'Monto Comisiones'      , value: 'comisiones_semana'    },
				{ text: 'Monto Rebajes'         , value: 'monto_total_rebajes'  },
				// { text: 'Monto Bonos'           , value: 'monto_total_bonos'    },
				{ text: 'Monto Total'           , value: 'monto_total'          },
				// { text: 'Departamento'          , value: 'departamento'         },
		    // { text: 'Plantel'               , value: 'plantel'              },
				// { text: 'Tipo pago'             , value: 'tipo_pago'            },
				// { text: 'Sueldo mensual'        , value: 'sueldo_mensual'       },
				// { text: 'Sueldo semanal'        , value: 'sueldo_semanal'       },
				// { text: 'Sueldo diario'         , value: 'sueldo_dia'           },
				// { text: 'Sueldo hora'           , value: 'sueldo_hora'          },
				// { text: 'Horas laborales'       , value: 'horas_laborales'      },
				// { text: 'Dias Laborados'        , value: 'dia_laboral'          },
		    // { text: 'Horas Extras'          , value: 'cantidad_horas'       },
				// { text: 'Monto Horas Extras'    , value: 'monto_horas_extras'   },
				// { text: 'Retardos'              , value: 'retardos'             },
				// { text: 'Comisiones'            , value: 'comisiones_semana'    },
				// { text: 'Devoluciones'          , value: 'devoluciones'         },
				// { text: 'Rebajes'               , value: 'rebajes'              },
				// { text: 'Monto Retardo'         , value: 'monto_retardo'        },
				// { text: 'Retenciones'           , value: 'retensiones'          },
        // { text: 'Compensaciones'        , value: 'compensaciones'       },
        // { text: 'Premios'               , value: 'premios'              },
				// { text: 'Bonos Ventas'          , value: 'bonos_ventas'         },
        // { text: 'Bonos RI'              , value: 'bonos_ri'             },
				// { text: 'Bonos Puntualidad'     , value: 'bonos_puntualidad'    },
				// { text: 'Seguro IMSS'           , value: 'seguroIMSS'           },
				// { text: 'Caja de Ahorro'        , value: 'cajaAhorro'           },
				// { text: 'Monto Jornada Laboral' , value: 'monto_laborado'       },
				// { text: 'Monto Total'           , value: 'monto_total'          },
				// { text: 'Monto Compensaciones'  , value: 'compensaciones'       },
			],
			headerscomparativa: [
				{ text: 'ID'                     , value: 'id_trabajador'         },
				{ text: 'Empleado'               , value: 'empleado'              },
				{ text: 'Puesto'                 , value: 'puesto'                },
				{ text: 'Departamento'           , value: 'departamento'          },
				{ text: 'Plantel'                , value: 'plantel'               },
				{ text: 'Monto Total Anterior'   , value: 'monto_total'           },
				{ text: 'Monto Total Actual'     , value: 'monto_total_actual'    },
				{ text: 'Monto Total Diferencia' , value: 'monto_total_diferencia'},
				],
			headersAsistencias: [
				{ text: "Hora Entrada", value: "hora_entrada" },
				{ text: "Hora Salida", value: "hora_salida" },
				{ text: "Chequeo Entrada", value: "chequeo_entrada" },
				{ text: "Chequeo Salida", value: "chequeo_salida" },
				{ text: "¿Regular?", value: "hora_regular" },
				{ text: "Horas Extras", value: "hora_extra" },
				{ text: "Horas Negativas", value: "hora_negativa" },
				{ text: "Horas Trabajo", value: "hora_trabajo" },
				{ text: "Horas Trabajadas", value: "hora_trabajada" },
				{ text: "Aceptar", value: "aceptar" },
				],
			gruposRoles2:[],
			encargadascomisiones:[],
			headersComisiones: [
	      // { text: "ID", value: "id_alumno" },
	      { text: "Matrícula", value: "matricula" },
	      { text: "Alumno", value: "alumno" },
	      { text: "Grupo", value: "grupo" },
	      // { text: "Precio Grupo", value: "precio_grupo" },
	      // { text: "Precio con Descuento", value: "precio_grupo_con_descuento_x_alumno" },
	      // { text: "Monto Pagado", value: "pagado" },
	      // { text: "Fecha", value: "fecha_pago" },
	      // { text: "Fecha Ultimo Cambio", value: "fecha_ultimo_cambio" },
	      // { text: "Comisionista", value: "vendedora" },
	      { text: "Comisión", value: "comision" },
	    ],

	    dialogComisiones: false
		}),

		computed: {

			...mapGetters( 'login' ,['getdatosUsuario']),

			filterTeacher( ){
				let teachers = this.entradassalidasteachers;
				return teachers.filter(el => el.puesto === 'Teacher');
			},

			filterEntradas( ){
				let entradass = this.entradassalidas.concat(this.nominaTeachersSabatino, this.nominaTeachersCatorcena);
				return entradass.filter(el =>  el.puesto !== 'Sin puesto');
			},

			filterEntradasEncargadas( ){
				let entradass = this.entradassalidas;
				return entradass.filter(el => el.puesto !== 'Sin puesto' && el.puesto == 'ENCARGADA DE SUCURSAL');
			},

			totalComisiones() {
	      let subtotal = this.encargadascomisiones
	        .map((item) => item.comision)
	        .reduce((prev, curr) => prev + curr, 0);
	      return new Intl.NumberFormat("en-US", {
	        style: "currency",
	        currency: "USD",
	      }).format(subtotal);
	    },
	    cantidadComisiones() {
	      let subtotal = this.encargadascomisiones.length;
	      this.cd1 = subtotal;
	      return Math.floor(subtotal);
	    },

		},


		async created () {
			await this.initialize()
			await this.getCiclosFecha()
			await this.misComisiones()
		},

		methods: {
			initialize () {
				this.cargar = true
				this.entradassalidas = []
				this.entradassalidasteachers = []

				const payload = {
					fecha:    this.fecha,   
				}

				return this.$http.post('usuarios.entradas', payload).then(response=>{
					this.encabezados = response.data.encabezados;
					this.entradassalidas = response.data.result3.filter( el => el.id == this.getdatosUsuario.id_trabajador );
				}).catch( error =>{
					this.validarError( error.response.data.message )
				}).finally( () => { this.cargar = false })
			},

			misComisiones( ){
				this.encargadascomisiones = [];
      this.overlay = true;

      const payload = {
        fecha: this.fecha,
        id_usuario: this.getdatosUsuario.iderp,
      }

      this.$http
        .post("encargadas_comisiones.get.comisionesindividuales", payload)
        .then((response) => {
          this.encargadascomisiones = response.data;
          this.overlay = false;
          // this.getComisionesRetenidasIndividuales()
        })
        .catch((error) => {
          // INdicamos que hay un error en el back
          if (error.body.message) {
            this.error = error.body.message;
          } else {
            this.error = error.body;
          }
          this.errorBack = true;
          this.overlay = false;
          this.dialogError = true;
        });
			},

			getNominaSemanal () {
				this.cargar = true

				const payload = {
					fecha:    this.fecha,   
				}

				return this.$http.post('encargadas_comisiones.get.nominasemanal', payload).then(response=>{
					this.nominaSemanal = response.data;
					this.cargar      = false  
				}).catch( error =>{
					this.validarError( error.response.data.message )
				}).finally( () => { this.cargar = false })
			},

			save () {
				this.cargar = true
				const payload = {
					fecha: this.fecha,  
					data: this.filterEntradas
				}


				this.$http.post('encargadas_comisiones.add.historialnomina', payload).then(response=>{
					this.validarSuccess('Datos grabados correctamente')
					this.dialogAceptar = false
					// this.getNominaSemanal()
					this.getAutorizarNomina()
					this.initialize();
					this.cargar = false
				}).catch( error =>{
					this.validarError( error.response.data.message )
				}).finally( () => { this.cargar = false })	
			},

			updateNominaSemanal () {
				this.cargar = true
				const payload = {
					fecha: this.fecha,  
					data: this.filterEntradas
				}

				this.$http.post('encargadas_comisiones.update.nominasemanal', payload).then(response=>{
					this.validarSuccess('Datos grabados correctamente')
					this.dialogActualizar = false
					this.getNominaSemanal()
					this.initialize();
					this.cargar = false
				}).catch( error =>{
					this.validarError( error.response.data.message )
				}).finally( () => { this.cargar = false })	
			},

			handleClick() {
				this.dialogAceptar = true;
			},


			getAutorizarNomina() {
				this.autorizarNomina = [];
				this.overlay = true;

				const payload = {
					fecha: this.fecha
				}

				this.$http
				.post("encargadas_comisiones.get.autorizarnomina", payload)
				.then((response) => {
					this.autorizarNomina = response.data.respuesta;
					this.overlay = false;
				})
				.catch((error) => {
					// INdicamos que hay un error en el back
					if (error.body.message) {
						this.error = error.body.message;
					} else {
						this.error = error.body;
					}
					this.errorBack = true;
					this.overlay = false;
					this.dialogError = true;
				});
			},


			s2ab(s) {
				var buf = new ArrayBuffer(s.length);
				var view = new Uint8Array(buf);
				for (var i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
					return buf;
			},

			exportar(){
				let data = XLSX.utils.json_to_sheet(this.entradassalidas)

				const workbook = XLSX.utils.book_new()
				const filename = 'entradas_salidas'
				XLSX.utils.book_append_sheet(workbook, data, filename)

				var wbout = XLSX.write(workbook, {
					bookType: 'xls',
					bookSST: false,
					type: 'binary'
				});

				const file = new File([new Blob([this.s2ab(wbout)])], filename + '.xls')

				let formData = new FormData();
		        // //se crea el objeto y se le agrega como un apendice el archivo 
				formData.append('file',file);
		        // /*getDatosRiesgo the form data*/

				this.$http.post('pdfs',formData).then(response=> {
					window.location = axios.defaults.baseURL + 'pdfs/' + filename + '.xls'
				}).catch(error=> {
					console.log(error);
				});
			},

			mostrarAsistencias(item){
				this.dialogAsistencias = true

				for (const i in item.entradas){
					this.asistencias.push({
						chequeo_entrada: item.entradas[i],
						chequeo_salida: item.salidas[i],
						hora_regular: item.hora_regular[i],
						hora_extra: item.hora_extra[i],
						hora_negativa: item.hora_negativa[i],
						hora_entrada: i == 0 ? item.horario_ingreso_fin : item.horario_ingreso,
						hora_salida: i == 0 ? item.horario_salida_fin : item.horario_salida,
					})
				}
			},

			formulaMontoLaborado(item) {
				let sueldo_dia = item.sueldo_dia;
				let dia_laboral = item.dia_laboral;
				this.formula_monto_laborado = `$${sueldo_dia}  X  ${dia_laboral} dias\n
				Días laborados:\n
				Sábado: 
				${ item.dia_descanso == 1    ? 'Descanso' : !item.entrada1 ? 'Sin registro' : item.entrada1 + ' a ' + item.salida1   } 
				${ item.entrada1 && item.salida1 ? 'JORNADA COMPLETA' : 'ERROR EN JORNADA' }\n
				Domingo: 
				${ item.dia_descanso == 2   ? 'Descanso' : !item.entrada2 ? 'Sin registro' : item.entrada2 + ' a ' + item.salida2   } 
				${ item.entrada2 && item.salida2 ? 'JORNADA COMPLETA' : 'ERROR EN JORNADA' }\n
				Lunes: 
				${ item.dia_descanso == 3     ? 'Descanso' : !item.entrada3 ? 'Sin registro' : item.entrada3 + ' a ' + item.salida3   } 
				${ item.entrada3 && item.salida3 ? 'JORNADA COMPLETA' : 'ERROR EN JORNADA' }\n
				Martes: 
				${ item.dia_descanso == 4    ? 'Descanso' : !item.entrada4 ? 'Sin registro' : item.entrada4 + ' a ' + item.salida4   } 
				${ item.entrada4 && item.salida4 ? 'JORNADA COMPLETA' : 'ERROR EN JORNADA' }\n
				Miércoles: 
				${ item.dia_descanso == 5 ? 'Descanso' : !item.entrada5 ? 'Sin registro' : item.entrada5 + ' a ' + item.salida5   } 
				${ item.entrada5 && item.salida5 ? 'JORNADA COMPLETA' : 'ERROR EN JORNADA' }\n
				Jueves: 
				${ item.dia_descanso == 6    ? 'Descanso' : !item.entrada6 ? 'Sin registro' : item.entrada6 + ' a ' + item.salida6   } 
				${ item.entrada6 && item.salida6 ? 'JORNADA COMPLETA' : 'ERROR EN JORNADA' }\n
				Viernes: 
				${ item.dia_descanso == 7   ? 'Descanso' : !item.entrada7 ? 'Sin registro' : item.entrada7 + ' a ' + item.salida7   } 
				${ item.entrada7 && item.salida7 ? 'JORNADA COMPLETA' : 'ERROR EN JORNADA' }\n`;
			},   

			formulaMontoHorasExtras(item) {
				let sueldo_hora = item.sueldo_hora;
				let cantidad_horas = item.cantidad_horas;
				this.formula_horas_extras = `${sueldo_hora}  X  ${ cantidad_horas ? cantidad_horas : 0 } horas extras`;
			},  

			formulaMontoRebajes(item) {

				let rebajes = item.rebajes !== undefined && item.rebajes !== null ? item.rebajes : 0.00;
				let seguroIMSS = item.seguroIMSS !== undefined && item.seguroIMSS !== null ? item.seguroIMSS : 0.00;
				let cajaAhorro = item.cajaAhorro !== undefined && item.cajaAhorro !== null ? item.cajaAhorro : 0.00;
				let monto_retardo = item.monto_retardo !== undefined && item.monto_retardo !== null ? item.monto_retardo : 0.00;
				let retensiones = item.retensiones !== undefined && item.retensiones !== null ? item.retensiones : 0.00;
				let devoluciones = item.devoluciones !== undefined && item.devoluciones !== null ? item.devoluciones : 0.00;

				this.formula_monto_rebajes = `
				Monto Retardo: $ ${monto_retardo}\n`;

				if( monto_retardo ){

					if( item.estatus1 == 1 ){
						this.formula_monto_rebajes +=`
						*${ this.formatFecha( item.fechas[0] ) } ${ item.entrada1 }\n`
					}
					if( item.estatus2 == 1 ){
						this.formula_monto_rebajes +=`
						*${ this.formatFecha( item.fechas[1] ) }: ${ item.entrada2 }\n`
					}
					if( item.estatus3 == 1 ){
						this.formula_monto_rebajes +=`
						*${ this.formatFecha( item.fechas[2] ) }: ${ item.entrada3 }\n`
					}
					if( item.estatus4 == 1 ){
						this.formula_monto_rebajes +=`
						*${ this.formatFecha( item.fechas[3] ) }: ${ item.entrada4 }\n`
					}
					if( item.estatus5 == 1 ){
						this.formula_monto_rebajes +=`
						*${ this.formatFecha( item.fechas[4] ) }: ${ item.entrada5 }\n`
					}
					if( item.estatus6 == 1 ){
						this.formula_monto_rebajes +=`
						*${ this.formatFecha( item.fechas[5] ) }: ${ item.entrada6 }\n`
					}
					if( item.estatus7 == 1 ){
						this.formula_monto_rebajes +=`
						*${ this.formatFecha( item.fechas[6] ) }: ${ item.entrada7 }\n`
					}

				}

				this.formula_monto_rebajes += `
				Monto Otros: $ ${rebajes}\n  
				Monto Seguro IMSS: $ ${seguroIMSS}\n  
				Monto Devoluciones: $ ${devoluciones}\n  
				Monto Caja Ahorro: $ ${cajaAhorro}\n  
				Monto Retenciones: $ ${retensiones}
				`;

			},

			formulaMontoBonos(item) {
				let bonos_ventas = item.bonos_ventas !== undefined && item.bonos_ventas !== null ? item.bonos_ventas : 0.00;
				let bonos_ri = item.bonos_ri !== undefined && item.bonos_ri !== null ? item.bonos_ri : 0.00;
				let bonos_puntualidad = item.bonos_puntualidad !== undefined && item.bonos_puntualidad !== null ? item.bonos_puntualidad : 0.00;

				this.formula_monto_bonos = `
				Monto Bonos Ventas: $${bonos_ventas}\n  
				Monto Bonos RI: $${bonos_ri}\n  
				Monto Bonos Puntualidad: $${bonos_puntualidad}
				`;
			},

			formulaMontoTotal(item) {
				let monto_laborado = item.monto_laborado !== undefined && item.monto_laborado !== null ? item.monto_laborado : 0.00;
				let compensaciones = item.compensaciones !== undefined && item.compensaciones !== null ? item.compensaciones : 0.00;
				let monto_horas_extras = item.monto_horas_extras !== undefined && item.monto_horas_extras !== null ? item.monto_horas_extras : 0.00;
				let comisiones_semana = item.comisiones_semana !== undefined && item.comisiones_semana !== null ? item.comisiones_semana : 0.00;
				let monto_total_rebajes = item.monto_total_rebajes !== undefined && item.monto_total_rebajes !== null ? item.monto_total_rebajes : 0.00;
				let monto_total_bonos = item.monto_total_bonos !== undefined && item.monto_total_bonos !== null ? item.monto_total_bonos : 0.00;

				this.formula_monto_total = `
				Monto Jornada Laboral: ${monto_laborado}\n  
				Monto Compensaciones: ${compensaciones}\n  
				Monto Horas Extras: ${monto_horas_extras}\n  
				Monto Comisiones: ${comisiones_semana}\n  
				Monto Bonos: ${monto_total_bonos}\n  
				Monto Rebajes: ${monto_total_rebajes} 
				`;
			},

			formatFecha( fechadata ){
				// Fecha en formato 'YYYY-MM-DD'
				let fechaString = fechadata;

				// Dividir la cadena de fecha en componentes
				let [year, month, day] = fechaString.split('-').map(Number);

				// Crear un objeto Date con los componentes (mes en JavaScript es 0-indexed)
				let fecha = new Date(year, month - 1, day);

				// Array de días de la semana y meses del año
				let diasSemana = ['Domingo', 'Lunes', 'Martes', 'Miércoles', 'Jueves', 'Viernes', 'Sábado'];
				let meses = ['enero', 'febrero', 'marzo', 'abril', 'mayo', 'junio', 'julio', 'agosto', 'septiembre', 'octubre', 'noviembre', 'diciembre'];

				// Obtener el día de la semana, día del mes y mes
				let diaSemana = diasSemana[fecha.getDay()];
				let dia = fecha.getDate();
				let mes = meses[fecha.getMonth()];

				// Construir la cadena en el formato deseado
				return `${diaSemana} ${dia} de ${mes}`;
			},

      // = response.data.horasDobles
			getCiclos () {
				this.cargar = true
				this.ciclos = []

				this.$http.get('roles.ciclos.all').then(response=>{
					for(const i in response.data){
						if (!(response.data[i].ciclo.search('FE') != -1)) {
							if(response.data[i].ciclo.search('CICLO') != -1){
								this.ciclos.push(response.data[i])
							}
						}
					}

					this.cargar = false
				}).catch( error =>{
					this.validarError( error.response.data.message )
				}).finally( () => { this.cargar = false })
			},

			getCiclosFecha () {
				this.cargar = true
				this.ciclos = []

				const payload = {
					fecha: this.fecha
				}

				this.$http.post('roles.get.ciclofecha', payload).then(response=>{
					for(const i in response.data){
						if (!(response.data[i].ciclo.search('FE') != -1)) {
							if(response.data[i].ciclo.search('CICLO') != -1){
								this.ciclos.push(response.data[i])
								this.ciclo = this.ciclos[0];
							}
						}
						console.log('ciclo', this.ciclos)
					}
					this.cargar = false
				}).catch( error =>{
					this.validarError( error.response.data.message )
				}).finally( () => { })
			},

			goHorasExtras( name ){
				this.$router.push({name: "HorasExtras", params: { fecha: this.fecha, nombre: name }})
			},
			goPrenomina( name ){
				this.$router.push({name: "EntradasSalidas", params: { fecha: this.fecha, nombre: name } })
			},
			goBonos( name ){
				this.$router.push({name: "BonosVentas", params: { fecha: this.fecha, nombre: name }})
			},
			goRebajes( name ){
				this.$router.push({name: "RebajesNomina", params: { fecha: this.fecha, nombre: name }})
			},
			goComisiones( name ){
				this.$router.push({name: "EncargadasComisiones", params: { fecha: this.fecha, nombre: name }})
			},

			exportExcel(data) {
				const wb = XLSX.utils.book_new();
				data.forEach(sheetData => {
					const ws = XLSX.utils.json_to_sheet(sheetData.data);
					XLSX.utils.book_append_sheet(wb, ws, sheetData.sheetName);
				});

				// Descarga el archivo Excel
				XLSX.writeFile(wb, 'Nomina y Prenomina.xlsx');
			},

			exportarInscripciones() {
				// const empleados = this.filterEntradas.map(({ id, nombre_completo, puesto, departamento, plantel, tipo_pago, sueldo_mensual, sueldo_semanal, sueldo_dia, sueldo_hora, dia_laboral, horas_laboradas_total, cantidad_horas, monto_horas_extras, retardos, comisiones_semana, devoluciones, rebajes, monto_retardo,  retensiones, compensaciones, bonos_ventas, bonos_ri, bonos_puntualidad, seguroIMSS, cajaAhorro, monto_laborado, monto_total }) =>
				// 	({ id, nombre_completo, puesto, departamento, plantel, tipo_pago, sueldo_mensual, sueldo_semanal, sueldo_dia, sueldo_hora, dia_laboral, horas_laboradas_total, "Horas Extras": cantidad_horas, monto_horas_extras, retardos, comisiones_semana, devoluciones, rebajes, monto_retardo, retensiones, compensaciones, bonos_ventas, bonos_ri, bonos_puntualidad, seguroIMSS, cajaAhorro, monto_laborado, monto_total }));

				const empleados = this.filterEntradas.map(({ id, nombre_completo, puesto, monto_laborado, monto_horas_extras, comisiones_semana, monto_total_rebajes, monto_total_bonos, monto_total }) =>
					({ "ID": id,"Empleado":nombre_completo, "Puesto":puesto, "Monto Jornada Laboral":monto_laborado,  "Monto Horas Extras":monto_horas_extras,  "Monto Comisiones":comisiones_semana, "Monto Rebajes":monto_total_rebajes, "Monto Bonos":monto_total_bonos,  "Monto Total":monto_total }));

				const empleados2 = this.filterEntradas.map(({ id, nombre_completo, puesto, plantel, sucursal_registro, fecha_ingreso, dia_laboral, horas_laboradas_total, horas_extras, entrada1, salida1, horas_laboradas1,  entrada2, salida2, horas_laboradas2, entrada3, salida3, horas_laboradas3,  entrada4, salida4, horas_laboradas4,  entrada5, salida5, horas_laboradas5,  entrada6, salida6, horas_laboradas6, entrada7, salida7, horas_laboradas7 }) => 
					({ id, nombre_completo, puesto, plantel, sucursal_registro, fecha_ingreso, dia_laboral, horas_laboradas_total, horas_extras, "Sabado/Entrada": entrada1, "Sabado/Salida":salida1, "Sabado/Horas":horas_laboradas1,  "Domingo/Entrada":entrada2, "Domingo/Salida":salida2, "Domingo/Horas":horas_laboradas2,  "Lunes/Entrada":entrada3, "Lunes/Salida":salida3, "Lunes/Horas":horas_laboradas3,  "Martes/Entrada":entrada4, "Martes/Salida":salida4, "Martes/Horas":horas_laboradas4, "Miercoles/Entrada":entrada5, "Miercoles/Salida":salida5, "Miercoles/Horas":horas_laboradas5, "Jueves/Entrada":entrada6, "Jueves/Salida":salida6, "Jueves/Horas":horas_laboradas6, "Viernes/Entrada":entrada7, "Viernes/Salida":salida7, "Viernes/Horas":horas_laboradas7  }));

				const empleados3 = this.nomina_pasada.map(({ id_trabajador, empleado, puesto, plantel, monto_total_sin_comisiones, monto_total_actual, monto_total_diferencia}) => 
					({ id_trabajador, empleado, puesto, plantel, "Monto Anterior": monto_total_sin_comisiones, "Monto Actual": monto_total_actual, "Monto Diferencia": monto_total_diferencia }));

				const empleados4 = this.filterEntradasEncargadas.map(({ id, nombre_completo, puesto, plantel, comisiones_semana}) => 
					({ id, nombre_completo, puesto, plantel, "Comisiones": comisiones_semana }));


				// Llama a la función exportExcel para crear un archivo Excel con dos pestañas
				this.exportExcel([
					{ sheetName: 'Nomina', data: empleados },
					{ sheetName: 'Prenomina', data: empleados2 }, // Segunda pestaña con los mismos datos
					{ sheetName: 'Comparativa', data: empleados3 }, // Segunda pestaña con los mismos datos
					{ sheetName: 'Comisiones', data: empleados4 } // Segunda pestaña con los mismos datos
				]);
			},

			async subirRequi( ){

				await this.subirNominaINBI()
				await this.subirComisionesINBI()
				await this.subirNominaFAST()
				await this.subirComisionesFAST()

			},

			async subirNominaINBI( ){

				let nominaInbiOperaciones = this.filterEntradas.filter( el => el.departamento == 'Operaciones' && !el.plantel.match('FAST') )

				let detallesInbiOperaciones = []

				for( const i in nominaInbiOperaciones ){

					const { id_usuario, monto_laborado, nombre_completo, id_plantel } = nominaInbiOperaciones[i]

					detallesInbiOperaciones.push({
						id_elemento_requisicion: 17,
						id_plantel_resultados: id_plantel,
						id_plantel: id_plantel,
						concepto: nombre_completo,
						cantidad: 1,
						costo_unitario: monto_laborado.replace('$',''),
						costo_total: monto_laborado.replace('$',''),
						id_alumno: 0,
						id_grupo: 0,
						id_empleado: id_usuario
					})
				}

				var payload = {
      		registro:                      null,
      		id_usuario_solicita:           this.getdatosUsuario.iderp,
		      id_sucursal_solicita:          this.getdatosUsuario.id_plantel,
		      id_requisicion_compra_estatus: 1,
		      id_usuario_ultimo_cambio:      this.getdatosUsuario.iderp,
		      id_usuario_compra:             28,
		      tipo_requisicion:              1,
		      id_ciclo:                      this.ciclo.id_ciclo,
          id_proyecto:                   null,
		      comentarios:                   'Nómina',
		      detalles:                      detallesInbiOperaciones,
			    fotos:[]
	      }


	      return this.$http.post('generar.nomina.operaciones', payload ).then(response=>{
          this.validarSuccess( 'Requisición generada correctamente' )
        	this.cargar      = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })

			},

			async subirNominaFAST( ){

				let nominaInbiOperaciones = this.filterEntradas.filter( el => el.departamento == 'Operaciones' && el.plantel.match('FAST') )

				let detallesInbiOperaciones = []

				for( const i in nominaInbiOperaciones ){

					const { id_usuario, monto_laborado, nombre_completo, id_plantel } = nominaInbiOperaciones[i]

					detallesInbiOperaciones.push({
						id_elemento_requisicion: 17,
						id_plantel_resultados: id_plantel,
						id_plantel: id_plantel,
						concepto: nombre_completo,
						cantidad: 1,
						costo_unitario: monto_laborado.replace('$',''),
						costo_total: monto_laborado.replace('$',''),
						id_alumno: 0,
						id_grupo: 0,
						id_empleado: id_usuario
					})
				}

				var payload = {
      		registro:                      null,
      		id_usuario_solicita:           this.getdatosUsuario.iderp,
		      id_sucursal_solicita:          this.getdatosUsuario.id_plantel,
		      id_requisicion_compra_estatus: 1,
		      id_usuario_ultimo_cambio:      this.getdatosUsuario.iderp,
		      id_usuario_compra:             28,
		      tipo_requisicion:              1,
		      id_ciclo:                      this.ciclo.id_ciclo_relacionado,
          id_proyecto:                   null,
		      comentarios:                   'Nómina',
		      detalles:                      detallesInbiOperaciones,
			    fotos:[]
	      }


	      return this.$http.post('generar.nomina.operaciones', payload ).then(response=>{
          this.validarSuccess( 'Requisición generada correctamente' )
        	this.cargar      = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })

			},

			async subirComisionesINBI( ){

				let nominaInbiOperaciones = this.filterEntradas.filter( el => el.departamento == 'Operaciones' && !el.plantel.match('FAST') )

				let detallesInbiOperaciones = []

				for( const i in nominaInbiOperaciones ){

					const { id_usuario, comisiones_semana, nombre_completo, id_plantel } = nominaInbiOperaciones[i]

					detallesInbiOperaciones.push({
						id_elemento_requisicion: 17,
						id_plantel_resultados: id_plantel,
						id_plantel: id_plantel,
						concepto: nombre_completo + ' ( COMISIONES )',
						cantidad: 1,
						costo_unitario: comisiones_semana.replace('$',''),
						costo_total: comisiones_semana.replace('$',''),
						id_alumno: 0,
						id_grupo: 0,
						id_empleado: id_usuario
					})
				}

				var payload = {
      		registro:                      null,
      		id_usuario_solicita:           this.getdatosUsuario.iderp,
		      id_sucursal_solicita:          this.getdatosUsuario.id_plantel,
		      id_requisicion_compra_estatus: 1,
		      id_usuario_ultimo_cambio:      this.getdatosUsuario.iderp,
		      id_usuario_compra:             28,
		      tipo_requisicion:              1,
		      id_ciclo:                      this.ciclo.id_ciclo,
          id_proyecto:                   null,
		      comentarios:                   'Nómina',
		      detalles:                      detallesInbiOperaciones,
			    fotos:[]
	      }


	      return this.$http.post('generar.nomina.operaciones', payload ).then(response=>{
          this.validarSuccess( 'Requisición generada correctamente' )
        	this.cargar      = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })

			},

			async subirComisionesFAST( ){

				let nominaInbiOperaciones = this.filterEntradas.filter( el => el.departamento == 'Operaciones' && el.plantel.match('FAST') )

				let detallesInbiOperaciones = []

				for( const i in nominaInbiOperaciones ){

					const { id_usuario, comisiones_semana, nombre_completo, id_plantel } = nominaInbiOperaciones[i]

					detallesInbiOperaciones.push({
						id_elemento_requisicion: 17,
						id_plantel_resultados: id_plantel,
						id_plantel: id_plantel,
						concepto: nombre_completo + ' ( COMISIONES )',
						cantidad: 1,
						costo_unitario: comisiones_semana.replace('$',''),
						costo_total: comisiones_semana.replace('$',''),
						id_alumno: 0,
						id_grupo: 0,
						id_empleado: id_usuario
					})
				}

				var payload = {
      		registro:                      null,
      		id_usuario_solicita:           this.getdatosUsuario.iderp,
		      id_sucursal_solicita:          this.getdatosUsuario.id_plantel,
		      id_requisicion_compra_estatus: 1,
		      id_usuario_ultimo_cambio:      this.getdatosUsuario.iderp,
		      id_usuario_compra:             28,
		      tipo_requisicion:              1,
		      id_ciclo:                      this.ciclo.id_ciclo_relacionado,
          id_proyecto:                   null,
		      comentarios:                   'Nómina',
		      detalles:                      detallesInbiOperaciones,
			    fotos:[]
	      }


	      return this.$http.post('generar.nomina.operaciones', payload ).then(response=>{
          this.validarSuccess( 'Requisición generada correctamente' )
        	this.cargar      = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })

			}
		},
	}
</script>




