<template>
  <v-container class="mt-4 ml-2" fluid>
    <v-row>
      <v-col cols="12">
        <v-card class="shadowCard">
          <v-card-title primary-title>
            <span class="text-subtitle-1">Trabajadores ERP Operaciones</span>
            <v-spacer></v-spacer>

            <v-btn
              class="mr-2"
              small
              dark
              color="green"
              @click="exportarDataExcel()"
              tile
            >
              <v-icon small>mdi-microsoft-excel</v-icon>
            </v-btn>

            <v-btn
              color="primary"
              dark
              class="text-capitalize"
              @click="initialize()"
              small
              tile
            >
              <v-icon left small>mdi-refresh</v-icon>
              Actualizar
            </v-btn>

          </v-card-title>
          <v-card-text>          
            <v-row >
              <!-- Seleccionar el plantel -->  
              <v-col cols="12" md="4" lg="3">
                <v-autocomplete
                  :items="puestos"
                  v-model="puesto_seleccionado"
                  label="Puesto"
                  clearable
                  filled
                  dense
                  item-text="puesto"
                  item-value="idpuesto"
                  single-line
                  hide-details
                ></v-autocomplete>
              </v-col>

              <v-col cols="12" md="4" lg="3">
                <v-autocomplete
                  label="Plantel"
                  id="id"
                  filled
                  dense
                  hide-details
                  v-model="plantel"
                  :items="planteles"
                  item-text="plantel"
                  item-value="id_plantel"
                  clearable
                  single-line
                  hide-details
                ></v-autocomplete>
              </v-col>

              <v-col cols="12" md="6" lg="5">
                <v-text-field
                  name="name"
                  label="Buscar"
                  id="id"
                  filled
                  dense
                  hide-details
                  append-icon="mdi-magnify"
                  v-model="search"
                  single-line
                  hide-details
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>

              <v-col cols="12">
                <v-data-table
                  :headers="headers"
                  :items="filterTrabajadores"
                  class="elevation-0"
                  :search="search"
                  :mobile-breakpoint="100"
                  dense
                >

                 <template v-slot:item.idsexo="{ item }">
                    <v-chip 
                      v-if="item.idsexo == 1"
                      small
                      color="green"
                      dark
                    >M</v-chip>

                    <v-chip 
                      v-if="item.idsexo == 2"
                      small
                      color="green"
                      dark
                    >F</v-chip>

                    <v-chip 
                      v-if="item.idsexo == 3"
                      small
                      color="green"
                      dark
                    >Otro</v-chip>
                  </template>

                  <!-- Valores que ocupan botons, chips, iconos, etc -->
                  <template v-slot:item.activo_sn="{ item }">
                    <v-chip 
                      v-if="item.activo_sn == 1"
                      small
                      color="green"
                      dark
                    >Si</v-chip>

                    <v-chip 
                      v-else
                      small
                      color="error"
                      dark
                    >No</v-chip>
                  </template>

                  <template v-slot:item.actions="{ item }">
                    <v-btn 
                      color="primary" 
                      x-small
                      tile
                      @click="editItem(item)"
                      class="mr-2"
                    >
                      <v-icon small>mdi-magnify</v-icon>
                    </v-btn>
                  </template>

                </v-data-table>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- Dialogo de editar-->
    <v-dialog
      v-model="dialog_editar"
      max-width="1000px"
      persistent
    >
      <v-card>
        <v-card-title>
          <span class="text-subtitle-1">{{ formTitle2 }}</span>
          <v-spacer></v-spacer>
          <span class="text-subtitle-1">
           <strong>ID: {{ editedItem.id_trabajador }}</strong>
         </span>
        </v-card-title>

        <v-card-text>
          <v-row>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="editedItem.nombres"
                label="Nombre(s)"
                filled
                hide-details
                dense
                readonly
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="6">
              <v-text-field
                v-model="editedItem.apellido_paterno"
                label="Apellido Paterno"
                filled
                hide-details
                dense
                readonly
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="6">
              <v-text-field
                v-model="editedItem.apellido_materno"
                label="Apellido Materno"
                filled
                hide-details
                dense
                readonly
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="6">
              <v-autocomplete
              :items="planteles"
              v-model="editedItem.id_plantel"
              label="Plantel"
              filled
              hide-details
              dense
              item-text="plantel"
              item-value="id_plantel"
              ></v-autocomplete>
            </v-col>

            <v-col cols="12" md="6">
              <v-text-field
                v-model="editedItem.fecha_nacimiento"
                label="Fecha Nacimiento"
                filled
                hide-details
                dense
                type="date"
                readonly
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="6">
              <v-autocomplete
                filled
                dense
                clearable
                v-model="editedItem.idpuesto"
                :items="puestos"
                label="Selecciona un puesto"
                persistent-hint
                item-text="puesto"
                item-value="idpuesto"
                prepend-inner-icon="mdi-account-box"
                hide-details
              >
              </v-autocomplete>
            </v-col>

          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="close()"
          >
            Cancelar
          </v-btn>
          <v-btn
            color="blue darken-1"
            class="elevation-6"
            dark
            @click="update"
          >
            Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>

  </v-container>
</template>
<script>
import axios from 'axios';
  import { mapGetters } from 'vuex'
  // Componentes
  import Alerta                from '@/components/alertas/Alerta.vue'
  import carga                 from '@/components/alertas/carga.vue';

  import Sueldos               from '@/components/nomina/Sueldos.vue';

  import validarErrores        from '@/mixins/validarErrores'
  import funcionesExcel        from '@/mixins/funcionesExcel'


  export default {

    mixins: [ validarErrores, funcionesExcel ],

    components:{
      Alerta,
      carga,
      Sueldos
    },

    data: () => ({
      // Alertas
      parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      loader: true,
      cargar: false,

      activos: null,
      puestos:[],
      puesto_seleccionado: null,
      plantel: null,
      planteles:[],
      perfiles:[],
      puestos:[],
      jornada:[],
      tipo_trabajador:[],
      nivel:[],
      curso:[],
      trabajadores:[],
      trabajadorERP:[],
      usua:[],
      departamento:[],
      valor:0,

      fecha_nacimiento:0,
      fecha_termino_labores:null,
      fecha_ingreso:0,

      dialog_agregar: false,
      dialog_editar: false,
      dialogDelete: false,
      dialog: false,
      
      editedIndex: -1,

      dialogSueldos: {
        estatus: false
      },

      editedItem: {
        nombres:'',                 
        apellido_paterno:'',           
        apellido_materno:'',           
        domicilio:'',                  
        horario_inicio: null,             
        horario_salida: null,             
        horario_inicio_fin: null,             
        horario_salida_fin: null,      
        telefono1:'',                  
        telefono2:'',                  
        email:'',                      
        rfc:'',                        
        numero_poliza_sgm_mayores:'',  
        numero_poliza_sgm_menores:'',  
        numero_imss:'',                
        fecha_ingreso: null,              
        fecha_termino_labores: null,      
        antiguedad:'',                 
        id_plantel:null,               
        idpuesto:null,   
        dia_descanso:0, 
        idsexo: null,  
        idencargada_experiencia:null,  
        horas_totales:null,             
        id_jornada_laboral:null,        
        id_departamento:null,           
        id_tipos_trabajadores:null,      
        id_nivel:null,                   
        id_curso:null,                   
        fecha_nacimiento:null,           
        motivo_salida:'',             
        activo_sn:'',                  
        id:null,
        numero_tarjeta: 0,
        banco: 0,
        folio_banco: 0,
        factor: 0,
        sueldo_hora_2:0,
        sueldo_hora_3:0,
        sueldo_hora:  0,
        apoyando: 0,
        nuevo_id_usuario: 0,
        idcategorias_maestro: 0,
        iddisponibilidad_maestros: 0,
        rolado_ciclo: false,
        lm_d: 0,
        lm_t:0,
        jv_d:0,
        jv_t:0,
        s_d:0,
        s_t: 0,
        d_d:0,
        d_t:0,
        numero_tarjeta_anterior:0,
        idcategorias_maestro_anterior:0,
        tarjeta_secundaria:'',
        idcategorias_encargadas:0,
        cuenta_hsbc:0,
        idsueldo_recepcion:0,
        idseguimiento_campanias:0,
        sueldo1:0,
        sueldo2:0,
        sueldo3:0,
        sueldo4:0,
        sueldo5:0,
        sueldo6:0,
        sueldo7:0,
        sueldo8:0,
        sueldo9:0,
        sueldo10:0,
        sueldo11:0,
        sueldo_aguinaldo:null,
      },

      defaultItem: {
        nombres:'',                 
        apellido_paterno:'',           
        apellido_materno:'',           
        domicilio:'',                  
        horario_inicio: null,             
        horario_salida: null,  
        horario_inicio_fin: null,             
        horario_salida_fin: null,                
        telefono1:'',                  
        telefono2:'',                  
        email:'',                      
        rfc:'',                        
        numero_poliza_sgm_mayores:'',  
        numero_poliza_sgm_menores:'',  
        numero_imss:'',                
        fecha_ingreso: null,              
        fecha_termino_labores: null,      
        antiguedad:'',                 
        id_plantel:null,               
        idpuesto:0,     
        dia_descanso:0,
        idsexo: null,
        idencargada_experiencia:null,     
        horas_totales:null,            
        id_jornada_laboral:0,        
        id_departamento:0 ,          
        id_tipos_trabajadore: 0,      
        id_nivel:0,                   
        id_curso:0,                   
        fecha_nacimiento:null,           
        motivo_salida:'',             
        activo_sn:'',                  
        id:null,
        numero_tarjeta: 0,
        banco: 0,
        folio_banco: 0,
        factor: 0,
        sueldo_hora_2:0,
        sueldo_hora_3:0,
        sueldo_hora:  0,
        apoyando: 0,
        nuevo_id_usuario: 0,
        idcategorias_maestro: 0,
        iddisponibilidad_maestros: 0,
        rolado_ciclo: false,
        lm_d: 0,
        lm_t:0,
        jv_d:0,
        jv_t:0,
        s_d:0,
        s_t: 0,
        d_d:0,
        d_t:0,
        numero_tarjeta_anterior:0,
        idcategorias_maestro_anterior:0,
        tarjeta_secundaria:'',
        idcategorias_encargadas:0,
        cuenta_hsbc:0,
        idsueldo_recepcion:0,
        idseguimiento_campanias:0,
        prestaciones: 0,
        rebaje_prestaciones:0,
        url_alta_imss:'',
        sueldo1:0,
        sueldo2:0,
        sueldo3:0,
        sueldo4:0,
        sueldo5:0,
        sueldo6:0,
        sueldo7:0,
        sueldo8:0,
        sueldo9:0,
        sueldo10:0,
        sueldo11:0,
        sueldo_aguinaldo:null,
      },

      baseItem: {
        nombres:'',                 
        apellido_paterno:'',           
        apellido_materno:'',           
        domicilio:'',                  
        horario_inicio: null,             
        horario_salida: null,     
        horario_inicio_fin: null,             
        horario_salida_fin: null,             
        telefono1:'',                  
        telefono2:'',                  
        email:'',                      
        rfc:'',                        
        numero_poliza_sgm_mayores:'',  
        numero_poliza_sgm_menores:'',  
        numero_imss:'',                
        fecha_ingreso: null,              
        fecha_termino_labores: null,      
        antiguedad:'',                 
        id_plantel:0,               
        idpuesto:0,   
        dia_descanso:0,  
        idsexo: null, 
        idencargada_experiencia:null,      
        horas_totales:null,             
        id_jornada_laboral:0,        
        id_departamento:0 ,          
        id_tipos_trabajadore: 0,      
        id_nivel:0,                   
        id_curso:0,                   
        fecha_nacimiento:null,           
        motivo_salida:'',             
        activo_sn:'',                  
        id:null,
        numero_tarjeta: 0,
        banco: 0,
        folio_banco: 0,
        factor: 0,
        sueldo_hora_2:0,
        sueldo_hora_3:0,
        sueldo_hora:  0,
        apoyando: 0,
        nuevo_id_usuario: 0,
        idcategorias_maestro: 0,
        iddisponibilidad_maestros: 0,
        rolado_ciclo: false,
        lm_d: 0,
        lm_t:0,
        jv_d:0,
        jv_t:0,
        s_d:0,
        s_t: 0,
        d_d:0,
        d_t:0,
        numero_tarjeta_anterior:0,
        idcategorias_maestro_anterior:0,
        tarjeta_secundaria:'',
        idcategorias_encargadas:0,
        cuenta_hsbc:0,
        idsueldo_recepcion:0,
        idseguimiento_campanias:0,
        prestaciones: 0,
        rebaje_prestaciones:0,
        url_alta_imss:'',
        sueldo1:0,
        sueldo2:0,
        sueldo3:0,
        sueldo4:0,
        sueldo5:0,
        sueldo6:0,
        sueldo7:0,
        sueldo8:0,
        sueldo9:0,
        sueldo10:0,
        sueldo11:0,
        sueldo_aguinaldo:null,
     },

     categoriasVendedoras:[],

      // Filtros: 
     incio:null,
     fin:null,

      // tabla
     search:'',
     page: 1,
     pageCount: 0,
     itemsPerPage: 10,
     horarios: [],
     headers: [
      { text: 'ID'                    , value: 'id_trabajador'     },
      { text: 'Trabajador'            , value: 'nombre_completo'   },
      { text: 'Sexo'                  , value: 'idsexo'            },
      { text: 'Puesto'                , value: 'puesto'            },
      { text: 'Depto'                 , value: 'departamento'      },
      { text: 'Sucursal'              , value: 'plantel'           },
      { text: 'Cumpleaños'            , value: 'fecha_nacimiento'  },
      { text: 'Acciones'              , value: 'actions', sortable: false },
    ],

      usuarios: [],
      disponibilidades:[],
      categorias:[],
      sueldos: [],
      vacantes:[],
      reglamento: false,
      descriptor: false,
      misionvision: false,
      confidencialidad: false,
      dialogVerDocumentos: false,
      files:'',

      url:'',

    }),

    computed: {
      ...mapGetters('login',['getdatosUsuario']),

      formTitle () {
        return 'Agregar Usuario'
      },

      formTitle2 () {
        return 'Editar Usuario'
      },

      filterTrabajadores( ){
        let data = this.trabajadorERP

        if( this.plantel ){
          data = data.filter( el=> { return el.id_plantel == this.plantel })
        }

        data = data.filter( el=> { return el.activo_sn == 1 })

        if( this.puesto_seleccionado ){
          data = data.filter( el=> { return el.idpuesto == this.puesto_seleccionado })
        }

        return data 
      },

      total( ){
        let total = this.trabajadorERP.filter( el => el.activo_sn && el.id_puesto == 10 ).length
        return total
      },

      porcentaje( ){
        let total = this.trabajadorERP.filter( el => el.activo_sn && el.id_puesto == 10 && el.disponibilidad_completa ).length
        return total
      },

      porcentaje_avance( ){

        let total =  ( this.trabajadorERP.filter( el => el.activo_sn && el.id_puesto == 10 && el.disponibilidad_completa ).length / this.total ) * 100


        return total
      },


    },

    watch: {
      dialog_agregar (val) {
        val || this.close()
      },
      dialog_editar (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },

    },

    async created () {

      this.url = axios.defaults.baseURL  + 'comprobantes-imss/'
      await this.getUsuarios()
      await this.initialize()
      await this.getPlanteles()
      await this.getPuestos()
    },

    methods: {
      initialize () {  
        this.cargar = true
        this.trabajadorERP = []
        return this.$http.get('trabajadores.erp.get.trabajadoreserp').then(response=>{
         
          this.trabajadorERP = response.data.filter( el => [18,19,40,73,82].includes( el.idpuesto ) )

          this.cargar      = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      verDocumentos( item ){
        this.reglamento = item.reglamento
        this.descriptor = item.descriptor
        this.misionvision = item.misionvision
        this.confidencialidad = item.confidencialidad
        this.dialogVerDocumentos = true
      },

      getSueldos( ){
        this.cargar = true
        this.sueldos = []
        return this.$http.get('usuarios.sueldos').then(response=>{
          this.sueldos = response.data
          this.cargar      = false
          this.dialogSueldos.estatus = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      getVacantes () {
        this.cargar = true
        this.vacantes = []
        
        const payload = {
          fechaini: this.filtroFechaIni,
          fechafin: this.filtroFechaFin
        }

        return this.$http.post('seguimiento.vacantes', payload ).then(response=>{
          this.vacantes = response.data.filter( el=> el.estatus == 1 )
          this.cargar      = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      getUsuarios( ){
        this.cargar = true
        this.usuarios = []
        this.$http.get('usuarios.all.erp').then(response=>{
          this.usuarios = response.data
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      update () {
        this.cargar = true

        this.$http.post('trabajadores.update.plantel', this.editedItem ).then(response => {   
          this.validarSuccess( response.data.message )
          this.dialog_editar = false
          this.initialize()
          this.close()  
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      getPlanteles () {
        this.cargar = true
        this.planteles = []
        return this.$http.get('planteles.activos').then(response=>{
          this.planteles = response.data
          this.cargar      = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      getPerfiles () {
        this.cargar = true
        this.perfiles = []
        return this.$http.get('usuarios.erp.get.perfilesERP').then(response=>{
          this.perfiles = response.data
          this.cargar      = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      getPuestos () {
        this.puestos = []
        this.$http.get('puestos.all').then(response=>{
          this.cargar = false
          this.puestos = response.data.filter( el => [18,19,40,73].includes( el.idpuesto ) )
          // Traer los puestos
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      editItem (item) {
        this.editedIndex = this.horarios.indexOf(item)
        this.editedItem = Object.assign({}, item)
        console.log( this.editedItem  )
        this.dialog_editar = true
      },

      close() {
        this.dialog_editar = false
        this.dialog_agregar = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.baseItem)
          this.defaultItem = Object.assign({}, this.baseItem)
          this.editedIndex = -1
        })
      },

      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.baseItem)
          this.editedIndex = -1
        })
      },

      closeDialog () {
        this.dialog = false
      },

      exportarDataExcel( ){

        // const alumnos = this.filterTrabajadores.map(({ id_trabajador, nombre_completo, puesto, plantel, sueldo_mensual, sueldo_dia, sueldo_hora, factor }) => ({ id_trabajador, nombre_completo, puesto, plantel, sueldo_mensual, sueldo_dia, sueldo_hora, factor }));

        // this.exportExcel( alumnos, 'Trabajadores')
        this.exportExcel( this.filterTrabajadores, 'Trabajadores')

      }
    },
  }
</script>

