<template>
  <v-dialog
    v-model="dialogReporteSucursal.estatus"
    max-width="1800"
    persistent
  >
    <v-card class="shadowCard">
		  <v-card-title primary-title>
		    <span class="text-subtitle-1">Reporte por sucursal de interés</span>
		    <v-spacer></v-spacer>

        <v-btn
          color="primary mr-8"
          dark
          class="text-capitalize"
          @click="initialize()"
          small
          tile
        >
          <v-icon small>mdi-refresh</v-icon>
        </v-btn>
        
		  </v-card-title>

		  <v-card-text>
		  	<v-row>

		  		<v-col cols="12" sm="6" md="4" lg="3">
		  			<v-text-field
		  			  name="name"
		  			  label="Buscar"
		  			  id="id"
		  			  filled
		  			  dense
		  			  single-line
		  			  hide-details
		  			  v-model="fechaini"
		  			  type="date"
		  			>
		  			</v-text-field>
		  		</v-col>

		  		<v-col cols="12" sm="6" md="4" lg="3">
		  			<v-text-field
		  			  name="name"
		  			  label="Buscar"
		  			  id="id"
		  			  filled
		  			  dense
		  			  single-line
		  			  hide-details
		  			  v-model="fechafin"
		  			  type="date"
		  			>
		  			</v-text-field>
		  		</v-col>

		  	</v-row>

		    <v-row>
		    	<v-col cols="12">
		    		<!-- <div ref="chartdiv" style="width: 100%; height: 600px;" v-show="verGraficaInbi"></div> -->
		    		<v-card class="elevation-0">
		    			<v-card-title primary-title class="text-body-1">
		    			  Reporte Inbi
		    			  <v-spacer></v-spacer>
		    			  <v-btn 
				          class="mr-2" 
				          small dark color="green"
				          @click="exportExcel( tablaInbi  , 'REPORTE_INBI')"
				          tile
				        >
				          <v-icon small>mdi-microsoft-excel</v-icon>
				        </v-btn>
		    			</v-card-title>
		    			<v-card-text>
			    		  <v-data-table
				          :headers="headers"
				          :items="tablaInbi"
				          class="elevation-0"
				          dense
				          mobile-breakpoint="200"
				        >
									<template v-slot:item="{ item, index }">
                    <!-- v-for="(data, i) in dataTablaInscritos" -->
                    <tr>
                      <td v-for="(h, i) in headers">
                        <span 
                          v-if="`${h.value}` == 'plantel'"
                        >
                          {{ item[`${h.value}`] }}
                        </span>

                        <span 
                          v-else-if="`${h.value}` == 'total'"
                        >
                        	<v-chip 
                            @click="abrirDatos( item[`total_all`] )"
                            color="blue"
                            small
                            dark
                          >
                          	{{ item[`${h.value}`].length }}
                          </v-chip>
                        </span>

                        <span v-else> 
                          <v-chip 
                            @click="abrirDatos( item[`${h.value}`] )"
                            color="blue"
                            small
                            dark
                          >
                            {{ item[`${h.value}`].length  }}
                          </v-chip>
                        </span>

                      </td>
                    </tr>
                  </template>
				        </v-data-table>
		    			</v-card-text>
		    		</v-card>					
		    	</v-col>

		    	<v-divider></v-divider>

		    	<v-col cols="12">
		    		<!-- <div ref="chartdivfast" style="width: 100%; height: 600px;" v-show="verGraficaFast"></div> -->
		    		<v-card class="elevation-0">
		    			<v-card-title primary-title class="text-body-1">
		    			  Reporte Fast
		    			  <v-spacer></v-spacer>
		    			  <v-btn 
				          class="mr-2" 
				          small dark color="green"
				          @click="exportExcel( tablaFast  , 'REPORTE_FAST')"
				          tile
				        >
				          <v-icon small>mdi-microsoft-excel</v-icon>
				        </v-btn>
		    			</v-card-title>
		    			<v-card-text>
			    		  <v-data-table
				          :headers="headers"
				          :items="tablaFast"
				          class="elevation-0"
				          dense
				          mobile-breakpoint="200"
				        >
				        	<template v-slot:item="{ item, index }">
                    <!-- v-for="(data, i) in dataTablaInscritos" -->
                    <tr>
                      <td v-for="(h, i) in headers">
                        <span 
                          v-if="`${h.value}` == 'plantel'"
                        >
                          {{ item[`${h.value}`] }}
                        </span>

                        <span 
                          v-else-if="`${h.value}` == 'total'"
                        >
                        	<v-chip 
                            @click="abrirDatos( item[`total_all`] )"
                            color="blue"
                            small
                            dark
                          >
                          	{{ item[`${h.value}`].length }}
                          </v-chip>
                        </span>

                        <span v-else> 
                          <v-chip 
                            @click="abrirDatos( item[`${h.value}`] )"
                            color="blue"
                            small
                            dark
                          >
                            {{ item[`${h.value}`].length  }}
                          </v-chip>
                        </span>

                      </td>
                    </tr>
                  </template>
				        </v-data-table>
		    			</v-card-text>
		    		</v-card>					
		    	</v-col>

		    </v-row>
		  </v-card-text>
		  <v-card-actions>
		  	<v-btn 
		  		tile
		  		top
		  		absolute
		  		icon
		  		right
		  		@click="dialogReporteSucursal.estatus = false"
		  	>
		  		<v-icon small left>mdi-close</v-icon>
		  	</v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
		</v-card>

		<v-dialog
		  v-model="dialogMensajes"
		  max-width="1400px"
		>
		  <v-card>
		    <v-card-title class="text-subtitle-1">
		      Datos de los contactos
		      <v-spacer></v-spacer>
		      <v-btn 
	          class="mr-10" 
	          small dark color="green"
	          @click="exportExcel( dataTableContactos  , 'MENSAJES')"
	          tile
	        >
	          <v-icon small>mdi-microsoft-excel</v-icon>
	        </v-btn>
		    </v-card-title>
		    <v-card-text>
		      <v-data-table
	          :headers="headers2"
	          :items="dataTableContactos"
	          class="elevation-0"
	          dense
	          mobile-breakpoint="200"
	        >
	        </v-data-table>
		    </v-card-text>
		    <v-card-actions>
		  	<v-btn 
		  		tile
		  		top
		  		absolute
		  		icon
		  		right
		  		@click="dialogMensajes = false"
		  	>
		  		<v-icon small left>mdi-close</v-icon>
		  	</v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
		  </v-card>
		</v-dialog>

    <!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>

  </v-dialog>
</template>
<script>
	import axios from 'axios';
	import { mapGetters } from "vuex";
	// Componentes
	import Alerta from "@/components/alertas/Alerta.vue";
	import carga from "@/components/alertas/carga.vue";

	import validarErrores from "@/mixins/validarErrores";
	import funcionesExcel from "@/mixins/funcionesExcel";

	import * as am5 from "@amcharts/amcharts5";
	import * as am5xy from "@amcharts/amcharts5/xy";
	import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";

	export default {

	  mixins: [ validarErrores, funcionesExcel ],

	  props: ['dialogReporteSucursal','escuela', 'nivel','leccion', 'contadorVendedoras'],

	  components: {
	    Alerta,
	    carga,
	  },

		data: () => ({
	    // Alertas
	    parametros:{
	      dialogError: false,
	      mensaje: '',
	      color: ''
	    },

	    respuestaAlerta:false,
	    loader: true,
	    cargar: false,

	    fechaini: null,
	    fechafin: null,

	    planteles:[],

	    verGraficaInbi: false,
	    verGraficaFast: false,
	    chart: null,
      root: null,
      loading: true,

      headers: [],
      tablaInbi: [],
      tablaFast:[],
      dataTableContactos:[],
			dialogMensajes: false,

			headers2:[
				{ text: "Folio"         , value: "folio"            },
        { text: "Nombre"        , value: "nombre_completo"  },
        { text: "Plantel"       , value: "plantel"          },
        { text: "Twilio"        , value: "twilio"           },
        { text: "Mensaje"       , value: "mensaje"          },
        { text: "Fecha"         , value: "fecha_creacion"   },
			]

	  }),

	  computed: {
	  	...mapGetters('login',['getdatosUsuario']),
	    formTitle () {
	      return this.editedIndex === -1 ? 'Agregar Diapositiva' : 'Editar Diapositiva'
	    },

	  },

	  watch: {
	    dialogDelete (val) {
	      val || this.closeDelete( )
	    },

	    fechaini ( val ){
	      val || this.initialize( )
	    }
	  },

	  async created () {
	    // await this.initialize()
	  },

	  mounted() {
	    
	  },

	  beforeDestroy() {
	    if (this.root) {
	      this.root.dispose();
	    }
  	},

	  methods: {

	    initialize() {
	      this.cargar = true;
	      this.vendedoras = []

	      const payload = {
	        fechaini : this.fechaini,
	        fechafin : this.fechafin
	      }

	      return axios.post('grafica.contactos.sucursal', payload ).then(response => {

	      	this.headers   = response.data.headers 
	      	this.tablaInbi = response.data.tablaInbi 
	      	this.tablaFast = response.data.tablaFast 

	      	this.makeInbi( response )
	      	this.makeFast( response )

	        // DATOS PARA LA GRAFICA
	        this.cargar = false;

	      }).catch(error => {
	        this.validarError(error.response.data.message);
	      }).finally(() => {
	        this.cargar = false;
	      });
	    },

	    makeInbi( value ){


	    	return
	    	// Crear raíz del gráfico
		    const root     = am5.Root.new(this.$refs.chartdiv);
		    // const rootFast = am5.Root.new(this.$refs.chartdivfast);

		    // Aplicar tema
		    root.setThemes([am5themes_Animated.new(root)]);

				// https://www.amcharts.com/docs/v5/charts/xy-chart/
				let chart = root.container.children.push(am5xy.XYChart.new(root, {
				  panX: false,
				  panY: false,
				  wheelX: "panX",
				  wheelY: "zoomX",
				  paddingLeft: 0,
				  layout: root.verticalLayout
				}));

				chart.set("scrollbarX", am5.Scrollbar.new(root, {
				  orientation: "horizontal"
				}));

				let data     = value.data.respuestaInbi

				// Create axes
				// https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
				let xRenderer = am5xy.AxisRendererX.new(root, {
				  minorGridEnabled: true
				});

				let xAxis = chart.xAxes.push(am5xy.CategoryAxis.new(root, {
				  categoryField: "fecha",
				  renderer: xRenderer,
				  tooltip: am5.Tooltip.new(root, {})
				}));

				xRenderer.grid.template.setAll({
				  location: 1
				})

				xAxis.data.setAll(data);

				let yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
				  min: 0,
				  max: 100,
				  numberFormat: "#'%'",
				  strictMinMax: true,
				  calculateTotals: true,
				  renderer: am5xy.AxisRendererY.new(root, {
				    strokeOpacity: 0.1
				  })
				}));

				// Add legend
				// https://www.amcharts.com/docs/v5/charts/xy-chart/legend-xy-series/
				let legend = chart.children.push(am5.Legend.new(root, {
				  centerX: am5.p50,
				  x: am5.p50
				}));

				// Add series
				// https://www.amcharts.com/docs/v5/charts/xy-chart/series/
				function makeSeries(name, fieldName) {
				  let series = chart.series.push(am5xy.ColumnSeries.new(root, {
				    name: name,
				    stacked: true,
				    xAxis: xAxis,
				    yAxis: yAxis,
				    valueYField: fieldName,
				    valueYShow: "valueYTotalPercent",
				    categoryXField: "fecha"
				  }));

				  series.columns.template.setAll({
				    tooltipText: "{name}, {categoryX}:{valueYTotalPercent.formatNumber('#.#')}%",
				    tooltipY: am5.percent(10)
				  });
				  series.data.setAll(data);

				  // Make stuff animate on load
				  // https://www.amcharts.com/docs/v5/concepts/animations/
				  series.appear();

				  series.bullets.push(function () {
				    return am5.Bullet.new(root, {
				      sprite: am5.Label.new(root, {
				        text: "{valueYTotalPercent.formatNumber('#.#')}%",
				        fill: root.interfaceColors.get("alternativeText"),
				        centerY: am5.p50,
				        centerX: am5.p50,
				        populateText: true
				      })
				    });
				  });

				  legend.data.push(series);
				}

				let planteles = value.data.plantelesInbi

				for( const i in planteles ){

					const { plantel } = planteles[i]

					makeSeries( plantel, plantel )

				}

				// Make stuff animate on load
				// https://www.amcharts.com/docs/v5/concepts/animations/
				chart.appear(1000, 100);

      	this.verGraficaInbi = true
	    },

	    makeFast( value ){

	    	return
    	  // Crear raíz del gráfico
		    const root = am5.Root.new(this.$refs.chartdivfast);

		    // Aplicar tema
		    root.setThemes([am5themes_Animated.new(root)]);

				// https://www.amcharts.com/docs/v5/charts/xy-chart/
				let chart = root.container.children.push(am5xy.XYChart.new(root, {
				  panX: false,
				  panY: false,
				  wheelX: "panX",
				  wheelY: "zoomX",
				  paddingLeft: 0,
				  layout: root.verticalLayout
				}));

				chart.set("scrollbarX", am5.Scrollbar.new(root, {
				  orientation: "horizontal"
				}));

				let data     = value.data.respuestaFast

				// Create axes
				// https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
				let xRenderer = am5xy.AxisRendererX.new(root, {
				  minorGridEnabled: true
				});

				let xAxis = chart.xAxes.push(am5xy.CategoryAxis.new(root, {
				  categoryField: "fecha",
				  renderer: xRenderer,
				  tooltip: am5.Tooltip.new(root, {})
				}));

				xRenderer.grid.template.setAll({
				  location: 1
				})

				xAxis.data.setAll(data);

				let yAxis = chart.yAxes.push(am5xy.ValueAxis.new(root, {
				  min: 0,
				  max: 100,
				  numberFormat: "#'%'",
				  strictMinMax: true,
				  calculateTotals: true,
				  renderer: am5xy.AxisRendererY.new(root, {
				    strokeOpacity: 0.1
				  })
				}));

				// Add legend
				// https://www.amcharts.com/docs/v5/charts/xy-chart/legend-xy-series/
				let legend = chart.children.push(am5.Legend.new(root, {
				  centerX: am5.p50,
				  x: am5.p50
				}));

				// Add series
				// https://www.amcharts.com/docs/v5/charts/xy-chart/series/
				function makeSeries(name, fieldName) {
				  let series = chart.series.push(am5xy.ColumnSeries.new(root, {
				    name: name,
				    stacked: true,
				    xAxis: xAxis,
				    yAxis: yAxis,
				    valueYField: fieldName,
				    valueYShow: "valueYTotalPercent",
				    categoryXField: "fecha"
				  }));

				  series.columns.template.setAll({
				    tooltipText: "{name}, {categoryX}:{valueYTotalPercent.formatNumber('#.#')}%",
				    tooltipY: am5.percent(10)
				  });
				  series.data.setAll(data);

				  // Make stuff animate on load
				  // https://www.amcharts.com/docs/v5/concepts/animations/
				  series.appear();

				  series.bullets.push(function () {
				    return am5.Bullet.new(root, {
				      sprite: am5.Label.new(root, {
				        text: "{valueYTotalPercent.formatNumber('#.#')}%",
				        fill: root.interfaceColors.get("alternativeText"),
				        centerY: am5.p50,
				        centerX: am5.p50,
				        populateText: true
				      })
				    });
				  });

				  legend.data.push(series);
				}

				let planteles = value.data.plantelesFast

				for( const i in planteles ){

					const { plantel } = planteles[i]

					makeSeries( plantel, plantel )

				}

				// Make stuff animate on load
				// https://www.amcharts.com/docs/v5/concepts/animations/
				chart.appear(1000, 100);

      	this.verGraficaFast = true
	    },


	    close () {
	      this.dialog = false
	      this.$nextTick(() => {
	        this.editedItem = Object.assign({}, this.defaultItem)
	        this.editedIndex = -1
	      })
	    },

	    closeDelete () {
	      this.dialogDelete = false
	      this.$nextTick(() => {
	        this.editedItem = Object.assign({}, this.defaultItem)
	        this.editedIndex = -1
	      })
	    },

	    verMensajes( item ){
	      this.mensaje = null
	      this.mensaje = item.utlimomensaje
	      this.dialogVerMensaje = true
	    },

	    abrirDatos( datos ){

	    	console.log( datos )

	    	this.dataTableContactos = datos
	    	this.dialogMensajes = true
	    }

	  },
	}
</script>
<style scoped>
  .dropZone {
    width: 80%;
    height: 150px;
    position: relative;
    border: 2px dashed #eee;
  }

  .dropZone:hover {
    border: 2px solid #D8D8D8;
  }

  .dropZone:hover .dropZone-title {
    color: #1975A0;
  }

  .dropZone-info {
    color: #A8A8A8;
    position: absolute;
    top: 50%;
    width: 100%;
    transform: translate(0, -50%);
    text-align: center;
  }

  ..dropZone-title {
    color: #787878;
  }

  .dropZone input {
    position: absolute;
    cursor: pointer;
    top: 0px;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
  }

  .dropZone-upload-limit-info {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
  }

  .dropZone-over {
    background: #D8D8D8;
    opacity: 0.8;
  }

  .dropZone-uploaded {
    width: 80%;
    height: 200px;
    position: relative;
    border: 2px dashed #eee;
  }

  .dropZone-uploaded-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #D8D8D8;
    position: absolute;
    top: 50%;
    width: 100%;
    transform: translate(0, -50%);
    text-align: center;
  }

  .removeFile {
    width: 200px;
  }

  .chat {
    width: calc(100%);
  }

  .chat .messages-chat {
    padding: 25px 10px;
  }

  .chat .messages-chat .message {
    display:flex;
    align-items: center;
    margin-bottom: 2px;
  }

  .chat .messages-chat .message .photo {
    display: block;
    width: 45px;
    height: 45px;
    background: #E6E7ED;
    -moz-border-radius: 50px;
    -webkit-border-radius: 50px;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .chat .messages-chat .text {
    margin: 0 35px;
    background-color: #fff;
    padding: 15px;
    border-radius: 12px;
  }

  .text-only {
    white-space: pre-line;

  }

  .time {
    font-size: 12px;
    color:lightgrey;
    margin-bottom:10px;
    margin-left: 38px;
  }

  .response-time {
    float: right;
    margin-right: 40px !important;
  }

  .response {
    float: right;
    margin-right: 0px !important;
    margin-left: auto; /* flexbox alignment rule */
    max-width: 90%;
  }

  .noresponse {
    max-width: 90%;
  }

  .noresponse .text{
    box-shadow: 0 20px 27px 0 rgba(0,0,0,0.05)  !important;
  }

  .response .text {
    background-color: #c5e6c1 !important;
    color: black;
    box-shadow: 0 20px 27px 0 rgba(0,0,0,0.05)  !important;

  }

  .audio {
    background-color: #f1f3f4 !important;
  }

  .response .audio {
    background-color: #f1f3f4 !important;
  }

  .footer-chat {
    width: calc(65% - 66px);
    height: 80px;
    display:flex;
    align-items: center;
    position:absolute;
    bottom: 0;
    background-color: transparent;
    border-top: 2px solid #EEE;

  }

  .chat .footer-chat .icon {
    margin-left: 30px;
    color:#C0C0C0;
    font-size: 14pt;
  }

  .chat .footer-chat .send {
    color:#fff;
    background-color: #4f6ebd;
    position: absolute;
    right: 50px;
    padding: 12px 12px 12px 12px;
    border-radius: 50px;
    font-size: 14pt;
  }

  .chat .footer-chat .name {
    margin: 0 0 0 20px;
    text-transform: uppercase;
    font-family:'Montserrat', sans-serif;
    font-size: 13pt;
    color:#515151;
  }

  .chat .footer-chat .right {
    position: absolute;
    right: 40px;
  }
</style>