<template>
	<v-container class="mt-4 ml-2" fluid>
	  <v-row>
	  	<v-col cols="12">
	  		<v-card class="shadowCard">
	  		  <v-card-title primary-title>
	  		    <span class="text-subtitle-1">Directorio de Teachers</span>
	  		    <v-spacer></v-spacer>

            <!-- <v-btn 
              class="mr-2" 
              small dark color="green"
              @click="exportExcel( diretorioteachers  , 'MODALIDADES_ERP')"
              tile
            >
              <v-icon small>mdi-microsoft-excel</v-icon>
            </v-btn> -->

            <v-btn
              color="primary"
              dark
              class="text-capitalize"
              @click="initialize()"
              small
              tile
            >
              <v-icon left small>mdi-refresh</v-icon>
              Actualizar
            </v-btn>
	  		  </v-card-title>
          
	  		  <v-card-text>
	  		  	<v-row justify="end">
	  		  		<v-col cols="12" md="6">
	  		  			<v-text-field
	  		  			  name="name"
	  		  			  label="Buscar"
	  		  			  id="id"
	  		  			  filled
	  		  			  dense
	  		  			  hide-details
	  		  			  append-icon="mdi-magnify"
	  		  			  v-model="search"
	  		  			></v-text-field>
	  		  		</v-col>
	  		  	</v-row>
	  		    <v-row>
	  		    	<v-col cols="12">
							  <v-data-table
							    :headers="headers"
							    :items="diretorioteachers"
							    class="elevation-0"
							    :search="search"
							    :mobile-breakpoint="100"
			            dense
							  >
							    <template v-slot:no-data>
							      <v-btn
							        color="primary"
							        @click="initialize"
							        small
							      >
							        Actualizar
							      </v-btn>
							    </template>
							  </v-data-table>
					  	</v-col>
					  </v-row>
					</v-card-text>
	  		</v-card>
	  	</v-col>
	  </v-row>

  	<!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>

	</v-container>
</template>
<script>
import axios from 'axios';
	import { mapGetters } from 'vuex'
	// Componentes
  import Alerta                from '@/components/alertas/Alerta.vue'
  import carga                 from '@/components/alertas/carga.vue';

  import validarErrores        from '@/mixins/validarErrores'
  import funcionesExcel        from '@/mixins/funcionesExcel'


  export default {

    mixins: [ validarErrores, funcionesExcel ],

  	components:{
      Alerta,
      carga,
    },

    data: () => ({
    	// Alertas
    	parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      loader: true,
      cargar: false,

      headers: [
        { text: 'ID'         , align: 'start', value: 'id_usuario' },
        { text: 'Teacher'    , value: 'nombre_completo'   },
        // { text: 'Teléfono 1' , value: 'telefono1'   },
        // { text: 'Teléfono 2' , value: 'telefono2' },
        { text: 'Plantel'    , value: 'plantel' },
      ],
      diretorioteachers: [],
      // tabla
			search:'',
    }),

    created () {
      this.initialize()
    },

    methods: {
      initialize () {
        this.diretorioteachers = []
        this.cargar = true
        this.$http.get('diretorioteachers.list').then(response=>{
        	this.diretorioteachers = response.data
        	this.cargar = false
        }).catch((error) => {
          this.validarError(error);
	      }).finally(() => {
	        this.cargar = false;
	      });
      },
    }
  }
</script>


