<template>
	<v-card class="elevation-0" >
		<v-row>
			<v-col cols="12" class="px-8">
				<v-card class="shadowCard">
				  <v-card-title primary-title class="subtitle-1">
				    <b>Datos del alumno</b>
				  </v-card-title>
				  <v-card-text class="black--text">

				  	<b>Matricula:</b> {{ alumno.matricula }}
				  	<br/>
				  	<b>Nombre:</b> {{ alumno.nombre }}

				  	<v-divider class="my-2"></v-divider>

				  	<!-- TIPO DE ALUMNOS -->
				  	<label >Estatus del alumno actualmente</label>
				  	<br/>
		        <span v-if="grupo && grupo.tipoAlumno == 1"><b>ALUMNO NI</b></span>
	          <span v-if="grupo && grupo.tipoAlumno == 2"><b>ALUMNO REGULAR</b></span>
	          <span v-if="grupo && grupo.tipoAlumno == 3" class="orange--text"><b>ALUMNO IRREGULAR</b></span>
	          <span v-if="grupo && grupo.tipoAlumno == 4" class="red--text"><b>ALUMNO CON ADEUDO</b></span>
	          <span v-if="grupo && grupo.tipoAlumno == 5" ><b>ALUMNO PENDIENTE DE ABRIR GRUPO</b></span>
	          <span v-if="grupo && grupo.tipoAlumno == 6" class="red--text"><b>ALUMNO CON DIFERENCIA DE PAGO</b></span>

				  </v-card-text>
				</v-card>
			</v-col>

			<!-- Aquí hay que poner algo para los que son de tipo 06 -->
      <v-col cols="12" v-if="grupo && [6].includes(grupo.tipoAlumno) " class="px-8">
        <v-card class="shadowCard">
          <v-card-title primary-title class="subtitle-1">
            <strong>GRUPO:</strong> {{ grupo.getUltimoGrupoPagado.grupo }}
          </v-card-title>
          <v-card-text class="black--text">
            <strong>DIFERENCIA:</strong> $ {{ grupo.getUltimoGrupoPagado.diferencia }}
          <br/>
          <br/>

          <v-alert
            dense
            outlined
            type="error"
          >
            {{ grupo.message }}
          </v-alert>
          </v-card-text>
        </v-card>
      </v-col>


      <!-- grupos Alumno unico -->
		  <v-col cols="12" v-if="grupo && [2,4,5].includes(grupo.tipoAlumno)" class="px-8">
		  	<v-card class="shadowCard">
		  	  <v-card-title primary-title class="subtitle-1">
            <b>Grupo Actual del alumno</b>
          </v-card-title>
		  	  <v-card-text class="black--text">
				  	<div class="text-subtitle-1">
				  		{{ grupo.grupoActual.grupo }}
				  		<br/>
		          <strong class="red--text">Adeudo: $ {{ grupo.grupoActual.adeudo }}<br/></strong>
				  	</div>
		  	  </v-card-text>
		  	  <v-card-actions v-if="grupo.grupoActual.adeudo <= 0">
            <v-btn
              color="green"
              dark
              small
              @click="steppers.paso = 3"
              tile
            >
              <v-icon small left>mdi-cash</v-icon>
              Pagar Factura
            </v-btn>
		  	  </v-card-actions>
		  	</v-card>
		  </v-col>

		  <v-col cols="12" v-if="errorMensaje" class="px-8">
		  	<v-alert
		      dense
		      outlined
		      type="error"
		    >
		      {{ errorMensaje }}
		    </v-alert>
		  </v-col>

      <!-- Alumnos DONDE EL GRUPO SE CERRÓ O NO TIENE CUPO SU GRUPO -->
      <!-- SELECCIONAR UN GRUPO DIFERENTE -->
      <v-col cols="12" v-if="!grupo" class="px-8">
        <v-card class="shadowCard">
          <v-card-title primary-title class="subtitle-1">
            Selecciona un nuevo grupo para el alumno
          </v-card-title>
          <v-card-text>
            <v-autocomplete
              label="Grupos"
              dense
              v-model="alumno.grupo"
              :items="grupos"
              item-text="grupo"
              item-value="id_grupo"
              clearable
              filled
              return-object
            ></v-autocomplete>
          </v-card-text>
        </v-card>
      </v-col>

		  <!-- Alumnos de reingreso -->
		  <!-- SELECCIONAR UN GRUPO DIFERENTE -->
      <v-col cols="12" v-if="grupo && [3].includes(grupo.tipoAlumno)" class="px-8">
        <v-card class="shadowCard">
          <v-card-title primary-title class="subtitle-1">
            Selecciona un nuevo grupo para el alumno
          </v-card-title>
          <v-card-text>
            <v-autocomplete
              label="Grupos"
              dense
              v-model="alumno.grupo"
              :items="grupos"
              item-text="grupo"
              item-value="id_grupo"
              clearable
              filled
              return-object
            ></v-autocomplete>
          </v-card-text>
        </v-card>
      </v-col>

      <!-- ALUMNO RI REGUALAR -->
      <!-- Seleccionar otro grupo, alumnos NO HERMANOS -->
      <v-col cols="12" v-if="grupo && [2,4,5].includes(grupo.tipoAlumno) || grupo && [2,4,5].includes(grupo.tipoAlumno)" class="px-8">
        <v-card class="shadowCard">
          <v-card-title primary-title class="subtitle-1">
            <b>Grupo siguiente:</b>
          </v-card-title>
          <v-card-text class="black--text">
            <!-- Seleccionar otro grupo diferente al siguiente -->
            <v-autocomplete
              label="Selecciona un grupo"
              dense
              v-if="otroGrupo"
              v-model="alumno.grupo"
              :items="grupos"
              item-text="grupo"
              item-value="id_grupo"
              clearable
              filled
              return-object
            ></v-autocomplete>

            <div class="text-subtitle-1" v-if="grupo.grupoSiguiente">
              <span v-if="!otroGrupo">{{ grupo.grupoSiguiente.grupo }}</span>
              <span v-if="grupo.capacidad">El siguiente grupo no cuenta con capacidad para inscribir alumnos</span>
              <v-switch label="Seleccionar otro grupo" v-model="otroGrupo" hide-details v-if="grupo.grupoActual.adeudo <= 0"></v-switch>
            </div>

            <div class="text-subtitle-1" v-if="!grupo.grupoSiguiente">
              <strong>{{ errorMensaje }}
                <br/>
              </strong>
              <span v-if="!otroGrupo"></span>
              <v-switch label="Seleccionar otro grupo" v-model="otroGrupo" hide-details></v-switch>
            </div>
          </v-card-text>
        </v-card>
      </v-col>

		</v-row>

		<v-divider class="my-4"></v-divider>

		<v-card-actions>
      <v-btn
        text
        @click="dialogAddAlumno.estatus = false"
      >
        Cancelar
      </v-btn>

      <v-btn
        tile
        color="primary"
        class="ml-2"
        @click="steppers.paso = 1"
      >
        <v-icon left small>mdi-arrow-left</v-icon>
        Regresar
      </v-btn>
      <v-spacer></v-spacer>
      <v-btn
      	tile
        color="primary"
        @click="steppers.paso = 3"
      >
        Siguiente
      	<v-icon right small>mdi-arrow-right</v-icon>
      </v-btn>
  	</v-card-actions>


  	<!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>

    <AgregarProspecto     v-if="existeProspecto"             :parametrosProspectos="parametrosProspectos" @saveProspecto="saveProspecto"/>


	</v-card>
</template>
<script>
import axios from 'axios';
	import { mapGetters, mapActions } from 'vuex'
  import Alerta          from '@/components/alertas/Alerta.vue'
  import carga           from '@/components/alertas/carga.vue';

  import AgregarProspecto   from '@/components/inscripciones/AgregarProspecto.vue';


  import validarErrores  from '@/mixins/validarErrores'

	// Componentes
  export default {
  	components:{
      Alerta,
      carga,
      AgregarProspecto
    },

    mixins: [ validarErrores ],

  	props:[
			'alumno',
			'steppers',
			'hermanos',
			'tutor'
	  ],

    data: () => ({
    	escuela: 2,
			// Alertas
    	parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      cargar: false,

    	prospectos:[],
    	parametrosProspectos:{
    		estatus: false
    	},

    	existeProspecto:false,
    	prospecto: null,
    	grupos:[],

    	grupo: null,
    	errorMensaje:null,
      otroGrupo: false,
      nuevoGrupoSeleccionado:null

    }),

    computed: {
    	...mapGetters('login',['getdatosUsuario', 'getEscuela']),
    },

    watch:{
      'alumno.grupo'( value ){
        console.log( value )
      }
    },

    async created () {
    	this.escuela = this.getEscuela
    	await this.getGruposAlumno( )
    	await this.getGruposDisponibles( )
    },

    methods: {

    	async getGruposAlumno( ) {
      	this.cargar = true

      	const payload = {
      		id_alumno: this.alumno.id_alumno,
      		escuela  : this.alumno.unidad_negocio
      	}

        try {
          await this.$http.post('inscripciones.grupo.alumno', payload).then(response=>{

            console.log( response.data )
          	this.cargar = false
          	this.grupo = response.data

          	this.alumno.tipoAlumno = this.grupo.tipoAlumno

            if( this.grupo && ![2,4].includes(this.grupo.tipoAlumno)){
              this.getGruposDisponibles( )
            }

            this.alumno.grupo = this.grupo.grupoSiguiente

          }).catch( error =>{

            if( error.response.data.grupoActual ){

              this.grupo = error.response.data
              this.alumno.tipoAlumno = this.grupo.tipoAlumno
              console.log( this.alumno.tipoAlumno ,'tipo de alumno')

            }else{

            }

            if( error.response.data.tipoAlumno ){
              this.alumno.tipoAlumno = error.response.data.tipoAlumno

            }

            this.errorMensaje = error.response.data.message
            this.validarError( error.response.data.message )

          }).finally( () => { this.cargar = false })
        }catch ( error ){
          console.log( error )
        }
      },

      getGruposDisponibles( ) {
        this.cargar = true
        this.grupos = []

        return this.$http.get('grupos.disponibles/' + this.alumno.unidad_negocio ).then(response=>{
          this.cargar = false
          this.grupos = response.data

          for( const i in this.grupos ){

            this.grupos[i]['tipoAlumno'] = this.grupo.tipoAlumno

          }

        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

    },
  }
</script>
