import Vue from 'vue'
import store from '@/store'
import {mapGetters, mapActions}    from 'vuex'

const axios  = require('axios');
var moment   = require('moment');

moment.locale(); 

export default {
	data: () => ({
    // Alertas
    parametros:{
      dialogError: false,
      mensaje: '',
      color: ''
    },

    respuestaAlerta:false,
    loader: true,
    cargar: false,


  }),

	computed: {
		...mapGetters('Carrito', ['getCarrito']),
		...mapGetters('login', ['getdatosUsuario','getLogeado', 'getEscuela','getEstatusLlamada','getTareas','getNuevosProspectos']),
    ...mapGetters('tema', ['getTema']),
    ...mapGetters('Carrito',['getCarrito']),
	},

	created () {

  },

  methods: {
    
    generaRequiOperativaInbi( nominaActual ){
  		let opEscuela = 1
			// Validamos la escuela
			const esFast = opEscuela !== 1;

			let data = nominaActual.filter( el => ![31,169,597].includes( el.id_usuario ) )

			// Hacemos el filtro de la información
			const nominaInbiOperaciones = data.filter(el => {
			    const esOperaciones = el.departamento === 'OPERACIONES';
			    const coincideFast = el.plantel.match('FAST');
			    return esOperaciones && (esFast ? coincideFast : !coincideFast);
			});

			let detallesInbiOperaciones = []

			for( const i in nominaInbiOperaciones ){

				const { id_usuario, monto_laborado, nombre_completo, id_plantel, plantel, monto_horas_extras, monto_total_rebajes  } = nominaInbiOperaciones[i]


				let monto1 = monto_laborado ? parseFloat( monto_laborado.replace('$','').replace(',','') ) : 0
				let monto2 = monto_horas_extras ? parseFloat( monto_horas_extras.replace('$','').replace(',','') ) : 0
				let monto3 = monto_total_rebajes ? parseFloat( monto_total_rebajes.replace('$','').replace(',','') ) : 0

				let total = monto1 + monto2 - monto3

				detallesInbiOperaciones.push({
					id_elemento_requisicion: 17,
					id_plantel_resultados: id_plantel,
					id_plantel,
					plantel,
					concepto: nombre_completo,
					cantidad: 1,
					costo_unitario: total.toFixed( 2 ),
					costo_total: total.toFixed( 2 ),
					id_alumno: 0,
					id_grupo: 0,
					id_empleado: id_usuario
				})
			}

			// NOMINA OFICINAS
			// Hacemos el filtro de la información
			const nominaOficinas =  nominaActual.filter( el => (el.departamento != 'OPERACIONES' && el.escuela == opEscuela) || ( [31,169,597].includes( el.id_usuario ) && el.escuela == opEscuela ) )

			for( const i in nominaOficinas ){

				const { id_usuario, monto_laborado, nombre_completo, id_plantel, plantel, monto_horas_extras, monto_total_rebajes } = nominaOficinas[i]

				let monto1 = monto_laborado ? parseFloat ( monto_laborado.replace('$','').replace(',','') ) : 0
				let monto2 = monto_horas_extras ? parseFloat ( monto_horas_extras.replace('$','').replace(',','') ) : 0
				let monto3 = monto_total_rebajes ? parseFloat ( monto_total_rebajes.replace('$','').replace(',','') ) : 0

				let total = monto1 + monto2 - monto3

				detallesInbiOperaciones.push({
					id_elemento_requisicion: 17,
					id_plantel_resultados: id_plantel,
					id_plantel,
					plantel,
					concepto: nombre_completo,
					cantidad: 1,
					costo_unitario: total.toFixed( 2 ),
					costo_total: total.toFixed( 2 ),
					id_alumno: 0,
					id_grupo: 0,
					id_empleado: id_usuario
				})
			}


			var payload = {
    		registro:                      null,
    		id_usuario_solicita:           this.getdatosUsuario.iderp,
	      id_sucursal_solicita:          this.getdatosUsuario.id_plantel,
	      id_requisicion_compra_estatus: 5,
	      id_usuario_ultimo_cambio:      this.getdatosUsuario.iderp,
	      id_usuario_compra:             28,
	      tipo_requisicion:              1,
	      id_ciclo:                      this.ciclo.id_ciclo,
        id_proyecto:                   null,
	      comentarios:                   'Nómina',
	      detalles:                      detallesInbiOperaciones,
		    fotos:[]
      }

			return detallesInbiOperaciones

		},

		generaRequiOperativaFast( nominaActual ){
  		let opEscuela = 2
			// Validamos la escuela
			const esFast = opEscuela !== 1;

			let data = nominaActual.filter( el => ![31,169,597].includes( el.id_usuario ) )

			// Hacemos el filtro de la información
			const nominaInbiOperaciones = data.filter(el => {
			    const esOperaciones = el.departamento === 'OPERACIONES';
			    const coincideFast = el.plantel.match('FAST');
			    return esOperaciones && (esFast ? coincideFast : !coincideFast);
			});

			let detallesInbiOperaciones = []

			for( const i in nominaInbiOperaciones ){

				const { id_usuario, monto_laborado, nombre_completo, id_plantel, plantel, monto_horas_extras, monto_total_rebajes  } = nominaInbiOperaciones[i]


				let monto1 = monto_laborado ? parseFloat( monto_laborado.replace('$','').replace(',','') ) : 0
				let monto2 = monto_horas_extras ? parseFloat( monto_horas_extras.replace('$','').replace(',','') ) : 0
				let monto3 = monto_total_rebajes ? parseFloat( monto_total_rebajes.replace('$','').replace(',','') ) : 0

				let total = monto1 + monto2 - monto3

				detallesInbiOperaciones.push({
					id_elemento_requisicion: 17,
					id_plantel_resultados: id_plantel,
					id_plantel,
					plantel,
					concepto: nombre_completo,
					cantidad: 1,
					costo_unitario: total.toFixed( 2 ),
					costo_total: total.toFixed( 2 ),
					id_alumno: 0,
					id_grupo: 0,
					id_empleado: id_usuario
				})
			}

			// NOMINA OFICINAS
			// Hacemos el filtro de la información
			const nominaOficinas =  nominaActual.filter( el => (el.departamento != 'OPERACIONES' && el.escuela == opEscuela) || ( [31,169,597].includes( el.id_usuario ) && el.escuela == opEscuela ) )

			for( const i in nominaOficinas ){

				const { id_usuario, monto_laborado, nombre_completo, id_plantel, plantel, monto_horas_extras, monto_total_rebajes } = nominaOficinas[i]

				let monto1 = monto_laborado ? parseFloat ( monto_laborado.replace('$','').replace(',','') ) : 0
				let monto2 = monto_horas_extras ? parseFloat ( monto_horas_extras.replace('$','').replace(',','') ) : 0
				let monto3 = monto_total_rebajes ? parseFloat ( monto_total_rebajes.replace('$','').replace(',','') ) : 0

				let total = monto1 + monto2 - monto3

				detallesInbiOperaciones.push({
					id_elemento_requisicion: 17,
					id_plantel_resultados: id_plantel,
					id_plantel,
					plantel,
					concepto: nombre_completo,
					cantidad: 1,
					costo_unitario: total.toFixed( 2 ),
					costo_total: total.toFixed( 2 ),
					id_alumno: 0,
					id_grupo: 0,
					id_empleado: id_usuario
				})
			}


			var payload = {
    		registro:                      null,
    		id_usuario_solicita:           this.getdatosUsuario.iderp,
	      id_sucursal_solicita:          this.getdatosUsuario.id_plantel,
	      id_requisicion_compra_estatus: 5,
	      id_usuario_ultimo_cambio:      this.getdatosUsuario.iderp,
	      id_usuario_compra:             28,
	      tipo_requisicion:              1,
	      id_ciclo:                      this.ciclo.id_ciclo_relacionado,
        id_proyecto:                   null,
	      comentarios:                   'Nómina',
	      detalles:                      detallesInbiOperaciones,
		    fotos:[]
      }

			return detallesInbiOperaciones

		},


		generaRequiComisionesInbi( nominaActual ){

			let opEscuela = 1
			// Validamos la escuela
			const esFast = opEscuela !== 1;

			const data = this.nominaActual.filter( el => el.comisiones_semana )

			// Hacemos el filtro de la información
			const nominaInbiOperaciones = data.filter(el => {
			    const esOperaciones = el.departamento === 'OPERACIONES';
			    const coincideFast = el.plantel.match('FAST');
			    return esOperaciones && (esFast ? coincideFast : !coincideFast);
			});

			let detallesInbiOperaciones = []

			for( const i in nominaInbiOperaciones ){

				const { id_usuario, comisiones_semana, nombre_completo, id_plantel, plantel } = nominaInbiOperaciones[i]

				detallesInbiOperaciones.push({
					id_elemento_requisicion: 17,
					id_plantel_resultados: id_plantel,
					id_plantel,
					plantel,
					concepto: nombre_completo + ` ( COMISIONES S${3} )`,
					cantidad: 1,
					costo_unitario: comisiones_semana.replace('$','').replace(',',''),
					costo_total: comisiones_semana.replace('$','').replace(',',''),
					id_alumno: 0,
					id_grupo: 0,
					id_empleado: id_usuario
				})
			}

			var payload = {
    		registro:                      null,
    		id_usuario_solicita:           this.getdatosUsuario.iderp,
	      id_sucursal_solicita:          this.getdatosUsuario.id_plantel,
	      id_requisicion_compra_estatus: 5,
	      id_usuario_ultimo_cambio:      this.getdatosUsuario.iderp,
	      id_usuario_compra:             28,
	      tipo_requisicion:              1,
	      id_ciclo:                      this.ciclo.id_ciclo,
        id_proyecto:                   null,
	      comentarios:                   'Nómina',
	      detalles:                      detallesInbiOperaciones,
		    fotos:[]
      }

      // this.exportExcel( detallesInbiOperaciones, 'Comisiones INBI')

			return detallesInbiOperaciones

		},

		generaRequiComisionesFast( nominaActual ){
			// Validamos la escuela
			let opEscuela = 2
			// Validamos la escuela
			const esFast = opEscuela !== 1;

			const data = this.nominaActual.filter( el => el.comisiones_semana )

			// Hacemos el filtro de la información
			const nominaInbiOperaciones = data.filter(el => {
			    const esOperaciones = el.departamento === 'OPERACIONES';
			    const coincideFast = el.plantel.match('FAST');
			    return esOperaciones && (esFast ? coincideFast : !coincideFast);
			});

			let detallesInbiOperaciones = []

			for( const i in nominaInbiOperaciones ){

				const { id_usuario, comisiones_semana, nombre_completo, id_plantel, plantel } = nominaInbiOperaciones[i]

				detallesInbiOperaciones.push({
					id_elemento_requisicion: 17,
					id_plantel_resultados: id_plantel,
					id_plantel,
					plantel,
					concepto: nombre_completo + ` ( COMISIONES S${3} )`,
					cantidad: 1,
					costo_unitario: comisiones_semana.replace('$','').replace(',',''),
					costo_total: comisiones_semana.replace('$','').replace(',',''),
					id_alumno: 0,
					id_grupo: 0,
					id_empleado: id_usuario
				})
			}

			var payload = {
    		registro:                      null,
    		id_usuario_solicita:           this.getdatosUsuario.iderp,
	      id_sucursal_solicita:          this.getdatosUsuario.id_plantel,
	      id_requisicion_compra_estatus: 5,
	      id_usuario_ultimo_cambio:      this.getdatosUsuario.iderp,
	      id_usuario_compra:             28,
	      tipo_requisicion:              1,
	      id_ciclo:                      this.ciclo.id_ciclo_relacionado,
        id_proyecto:                   null,
	      comentarios:                   'Nómina',
	      detalles:                      detallesInbiOperaciones,
		    fotos:[]
      }

      // this.exportExcel( detallesInbiOperaciones, 'Comisiones INBI')

			return detallesInbiOperaciones
		},

		generaRequiBonosInbi( nominaActual ){

			let opEscuela = 1
			// Validamos la escuela
			const esFast = this.opEscuela !== 1;


			// Hacemos el filtro de la información
			const data = this.nominaActual.filter(el => {
		    const esOperaciones = el.departamento === 'OPERACIONES';
		    const coincideFast = el.plantel.match('FAST');
		    return esOperaciones && (esFast ? coincideFast : !coincideFast);
			});

			// comisiones_semana
			// monto_total_bonos

			// Mejor vendedora
			let bono1 = data.filter( el => el.bonos_ventas )

			// Bono RI
			let bono2 = data.filter( el => el.bonos_ri )

			// Bono PYA
			let bono3 = data.filter( el => el.bonos_puntualidad )

			let detallesInbiOperaciones = []


			// LLENAR LOS BONOS DE MEJOR VENDEDORA
			for( const i in bono1 ){

				const { id_usuario, bonos_ventas, nombre_completo, id_plantel, plantel } = bono1[i]

				detallesInbiOperaciones.push({
					id_elemento_requisicion: 17,
					id_plantel_resultados: id_plantel,
					id_plantel: id_plantel,
					plantel,
					concepto: nombre_completo + ' ( BONO MEJOR VENDEDORA )',
					cantidad: 1,
					costo_unitario: bonos_ventas.replace('$','').replace(',',''),
					costo_total: bonos_ventas.replace('$','').replace(',',''),
					id_alumno: 0,
					id_grupo: 0,
					id_empleado: id_usuario
				})
			}

			// LLENAR LOS BONOS DE RI
			for( const i in bono2 ){

				const { id_usuario, bonos_ri, nombre_completo, id_plantel } = bono2[i]

				detallesInbiOperaciones.push({
					id_elemento_requisicion: 17,
					id_plantel_resultados: id_plantel,
					id_plantel: id_plantel,
					concepto: nombre_completo + ' ( BONO RI )',
					cantidad: 1,
					costo_unitario: bonos_ri.replace('$','').replace(',',''),
					costo_total: bonos_ri.replace('$','').replace(',',''),
					id_alumno: 0,
					id_grupo: 0,
					id_empleado: id_usuario
				})
			}

			// LLENAR LOS BOBOS DE PUNTUALIDAD
			for( const i in bono3 ){

				const { id_usuario, bonos_puntualidad, nombre_completo, id_plantel } = bono3[i]

				detallesInbiOperaciones.push({
					id_elemento_requisicion: 17,
					id_plantel_resultados: id_plantel,
					id_plantel: id_plantel,
					concepto: nombre_completo + ' ( BONO PYA )',
					cantidad: 1,
					costo_unitario: bonos_puntualidad.replace('$','').replace(',',''),
					costo_total: bonos_puntualidad.replace('$','').replace(',',''),
					id_alumno: 0,
					id_grupo: 0,
					id_empleado: id_usuario
				})
			}

			var payload = {
    		registro:                      null,
    		id_usuario_solicita:           this.getdatosUsuario.iderp,
	      id_sucursal_solicita:          this.getdatosUsuario.id_plantel,
	      id_requisicion_compra_estatus: 5,
	      id_usuario_ultimo_cambio:      this.getdatosUsuario.iderp,
	      id_usuario_compra:             28,
	      tipo_requisicion:              1,
	      id_ciclo:                      346,
        id_proyecto:                   null,
	      comentarios:                   'Nómina',
	      detalles:                      detallesInbiOperaciones,
		    fotos:[]
      }

      // this.exportExcel( detallesInbiOperaciones, 'BONOS INBI')

			return detallesInbiOperaciones

		},

		generaRequiBonosFast( nominaActual ){

			let opEscuela = 2
			// Validamos la escuela
			const esFast = opEscuela !== 1;


			// Hacemos el filtro de la información
			const data = this.nominaActual.filter(el => {
		    const esOperaciones = el.departamento === 'OPERACIONES';
		    const coincideFast = el.plantel.match('FAST');
		    return esOperaciones && (esFast ? coincideFast : !coincideFast);
			});

			// comisiones_semana
			// monto_total_bonos

			// Mejor vendedora
			let bono1 = data.filter( el => el.bonos_ventas )

			// Bono RI
			let bono2 = data.filter( el => el.bonos_ri )

			// Bono PYA
			let bono3 = data.filter( el => el.bonos_puntualidad )

			let detallesInbiOperaciones = []


			// LLENAR LOS BONOS DE MEJOR VENDEDORA
			for( const i in bono1 ){

				const { id_usuario, bonos_ventas, nombre_completo, id_plantel, plantel } = bono1[i]

				detallesInbiOperaciones.push({
					id_elemento_requisicion: 17,
					id_plantel_resultados: id_plantel,
					id_plantel: id_plantel,
					plantel,
					concepto: nombre_completo + ' ( BONO MEJOR VENDEDORA )',
					cantidad: 1,
					costo_unitario: bonos_ventas.replace('$','').replace(',',''),
					costo_total: bonos_ventas.replace('$','').replace(',',''),
					id_alumno: 0,
					id_grupo: 0,
					id_empleado: id_usuario
				})
			}

			// LLENAR LOS BONOS DE RI
			for( const i in bono2 ){

				const { id_usuario, bonos_ri, nombre_completo, id_plantel } = bono2[i]

				detallesInbiOperaciones.push({
					id_elemento_requisicion: 17,
					id_plantel_resultados: id_plantel,
					id_plantel: id_plantel,
					concepto: nombre_completo + ' ( BONO RI )',
					cantidad: 1,
					costo_unitario: bonos_ri.replace('$','').replace(',',''),
					costo_total: bonos_ri.replace('$','').replace(',',''),
					id_alumno: 0,
					id_grupo: 0,
					id_empleado: id_usuario
				})
			}

			// LLENAR LOS BOBOS DE PUNTUALIDAD
			for( const i in bono3 ){

				const { id_usuario, bonos_puntualidad, nombre_completo, id_plantel } = bono3[i]

				detallesInbiOperaciones.push({
					id_elemento_requisicion: 17,
					id_plantel_resultados: id_plantel,
					id_plantel: id_plantel,
					concepto: nombre_completo + ' ( BONO PYA )',
					cantidad: 1,
					costo_unitario: bonos_puntualidad.replace('$','').replace(',',''),
					costo_total: bonos_puntualidad.replace('$','').replace(',',''),
					id_alumno: 0,
					id_grupo: 0,
					id_empleado: id_usuario
				})
			}

			var payload = {
    		registro:                      null,
    		id_usuario_solicita:           this.getdatosUsuario.iderp,
	      id_sucursal_solicita:          this.getdatosUsuario.id_plantel,
	      id_requisicion_compra_estatus: 5,
	      id_usuario_ultimo_cambio:      this.getdatosUsuario.iderp,
	      id_usuario_compra:             28,
	      tipo_requisicion:              1,
	      id_ciclo:                      346,
        id_proyecto:                   null,
	      comentarios:                   'Nómina',
	      detalles:                      detallesInbiOperaciones,
		    fotos:[]
      }

      // this.exportExcel( detallesInbiOperaciones, 'BONOS INBI')

			return detallesInbiOperaciones

		}
  },
}