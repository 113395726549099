<template>
  <v-container class="mt-5 ml-2">
    <v-row>
    	<v-col cols="12">
    		<v-card class="shadowCard">
          <v-card-title class="text-subtitle-1">
            Revisar clase
            <v-spacer></v-spacer>
		        <v-btn 
	            class="mr-2" 
	            small dark color="green"
	            @click="exportar()">
	            <v-icon>mdi-microsoft-excel</v-icon>
	          </v-btn>

		        <v-btn 
		        	color="orange"
		        	dark 
		        	small  
		        	@click="getGrupos()"
		        >
		      		Actualizar
		      	</v-btn>

          </v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12" md="4" lg="3" class="pb-0">
                <v-autocomplete
                  dense
                  clearable
                  v-model="cicloInf"
                  :items="ciclos"
                  label="Selecciona ciclo actual"
                  persistent-hint
                  hide-details
                  return-object
                  item-text="ciclo"
                  item-value="id_ciclo"
                  filled
                >
                </v-autocomplete>
              </v-col>
              <v-col cols="12" md="4" lg="3" class="pb-0">
                <v-text-field
                  name="name"
                  label="Fecha"
                  id="id"
                  v-model="dia"
                  filled
                  hide-details
                  clearable
                  type="date"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row justify="end">
              <v-col cols="12" md="6">
              	<v-text-field
				          label="Buscar"
				          v-model="search"
				          dense
				          filled
				        ></v-text-field>
              </v-col>
            </v-row>
			  		<v-data-table
				      :headers="headers"
				      :items="clases"
				      :search="search"
				      class="elevation-0"
				    >
					    <template v-slot:item.revisar="{ item }">
						    <v-btn color="primary" small v-if="item.evaluada">Evaluada</v-btn>
						    <v-btn color="secondary" small @click="abrirDialogClase( item )" v-else>Revisar</v-btn>
					    </template>
				    </v-data-table>
				  </v-card-text>
				</v-card>
	  	</v-col>
	  </v-row>

	  <v-row justify="center" v-if="grupo">
	    <v-dialog
	      v-model="dialogClase"
	      max-width="800"
	      transition="dialog-bottom-transition"
	      persistent
	    >
	      <v-card>
	        <v-toolbar
	          dark
	          dense
	          color="primary"
	          class="elevation-0"
	          style="border-radius: 0px !important;"
	        >
	          <v-spacer></v-spacer>

	          <!-- boton para cerrar -->
	          <v-btn
	            icon
	            dark
	            @click="dialogClase = false"
	          >
	            <v-icon>mdi-close</v-icon>
	          </v-btn>

	        </v-toolbar>

	        <v-card-text class="pt-2">
		        <v-row justify="space-around">
		        	<!-- descripción del grupo -->
		        	<v-col cols="12" md="6" class="pt-12">
		        		<div>Grupo</div>
		        		<div class="text-body-1"><strong>{{ grupo.grupo }}</strong></div>
		        		<div class="mt-3">Teacher</div>
		        		<div class="text-body-1"><strong>{{ grupo.teacher }}</strong></div>
		        	</v-col>

		        	<!-- Imagen -->
		        	<v-col cols="12" md="6" lg="6">
				        <input id="fileUpload" type="file" hidden @change="cargarFoto" accept="image/*" v-if="!file">
		        			<v-card height="100%" class="elevation-0" align="center" outlined @click="chooseFiles()" v-if="!file">
		        		  	<v-img src="@/assets/agregar_foto.jpg" contain aspect-ratio="2" max-width="380"></v-img>
		        			</v-card>
				   		 	</input>

				   		 	<v-card height="100%" class="elevation-0" align="center" outlined v-if="file">
	        		  	<v-img :src="fotoCargada.foto" contain aspect-ratio="2" max-width="380" v-if="fotoCargada">
	        					<v-btn color="red" fab dark small absolute button right @click="file = null"><v-icon>mdi-delete</v-icon></v-btn>
	        		  	</v-img>
	        			</v-card>
		        	</v-col>
		        </v-row>
	        </v-card-text>

	        <v-divider></v-divider>

	        <v-card-text class="pt-2">
		        <v-row v-for="(pregunta, i) in preguntas" :key="i">
		        	<v-col cols="12"  class="pb-0">
		        		<v-checkbox 
	              	v-model="pregunta.check"
	              	:label="pregunta.pregunta"
	              ></v-checkbox>
		        	
		        		<v-textarea
		        			dense
		        			label="Notas:"
		        			outlined
		        			:rows="3"
		        			auto-grow
		        			v-model="pregunta.nota"
		        			hide-details
		        		>
		        		</v-textarea>
		        	</v-col>
		        </v-row>
	        </v-card-text>

	        <v-card-actions>
	        	<v-spacer></v-spacer>
	          <v-btn
              dark
              color="primary"
              class="elevation-6"
              large
              @click="dialogClase = false, dialogConfirmacion = true"
            >
              GUARDAR EVALUACION
            </v-btn>
	        </v-card-actions>
	        
	      </v-card>
	    </v-dialog>
	  </v-row>

    <!-- Dialogo para indicar que todo esta correcto -->
    <v-dialog
      v-model="dialogConfirmacion"
      persistent
      max-width="600"
    >
      <v-card>
      	<v-card-text>
	      	<v-row>
	      		<v-col cols="6">
	      			<v-card class="elevation-0" height="100%" style="display: table;">
		      			<div class="text-h6" style="display: table-cell; vertical-align: middle;">
		      				<strong>¿Estás seguro de que seas guardar la información?</strong>
			      			<v-card-actions>
			      				<!-- Cerrar dialogo de confirmación y abrir el dialogo de editar la clase -->
			      			  <v-btn color="secondary" text large class="mr-2" @click="dialogConfirmacion = false, dialogClase = true">No, Cancelar</v-btn>
			      			  <!-- Guardar la información  -->
			      			  <v-btn 
			      			  	color="blue lighten-2" 
			      			  	dark 
			      			  	class="elevation-6" 
			      			  	large 
			      			  	@click="capturarReporte()"
			      			  	:loading="disabled"
              				:disabled="disabled" 
			      			  >Sí, Guardar</v-btn>
			      			</v-card-actions>
		      			</div>
	      			</v-card>
	      		</v-col>
	      		<v-col cols="6">
	      			<v-img src="@/assets/send.png"></v-img>
	      		</v-col>
		      </v-row>
      	</v-card-text>
      </v-card>
    </v-dialog>

    <!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>

  </v-container>
</template>
<script>
import axios from 'axios';
  import { mapGetters, mapActions } from 'vuex'
  // Componentes
  import Alerta                from '@/components/alertas/Alerta.vue'
  import carga                 from '@/components/alertas/carga.vue';

  import validarErrores  from '@/mixins/validarErrores'

	import XLSX from 'xlsx'

  export default {
   components:{
      Alerta,
      carga,
    },

    mixins: [ validarErrores ],

    data: () => ({
      // Alertas
      parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      loader: true,
      cargar: false,


    	dialogConfirmacion: false,
    	dialogClase: false,

    	cicloInf: 		null,
    	ciclos:  			[],
    	dialogError: 	false,
			dialogSuccess:false,
			error: 				'',
			successMensaje:'',
			cargar: 			false,

      search: '',
      headers: [
        { text: 'Id grupo'          , value: 'id_grupo'    },
        { text: 'Grupo'             , value: 'grupo'       },
        { text: 'Hora inicio'       , value: 'hora_inicio' },
        { text: 'Teacher'           , value: 'teacher'     },
        { text: 'Revisar'           , value: 'revisar' },
      ],

      clases:[],
      grupo: null,

      fotoCargada:null,
      file: null,
      errorBack:false,
      textoCopiado:'COPIAR',

      preguntas:[],
      disabled:false,
      dia: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
    }),

    watch:{
	    cicloInf () {
        if(this.cicloInf){
          this.getGrupos()
        }
      },
	  },

    async created () {
    	await this.getCiclos()
    },

    methods: {
    	getCiclos () {
    		this.fotoCargada = null

    		this.textoCopiado = 'COPIAR'
        this.cargar = true
        this.ciclos = []
        this.$http.get('kpi.ciclos.all').then(response=>{
          for(const i in response.data){
            if (!(response.data[i].ciclo.search('FE') != -1)) {
              if(response.data[i].ciclo.search('CICLO') != -1){
                this.ciclos.push(response.data[i])
              }
            }
          }
          this.cargar = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      getGrupos () {
    		// validamos los campos
    		if(this.cicloInf){
	    	 	this.cargar = true
	    		this.clases  = []
	    		
	    		// Preparamos el cuerpo de datos
	    		const payload = {
	    			cicloFast:   this.cicloInf.id_ciclo_relacionado,
	    			cicloInbi:   this.cicloInf.id_ciclo,
	    			id_plantel:  this.getdatosUsuario.id_plantel,
	    			planteles:   this.getdatosUsuario.planteles,
	    			fecha:       this.dia,
	    			idpuesto:    this.getdatosUsuario.idpuesto,
	    			iderp:       this.getdatosUsuario.iderp
	    		}

	    		// Hacemos la petición
	    		this.$http.post('calidad.clases.plantel', payload).then(response=>{
	        	// Guardamos la respuesta
	        	this.clases  = response.data
	        	this.cargar = false
	        }).catch( error =>{
	          this.validarError( error.response.data.message )
	        }).finally( () => { this.cargar = false })
    		}else{
    			// Error,no se selecciono ningun ciclo
    			this.validarErrorDirecto('Por favor, elige el ciclo')
    		}
    	},

      getPreguntas () {
        this.cargar = true
        this.preguntas = []
        this.$http.get('calidad.preguntas.all').then(response=>{
          // Guardamos las preguntas
          this.preguntas = response.data

          // Recorremos y concatenamos la nota y el check para saber si esta selccionada o no
          for(const i in this.preguntas){
          	// Concatenamos
          	this.preguntas[i] = { ...this.preguntas[i], nota:'', check:0}
          }
          this.cargar = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

    	chooseFiles() {
        document.getElementById("fileUpload").click()
    	},

      async abrirDialogClase ( grupo ) {
      	await this.getPreguntas()
      	this.file  = null
      	this.grupo = grupo
      	this.dialogClase = true
      },

      // Cargar tofo
      cargarFoto(e){
      	// Verificamos que haya un archivo
      	if(e.target.files.length > 0){
      		// guardamos el archivo seleccionado
					this.file = e.target.files[0]
					// creamos una variable tipo FormData
	        let formData = new FormData();
	        //se crea el objeto y se le agrega como un apendice el archivo 
	        formData.append('file', this.file);
	        //mandamos a ocvertir la imagen a base64 pero mandamos el docuemnto, el formdata, el nombre
	        this.getBase64(this.file, formData, this.file.name)
      	}
			},

      getBase64(archivo,formData,name) {
        var me = this
        // Creamos la variable de lectura
        var reader = new FileReader();
        // leemos el archivos
        reader.readAsDataURL(archivo);
        reader.onload =  function(){
          // Agregamos la foto cargada al arreglo que vamos a mostrar arriba en la pantalla
          me.fotoCargada = {
            foto: reader.result,
            formdata: formData,
          }
        }
      },

      getFileExtension1(filename) {
  			return (/[.]/.exec(filename)) ? /[^.]+$/.exec(filename)[0] : undefined;
			},

			// función para guardar la información de la minuta
			async capturarReporte () {
				this.disabled = true
				if(this.fotoCargada){
					// Crear el payload a guardar
					let payload  = {
						id_usuario_erp : this.getdatosUsuario.iderp,
						id_teacher     : this.grupo.id_teacher,
						id_grupo       : this.grupo.id_grupo,
						estatus        : 0,
						unidad_negocio : this.grupo.id_unidad_negocio,
						id_ciclo       : this.grupo.id_ciclo,
						dia            : this.grupo.dia,
						deleted        : 0,
						url            : '',
						preguntas      : this.preguntas
					}

					try{
						const datosGrabados = await this.save(payload).then(response=> response)
						const grabarImage   = await this.guardarImagen(datosGrabados.body.id).then(response=> response)

						this.dialogSuccess = true
    				this.fotoCargada   = null
    				this.disabled      = false
						this.getGrupos()
						// const imagenGrabada = await this.save(datosGrabados).then(response=> response)
					}catch (e) {
						this.disabled = false
						this.validarErrorDirecto(e)
					}
					this.disabled = false
					this.dialogConfirmacion = false
				}else{
					this.disabled = false
					this.validarErrorDirecto('Por favor, agrega la imagen de la clase')
				}
			},

			save ( payload ) {
				return new Promise((resolve,reject)=>{
			    // Hacemos la petición
	    		this.$http.post('calidad.clases.evaluacion', payload).then(response=>{
	    			console.log(response.data)
	    			if(response.data.estatusError == 0){
	    				this.dialogSuccess = false
    					this.fotoCargada   = null
    					this.disabled      = false
		        	// INdicamos que hay un error en el back
		        	this.error = 'Este grupo ya cuenta con una evalaución'
		        	this.errorBack = false
		        	this.cargar = false
							this.dialogConfirmacion = false
		        	this.dialogError = true
		        	this.getGrupos()
	    			}else{
	        		resolve( response )
	    			}
	        }).catch( error =>{
	          this.validarError( error.response.data.message )
	        }).finally( () => { this.cargar = false })
			  })
			},

			guardarImagen ( id ) {
				return new Promise((resolve,reject)=>{
					// obtener la extensión del archivo
					const ext = this.getFileExtension1(this.file.name)
			    // Hacemos la petición
	    		this.$http.post(`calidad.clases.imagen/${ id }/${ ext}`, this.fotoCargada.formdata ).then(response=>{
	        	resolve( response )
	        }).catch( error =>{
	          this.validarError( error.response.data.message )
	        }).finally( () => { this.cargar = false })
			  })
			},

			copiarPortapapeles () {
        navigator.clipboard.writeText(this.error).then(() => {
          this.textoCopiado = 'COPIADO'
        }).catch(err => {
          console.log('Something went wrong', err);
        })
      }
    },

    computed:{
	    ...mapGetters('login',['getdatosUsuario']),
	  },
  }
</script>


