<template>
  <v-container mt-6 fluid>
    <!-- Header -->
    <v-row>
      <v-col cols="12" class="pa-0">
        <v-app-bar dense elevation="1" color="error" dark>
          <v-toolbar-title>Canal Inbi</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-btn
            color="white"
            text
            @click="openAddDialog"
          >
            <v-icon left>mdi-plus</v-icon>
            Agregar Video
          </v-btn>
        </v-app-bar>
      </v-col>
    </v-row>

    <v-row>
      <!-- Área del video -->
      <v-col cols="12" md="8" class="pt-0 pl-0 pr-0">
        <v-card flat>
          <iframe
            v-if="currentVideo"
            :src="currentVideo.url + 'rel=0&controls=0&showinfo=0' "
            frameborder="0"
            class="pa-2"
            allowfullscreen
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            style="width: 100%; height: 500px;"
          ></iframe>

          <v-img
            v-else
            src="@/assets/learn.png"
            contain
            aspect-ratio="2"
            class="pa-2"
          />
        </v-card>
      </v-col>

      <!-- Lista de videos mejorada -->
      <v-col cols="12" md="4" class="pt-0 pl-0 pr-0">
        <v-card flat :height="tamanioPantalla" class="overflow-y-auto">
          <v-card-title class="justify-center error white--text py-3">
            Lista de Videos
          </v-card-title>

          <v-list>
            <v-list-item-group>
              <v-list-item
                v-for="video in videos"
                :key="video.id"
                @click="reproducirVideo(video)"
                class="mb-2"
              >
                <v-list-item-content>
                  <v-list-item-title class="font-weight-medium">
                    {{ video.titulo }}
                  </v-list-item-title>
                  <v-list-item-subtitle class="mt-1">
                    {{ video.descripcion }}
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-avatar size="80" class="mr-4" style="border-radius: 7px;">
                  <img
                    :src="`https://img.youtube.com/vi/${obtenerVideoID(video.url)}/0.jpg`"
                    style="width: 80px; height: 60px; object-fit: cover;"
                  >
                </v-list-item-avatar>
                <v-list-item-action>
                  <v-row no-gutters align="center" justify="center">
                    <v-col cols="auto" class="mr-2">
                      <v-btn
                        icon
                        color="error"
                        @click.stop="reproducirVideo(video)"
                      >
                        <v-icon>mdi-play-circle</v-icon>
                      </v-btn>
                    </v-col>
                    <v-col cols="auto">
                      <v-btn
                        icon
                        color="error"
                        @click.stop="confirmarBorrado(video)"
                      >
                        <v-icon>mdi-delete</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-list-item-action>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-card>
      </v-col>
    </v-row>

    <!-- Dialog para agregar video -->
    <v-dialog v-model="dialogAdd" max-width="500px">
      <v-card>
        <v-card-title class="error white--text">
          Agregar Nuevo Video
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="nuevoVideo.titulo"
                  label="Título"
                  required
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-textarea
                  v-model="nuevoVideo.descripcion"
                  label="Descripción"
                  required
                ></v-textarea>
              </v-col>
              <v-col cols="12">
                <v-text-field
                  v-model="nuevoVideo.url"
                  label="URL del video (Embed)"
                  required
                  hint="Ejemplo: https://www.youtube.com/embed/VIDEO_ID"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" text @click="dialogAdd = false">
            Cancelar
          </v-btn>
          <v-btn color="success" text @click="guardarVideo">
            Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Dialog de confirmación para borrar -->
    <v-dialog v-model="dialogDelete" max-width="400px">
      <v-card>
        <v-card-title class="error white--text">
          Confirmar Eliminación
        </v-card-title>
        <v-card-text class="pt-4">
          ¿Está seguro que desea eliminar este video?
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" text @click="dialogDelete = false">
            Cancelar
          </v-btn>
          <v-btn color="success" text @click="borrarVideo">
            Confirmar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <Alerta v-if="respuestaAlerta" :parametros="parametros"/>
  </v-container>
</template>

<script>

  import { mapGetters } from 'vuex'
  // Componentes
  import Alerta                from '@/components/alertas/Alerta.vue'
  import carga                 from '@/components/alertas/carga.vue'


  import validarErrores  from '@/mixins/validarErrores'

  export default {
    name: "youtube-course-viewer",

    components:{
      Alerta,
      carga,

    },

    mixins: [ validarErrores ],

    data: () => ({
      respuestaAlerta:false,
      cargar: false,
      videos: [],
      currentVideo: null,
      scrollInvoked: 0,
      parametros: {
      dialogError: false,
      mensaje: '',
      color: ''
      },
      respuestaAlerta: false,
      cargar: false,
      dialogAdd: false,
      dialogDelete: false,
      videoToDelete: null,
      nuevoVideo: {
        titulo: '',
        descripcion: '',
        url: ''
      }
    }),

    computed: {
      ...mapGetters('login',['getdatosUsuario']),

      tamanioPantalla() {
      switch (this.$vuetify.breakpoint.name) {
          case 'xs': return (this.$vuetify.breakpoint.height / 2) + 80
          case 'sm': return (this.$vuetify.breakpoint.height / 2) - 150
          case 'md': return this.$vuetify.breakpoint.height - 120
          case 'lg': return this.$vuetify.breakpoint.height - 120
          case 'xl': return this.$vuetify.breakpoint.height - 120
          default: return this.$vuetify.breakpoint.height - 120
        }
      }
    },

    watch: {

    },

    created () {
      this.cargarVideos()
    },

    methods: {

      obtenerVideoID(url) {
        const partes = url.split('/');
        const videoIDConParams = partes[partes.length - 1];
        return videoIDConParams.split('?')[0];
      },

      async cargarVideos() {
        try {
          this.cargar = true;
          const response = await this.$http.get('videos');
          if (response.data && Array.isArray(response.data)) {
            this.videos = response.data;
            if (this.videos.length > 0 && !this.currentVideo) {
              this.currentVideo = this.videos[0];
            }
          } else {
            console.error('Formato de respuesta inesperado:', response.data);
          }
        } catch (error) {
          console.error('Error al cargar videos:', error);
          this.validarError(error);
        } finally {
          this.cargar = false;
        }
      },


      async reproducirVideo(video) {
        try {
          this.currentVideo = video;

          const payload = {
            id_video: video.id,
            iderp_usuario: this.getdatosUsuario.iderp
          };

          await this.$http.post('video-eventos', payload);
        } catch (error) {
          console.error('Error completo:', error);
          // Opcionalmente mostrar un mensaje al usuario
          this.parametros = {
            dialogError: true,
            mensaje: 'Error al registrar la visualización del video',
            color: 'error'
          };
          this.respuestaAlerta = true;
        }
      },

        openAddDialog() {
        this.nuevoVideo = {
          titulo: '',
          descripcion: '',
          url: ''
        };
        this.dialogAdd = true;
      },

      async guardarVideo() {
        try {
          if (!this.nuevoVideo.titulo || !this.nuevoVideo.descripcion || !this.nuevoVideo.url) {
            this.parametros = {
              dialogError: true,
              mensaje: 'Por favor complete todos los campos',
              color: 'warning'
            };
            this.respuestaAlerta = true;
            return;
          }

          await this.$http.post('videos', this.nuevoVideo);

          this.parametros = {
            dialogError: true,
            mensaje: 'Video agregado exitosamente',
            color: 'success'
          };
          this.respuestaAlerta = true;

          this.dialogAdd = false;
          await this.cargarVideos();
        } catch (error) {
          console.error('Error al guardar video:', error);
          this.validarError(error);
        }
      },

      confirmarBorrado(video) {
        this.videoToDelete = video;
        this.dialogDelete = true;
      },

      async borrarVideo() {
        try {
          if (!this.videoToDelete) return;

          await this.$http.delete(`videos/${this.videoToDelete.id}`);

          this.parametros = {
            dialogError: true,
            mensaje: 'Video eliminado exitosamente',
            color: 'success'
          };
          this.respuestaAlerta = true;

          this.dialogDelete = false;
          this.videoToDelete = null;
          await this.cargarVideos();
        } catch (error) {
          console.error('Error al borrar video:', error);
          this.validarError(error);
        }
      },

      onScroll() {
        this.scrollInvoked++
      }

    },
  }
</script>

<style scoped>
.colorCurso {
  color: #006093;
}

.video-title {
  font-weight: 500;
  margin-bottom: 4px;
}

.video-description {
  color: rgba(0, 0, 0, 0.6);
  margin-bottom: 8px;
}

.v-card::-webkit-scrollbar {
  -webkit-appearance: none !important;
  width: 8px !important;
}

.v-card::-webkit-scrollbar-thumb {
  background-color: #BABCBF;
  border-radius: 20px;
  border: 1px solid #ededed;
}

.v-list-item {
  border-radius: 4px;
  margin: 0 8px;
}

.v-list-item:hover {
  background-color: #f5f5f5;
}

.v-list-item-title {
  line-height: 1.4;
  margin-bottom: 4px;
}

.v-list-item-subtitle {
  white-space: normal;
  display: -webkit-box;
  line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
</style>
