<template>
  <v-container class="mt-5 ml-2">
    <v-row>
      <v-col cols="12">
        <v-card class="shadowCard">
          <v-toolbar
            flat
          >
            <v-toolbar-title>NI Sucursal</v-toolbar-title>
            <v-divider
              class="mx-4"
              inset
              vertical
            ></v-divider>
            <v-spacer></v-spacer>
            <v-btn 
              class="mr-2" 
              small dark color="green"
              @click="exportar()" tile>
              <v-icon>mdi-microsoft-excel</v-icon>
            </v-btn>
            <v-btn color="primary" @click="consultar()" small tile>Consultar</v-btn>
          </v-toolbar>

          <v-card-text>
            <v-row>
              <v-col cols="12" sm="4" lg="4" xl="3">
                <v-autocomplete
                  filled
                  dense
                  clearable
                  v-model="cicloInf"
                  :items="ciclos"
                  label="Selecciona ciclo actual"
                  persistent-hint
                  hide-details
                  return-object
                  item-text="ciclo"
                  item-value="id_ciclo"
                >
                </v-autocomplete>
              </v-col>

              <v-col cols="12" sm="4" lg="3" xl="2">
                <v-text-field
                  label="Fecha de incio"
                  filled
                  dense
                  clearable
                  v-model="fechaini"
                  type="date"
                ></v-text-field>
              </v-col>

              <v-col cols="12" sm="4" lg="3" xl="2">
                <v-text-field
                  label="Fecha final"
                  filled
                  dense
                  clearable
                  v-model="fechafin"
                  type="date"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
          

          <v-card-text class="pt-0">
            <v-row align="center">
            	
            	<v-col cols="12" sm="6" md="5" lg="3">
            		<v-card color="red darken-4 shadowCard" class="pa-3 white--text text-h4">
            		  <div class="py-1 text--center text-h6 white--text" align="center">
                      Inscritos Fast <br/>
                      {{totalFast}}
            		  </div>
            		</v-card>
            	</v-col>
              
              <v-col cols="12" sm="6" md="5" lg="3">
                <v-card color="blue lighten-1 shadowCard" class="pa-3 white--text text-h4-4">
                  <div class="py-1 text--center text-h6 white--text" align="center">
                      Inscritos Inbi <br/>
                      {{totalInbi}}
                  </div>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>

          <v-card-text>
            <v-row>
              <v-col cols="12" md="6">
              	<v-card class="shadowCard">
              		<v-card-title primary-title class="text-subtitle-1">
              		  Inscritos Fast
              		</v-card-title>
              	  <v-card-text>
        		      	<v-data-table
        		          :headers="headers"
        		          :items="dataTablaFastUno"
        		          class="elevation-0"
        		          hide-default-footer
        		          :items-per-page="20"
        		          dense
        		          mobile-breakpoint="200"
        		        >
        		          <template v-slot:no-data>
        		            <v-btn
        		              color="primary"
        		              @click="initialize"
        		              small
        		            >
        		              Actualizar
        		            </v-btn>
        		          </template>
        		        </v-data-table>
              	  </v-card-text>
              	</v-card>
              </v-col>

              <v-col cols="12" md="6">
              	<v-card class="shadowCard">
              		<v-card-title primary-title class="text-subtitle-1">
              		  Inscritos Inbi
              		</v-card-title>
              	  <v-card-text>
        		      	<v-data-table
        		          :headers="headers"
        		          :items="dataTablaInbiUno"
        		          class="elevation-0"
        		          hide-default-footer
        		          :items-per-page="20"
        		          dense
        		          mobile-breakpoint="200"
        		        >
        		          <template v-slot:no-data>
        		            <v-btn
        		              color="primary"
        		              @click="initialize"
        		              small
        		            >
        		              Actualizar
        		            </v-btn>
        		          </template>
        		        </v-data-table>
              	  </v-card-text>
              	</v-card>
              </v-col>
              
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import axios from 'axios';
  import XLSX from 'xlsx'

  export default {
    data: () => ({
      snackbar:false,
      headers: [
        { text: 'Sucursal'     , value: 'plantel' },
        { text: 'Total'        , value: 'total' },
      ],

      desserts: [],
      ciclos:[],
      cicloInf:null,
      cicloSup:null,
      planteles:[],
      plantel:null,
      cicloAnterior:[],
      cicloSiguiente:[],

      dataTablaFastUno:[],
      dataTablaInbiUno:[],
      dataTablaFastDos:[],

      
      fast:{},
      inbi:{},

      fechaini:null,
      fechafin:null,

      text:'',
      dialogCarga:false,
      totalFast:0,
      totalInbi:0,

    }),

    watch: {
      cicloInf () {
        if(this.cicloInf){
          var num_search = parseInt(this.cicloInf.ciclo.substr(5,3)) + 1
          for(const i in this.ciclos){
            var cadena_search = this.ciclos[i].ciclo.substr(0,8)
            if (cadena_search.search(num_search) != -1) {
              this.cicloSup = this.ciclos[i]
            }
          }
        }
      }
    },

    created () {
      this.initialize()
    },

    methods: {
      initialize () {
        this.ciclos = []
        this.planteles = []
        this.$http.get('kpi.planteles.all').then(response=>{
          for(const i in response.data){
            var payload = {
              plantel: response.data[i].plantel,
              alumnosActual: 0,
              alumnosSiguiente: 0,
              faltan: 0,
              porcentaje: 0,
            }
            this.planteles.push(payload)
          }
          this.$http.get('kpi.ciclos.all').then(response=>{
            for(const i in response.data){
              if (!(response.data[i].ciclo.search('FE') != -1)) {
                if(response.data[i].ciclo.search('CICLO') != -1){
                  this.ciclos.push(response.data[i])
                }
              }
            }
          }).catch(error=>{console.log(error)})
        }).catch(error=>{console.log(error)})
      },

      consultar () {
        if(this.cicloInf != null && this.fechafin == null && this.fechaini == null){
          this.getCiclo()
        }else if(this.cicloInf != null && this.fechafin != null && this.fechaini != null){
          this.getCicloRango()
        }else if(this.cicloInf != null && this.fechaini != null && this.fechafin == null){
          this.getCicloDia()
        }else if(this.cicloInf == null && this.fechaini != null && this.fechafin != null){
          this.getRango()
        }else if(this.cicloInf == null && this.fechaini != null && this.fechafin == null){
          this.getDia()
        }else{
          this.text = 'Selecciona los ciclos porfavor'
          this.snackbar = true
        }
      },

      getDia () {
        this.dataTablaFastUno = []
        this.dataTablaInbiUno = []
        this.dialogCarga = true

        var payload = {
          fechaini: this.fechaini,
          fechafin: this.fechafin
        }

        this.$http.post('kpi.inscripciones.vendedora.dia.fast',payload).then(response=>{
          // Sacamos los datos repetidos
          let planteles = response.data.map(item=>{
            return [item.plantel,item]
          });
          // Creamos un map de los alumnos
          var plantelesUnicos = new Map(planteles); // Pares de clave y valor
          // y ahora si, extraemos los valores unicos
          let unicos = [...plantelesUnicos.values()]; // Conversión a un array    
          for(const i in unicos){
            var plantelCount = {plantel: unicos[i].plantel, total: 0}
            for(const j in response.data){
              if(unicos[i].plantel == response.data[j].plantel){
                plantelCount.total += 1
              }
            }
            this.dataTablaFastUno.push(plantelCount)
          }
          
          var totales = {
            plantel: 'Total',
            total:0,
          }
          // Recorrer para hacer la sumatoria
          for(const i in this.dataTablaFastUno){
            totales.total += this.dataTablaFastUno[i].total
          }
          // Guardamos el total de fast
          this.totalFast =  totales.total
          // Le insertamos los totales a la tabla
          this.dataTablaFastUno.push(totales)

          this.$http.post('kpi.inscripciones.vendedora.dia.inbi',payload).then(response=>{
            // Sacamos los datos repetidos
            let plantelesInbi = response.data.map(item=>{
              return [item.plantel,item]
            });
            // Creamos un map de los alumnos
            var plantelesUnicosInbi = new Map(plantelesInbi); // Pares de clave y valor
            // y ahora si, extraemos los valores unicos
            let unicosInbi = [...plantelesUnicosInbi.values()]; // Conversión a un array    
            for(const i in unicosInbi){
              var plantelCountInbi = {plantel: unicosInbi[i].plantel, total: 0}
              for(const j in response.data){
                if(unicosInbi[i].plantel == response.data[j].plantel){
                  plantelCountInbi.total += 1
                }
              }
              this.dataTablaInbiUno.push(plantelCountInbi)
            }
            
            var totalesInbi = {
              plantel: 'Total',
              total:0,
            }
            // Recorrer para hacer la sumatoria
            for(const i in this.dataTablaInbiUno){
              totalesInbi.total += this.dataTablaInbiUno[i].total
            }
            // Guardamos el total de fast
            this.totalInbi =  totalesInbi.total
            // Le insertamos los totales a la tabla
            this.dataTablaInbiUno.push(totalesInbi)
            this.dialogCarga = false
          }).catch(error=>{console.log(error)})
        }).catch(error=>{console.log(error)})  
      },
      getRango () {
        this.dataTablaFastUno = []
        this.dataTablaInbiUno = []
        this.dialogCarga = true

        var payload = {
          fechaini: this.fechaini,
          fechafin: this.fechafin
        }

        this.$http.post('kpi.inscripciones.vendedora.rango.fast',payload).then(response=>{
          // Sacamos los datos repetidos
          let planteles = response.data.map(item=>{
            return [item.plantel,item]
          });
          // Creamos un map de los alumnos
          var plantelesUnicos = new Map(planteles); // Pares de clave y valor
          // y ahora si, extraemos los valores unicos
          let unicos = [...plantelesUnicos.values()]; // Conversión a un array    
          for(const i in unicos){
            var plantelCount = {plantel: unicos[i].plantel, total: 0}
            for(const j in response.data){
              if(unicos[i].plantel == response.data[j].plantel){
                plantelCount.total += 1
              }
            }
            this.dataTablaFastUno.push(plantelCount)
          }
          
          var totales = {
            plantel: 'Total',
            total:0,
          }
          // Recorrer para hacer la sumatoria
          for(const i in this.dataTablaFastUno){
            totales.total += this.dataTablaFastUno[i].total
          }
          // Guardamos el total de fast
          this.totalFast =  totales.total
          // Le insertamos los totales a la tabla
          this.dataTablaFastUno.push(totales)


          this.$http.post('kpi.inscripciones.vendedora.rango.inbi',payload).then(response=>{
            // Guardar los datos 

            // Sacamos los datos repetidos
            let plantelesInbi = response.data.map(item=>{
              return [item.plantel,item]
            });
            // Creamos un map de los alumnos
            var plantelesUnicosInbi = new Map(plantelesInbi); // Pares de clave y valor
            // y ahora si, extraemos los valores unicos
            let unicosInbi = [...plantelesUnicosInbi.values()]; // Conversión a un array    
            for(const i in unicosInbi){
              var plantelCountInbi = {plantel: unicosInbi[i].plantel, total: 0}
              for(const j in response.data){
                if(unicosInbi[i].plantel == response.data[j].plantel){
                  plantelCountInbi.total += 1
                }
              }
              this.dataTablaInbiUno.push(plantelCountInbi)
            }
            
            var totalesInbi = {
              plantel: 'Total',
              total:0,
            }
            // Recorrer para hacer la sumatoria
            for(const i in this.dataTablaInbiUno){
              totalesInbi.total += this.dataTablaInbiUno[i].total
            }
            // Guardamos el total de fast
            this.totalInbi =  totalesInbi.total
            // Le insertamos los totales a la tabla
            this.dataTablaInbiUno.push(totalesInbi)
            this.dialogCarga = false
          }).catch(error=>{console.log(error)})
        }).catch(error=>{console.log(error)})  
      },

      getCiclo () {
        this.dialogCarga = true
        this.fast = {}
        this.inbi = {}
        this.$http.get('kpi.inscripciones.total.fast/' + this.cicloInf.id_ciclo_relacionado).then(response=>{
          this.dataTablaFastUno = response.data
          var payload = {
            plantel: 'Total',
            total:0,
          }
          for(const i in this.dataTablaFastUno){
            payload.total += this.dataTablaFastUno[i].total
          }
          this.totalFast =  payload.total
          this.dataTablaFastUno.push(payload)
          this.$http.get('kpi.inscripciones.total.inbi/' + this.cicloInf.id_ciclo).then(response=>{
            this.dataTablaInbiUno = response.data
            var payload = {
              plantel: 'Total',
              total:0,
            }
            for(const i in this.dataTablaInbiUno){
              payload.total += this.dataTablaInbiUno[i].total
            }
            this.totalInbi =  payload.total
            this.dataTablaInbiUno.push(payload)
            this.dialogCarga = false
          }).catch(error=>{console.log(error)})
        }).catch(error=>{console.log(error)})
      },

      getCicloRango () {
        this.dialogCarga = true
        this.fast = {}
        this.inbi = {}
        var payload = {
          ciclo: this.cicloInf.id_ciclo_relacionado,
          fechaini: this.fechaini,
          fechafin: this.fechafin
        }

        this.$http.post('kpi.inscripciones.ciclo.rango.fast',payload).then(response=>{
          // Guardar los datos 
          this.dataTablaFastUno = response.data
          //  payload para cargar el total de la tabla al final
          var totales = {
            plantel: 'Total',
            total:0,
          }
          // Recorrer para hacer la sumatoria
          for(const i in this.dataTablaFastUno){
            totales.total += this.dataTablaFastUno[i].total
          }
          // Guardamos el total de fast
          this.totalFast =  totales.total
          // Le insertamos los totales a la tabla
          this.dataTablaFastUno.push(totales)
          /*******************************************/
          // cambiamos el ciclo al payload
          payload.ciclo = this.cicloInf.id_ciclo
          this.$http.post('kpi.inscripciones.ciclo.rango.inbi',payload).then(response=>{
            // Guardar los datos 
            this.dataTablaInbiUno = response.data
            //  payload para cargar el total de la tabla al final
            var totales = {
              plantel: 'Total',
              total:0,
            }
            // Recorrer para hacer la sumatoria
            for(const i in this.dataTablaInbiUno){
              totales.total += this.dataTablaInbiUno[i].total
            }
            // Guardamos el total de inbi
            this.totalInbi =  totales.total
            // Le insertamos los totales a la tabla
            this.dataTablaInbiUno.push(totales)
            // cerramos el dialog de carga
            this.dialogCarga = false
          }).catch(error=>{console.log(error)})
        }).catch(error=>{console.log(error)})
      },

      getCicloDia () {
        this.dialogCarga = true
        // Vaciamos los datos
        this.fast = {}
        this.inbi = {}

        var payload = {
          ciclo: this.cicloInf.id_ciclo_relacionado,
          fechaini: this.fechaini
        }
        // Consultamos los datos mandando el payload
        this.$http.post('kpi.inscripciones.ciclo.dia.fast',payload).then(response=>{
          // Cargamos los datos
          this.dataTablaFastUno = response.data
          // Generamos lo de la tabla del total que va al final
          var totales = {
            plantel: 'Total',
            total:0,
          }
          // Recorremos todo para sacar el total
          for(const i in this.dataTablaFastUno){
            totales.total += this.dataTablaFastUno[i].total
          }
          this.totalFast =  totales.total
          this.dataTablaFastUno.push(totales)

          // Cambiamos el ciclo 
          payload.ciclo = this.cicloInf.id_ciclo

          // Consultamos los datos mandando el payload
          this.$http.post('kpi.inscripciones.ciclo.dia.inbi',payload).then(response=>{
            this.dataTablaInbiUno = response.data
            var totales = {
              plantel: 'Total',
              total:0,
            }
            for(const i in this.dataTablaInbiUno){
              totales.total += this.dataTablaInbiUno[i].total
            }
            this.totalInbi =  totales.total
            this.dataTablaInbiUno.push(totales)
            this.dialogCarga = false
          }).catch(error=>{console.log(error)})
        }).catch(error=>{console.log(error)})
      },

      exportar(){
        this.exportExcel()
      },

      s2ab(s) {
        var buf = new ArrayBuffer(s.length);
        var view = new Uint8Array(buf);
        for (var i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
        return buf;
      },

      exportExcel(){
      	var arrayEspacios = this.dataTablaFastUno
      	var arregloInbiTablaDos = {
        	id_plantel    :'',
        	plantel    		:'',
        	alumnodia    	:'',
					alumnosemana	:'',
					totalAcumulado:'',
        }
        arrayEspacios.push(arregloInbiTablaDos)
        arrayEspacios.push(arregloInbiTablaDos)
        arrayEspacios.push(arregloInbiTablaDos)

        // titulos
        var arregloInbiTablaDos = {
        	id_plantel    :'id_plantel',
        	plantel    		:'plantel',
        	alumnodia    	:'alumnodia',
					alumnosemana	:'alumnosemana',
					totalAcumulado:'totalAcumulado',
        }
        arrayEspacios.push(arregloInbiTablaDos)

      	var arrayConcat = arrayEspacios.concat(this.dataTablaInbiUno);

        let data = XLSX.utils.json_to_sheet(arrayConcat)
        const workbook = XLSX.utils.book_new()
        const filename = 'KPI'
        XLSX.utils.book_append_sheet(workbook, data, filename)

        var wbout = XLSX.write(workbook, {
          bookType: 'xls',
          bookSST: false,
          type: 'binary'
        });

        const file = new File([new Blob([this.s2ab(wbout)])], filename + '.xls')
        
        let formData = new FormData();
        // //se crea el objeto y se le agrega como un apendice el archivo 
        formData.append('file',file);
        // /*getDatosRiesgo the form data*/

        this.$http.post('pdfs',formData).then(response=> {
      		this.dataTablaFastUno.splice(this.dataTablaFastUno.length-4,4)
          window.location = axios.defaults.baseURL + 'pdfs/' + filename + '.xls'
        }).catch(error=> {
          alert(error.body)
          console.log(error);
        });

      },

      
    },
  }
</script>