<template>
  <v-dialog
    v-model="dialogTwilio.estatus"
    max-width="1000"
    persistent
  >
    <v-card class="shadowCard">
		  <v-card-title primary-title>
		    <span class="text-subtitle-1">Distribución de contactos</span>
		    <v-spacer></v-spacer>

        <v-btn 
          class="mr-2" 
          small dark color="green"
          @click="exportExce( )"
          tile
        >
          <v-icon small>mdi-microsoft-excel</v-icon>
        </v-btn>

        <v-btn
          color="primary"
          dark
          class="text-capitalize"
          @click="initialize()"
          small
          tile
        >
          <v-icon small>mdi-refresh</v-icon>
        </v-btn>
        
		  </v-card-title>

		  <v-card-text>
		  	<v-row>

          <v-col cols="12" sm="6" md="6">
            <v-radio-group
              v-model="escuela"
              row
            >
              <v-radio
                label="Inbi"
                :value="1"
              ></v-radio>
              <v-radio
                label="Fast"
                :value="2"
              ></v-radio>
              <v-radio
                label="Todas"
                :value="3"
              ></v-radio>
            </v-radio-group>
          </v-col>

		  		<v-col cols="12" sm="6" md="6">
		  			<v-text-field
		  			  name="name"
		  			  label="Buscar"
		  			  id="id"
		  			  filled
		  			  dense
		  			  single-line
		  			  hide-details
		  			  append-icon="mdi-magnify"
		  			  v-model="search"
		  			>
		  			</v-text-field>
		  		</v-col>
		  	</v-row>
		    <v-row>
          <v-col cols="12">
            <div>Planteles Sin Vendedora</div>
            <div v-for="(plantel, i ) in plantelesSinVendedora">
              {{ plantel.plantel }}
            </div>
            <div v-if="!plantelesSinVendedora.length">Todos los planteles tienen vendedora</div>
          </v-col>

          <!-- TABLA DE PLANTELES -->
		    	<v-col cols="12">
					  <v-data-table
					    :headers="headers"
					    :items="filterPlanteles"
					    class="elevation-0"
					    :search="search"
					    :mobile-breakpoint="100"
	            dense
					  >
              
              <template v-slot:item.comentarios="props">
                <v-edit-dialog
                  :return-value.sync="props.item.comentarios"
                  @save="saveMatch( props.item )"
                >
                  {{ props.item.comentarios }}
                  <template v-slot:input>
                    <v-text-field
                      v-model="props.item.comentarios"
                      label="Agregar los match"
                      single-line
                      counter
                    ></v-text-field>
                  </template>
                </v-edit-dialog>
              </template>

              <template v-slot:item.actions="{ item }">
                <v-btn 
                  color="primary" 
                  x-small
                  @click="verVendedoras(item)"
                  class="mr-2"
                >
                  <v-icon small>mdi-magnify</v-icon>
                </v-btn>
              </template>

					    <template v-slot:no-data>
					      <v-btn
					        color="primary"
					        @click="initialize"
					        small
					      >
					        Actualizar
					      </v-btn>
					    </template>

					  </v-data-table>
		    	</v-col>
		    </v-row>
		  </v-card-text>
		  <v-card-actions>
		  	<v-btn 
		  		color="black"
		  		dark
		  		small
		  		tile
		  		@click="dialogTwilio.estatus = false"
		  	>
		  		<v-icon small left>mdi-close</v-icon>
		  		Cerrar
		  	</v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
		</v-card>

    <!-- Dialogo de editar o agregar-->
	  <v-dialog
      v-model="dialog"
      max-width="400px"
      persistent
    >
      <v-card>
        <v-card-title>
          <span class="text-subtitle-1">{{ formTitle }}</span>
          <v-spacer></v-spacer>
          <span class="text-subtitle-1">
          	<strong>ID: {{ editedItem.idtwilio_vendedoras }}</strong>
          </span>
        </v-card-title>

        <v-card-text>
          <v-col cols="12">
          	<v-autocomplete
		  			  label="Vendedoras"
		  			  id="id"
		  			  filled
		  			  dense
		  			  hide-details
		  			  v-model="editedItem.id_vendedora"
		  			  :items="usuarios"
		  			  item-text="nombre_completo"
		  			  item-value="id_usuario"
		  			  clearable
		  			></v-autocomplete>
      		</v-col>

      		<!-- <v-col cols="12">
          	<v-text-field
          	  label="Prioridad"
          	  id="id"
          	  type="number"
          	  filled
          	  dense
          	  v-model="editedItem.prioridad"
		  			  hide-details
          	></v-text-field>
      		</v-col> -->

          <v-col cols="12">
            <v-text-field
              label="% del plantel"
              id="id"
              type="number"
              filled
              dense
              v-model="editedItem.porcentaje_plantel"
              hide-details
            ></v-text-field>
          </v-col>

          <v-col cols="12">
            <v-text-field
              label="% sin plantel"
              id="id"
              type="number"
              filled
              dense
              v-model="editedItem.porcentaje_sin_plantel"
              hide-details
            ></v-text-field>
          </v-col>

      		<v-col cols="12">
          	<v-autocomplete
		  			  label="Plantel"
		  			  id="id"
		  			  filled
		  			  dense
		  			  hide-details
		  			  v-model="editedItem.id_plantel"
		  			  :items="planteles"
		  			  item-text="plantel"
		  			  item-value="id_plantel"
		  			  clearable
		  			></v-autocomplete>
      		</v-col>

        </v-card-text>

        <v-card-actions>
          <v-btn
            color="black"
            dark
            small
            @click="dialog = false"
            tile
            dense
          >
          	<v-icon left small>mdi-close</v-icon>
            Cancelar
          </v-btn>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            small
            dark
            @click="save"
            tile
          >
          	<v-icon left small>mdi-content-save</v-icon>
            Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Dialogo para editar los twilios -->
    <!-- Dialogo de editar o agregar-->
    <v-dialog
      v-model="dialogTwilios"
      max-width="600px"
      persistent
    >
      <v-card v-if="twilios">
        <v-card-title>
          <span class="text-subtitle-1">Permisos de CRM</span>
          <v-spacer></v-spacer>
          <span class="text-subtitle-1">
            <strong>Vendedora: {{ twilios.nombre }}</strong>
          </span>
        </v-card-title>

        <v-card-text>

          <v-row>
            <v-col cols="12" md="10">
              <v-autocomplete
                label="Vendedoras"
                id="id"
                filled
                dense
                v-model="usuarioAreemplazar"
                :items="usuarios"
                item-text="nombre_completo"
                item-value="id_usuario"
                clearable
                return-object
                @keyup.enter="addDetalle( )"
              ></v-autocomplete>
            </v-col>

            <v-col cols="12" md="2">
              <v-btn color="orange">
                <v-icon tile dark @click="addDetalle( )">mdi-plus</v-icon>
              </v-btn>
            </v-col>
          </v-row>

          <v-data-table
            :headers="headersTwilios"
            :items="twilios.twilios"
            class="elevation-0"
            :mobile-breakpoint="100"
            dense
          >

            <template v-slot:item.actions="{ item }">
              <v-btn 
                color="error" 
                x-small
                @click="deleteReemplazo(item)"
                class="mr-2"
              >
                <v-icon small> mdi-delete</v-icon>
              </v-btn>
            </template>
          </v-data-table>
        </v-card-text>

        <v-card-actions>
          <v-btn
            color="black"
            dark
            small
            @click="dialogTwilios = false"
            tile
            dense
          >
            <v-icon left small>mdi-close</v-icon>
            Cerrar
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialogTwiliosVendedora"
      max-width="600px"
      persistent
    >
      <v-card>
        <v-card-title>
          <span class="text-subtitle-1">Planteles por vendedora</span>
          <v-spacer></v-spacer>
          <span class="text-subtitle-1">
          </span>
        </v-card-title>

        <v-card-text>

          <v-data-table
            :headers="headers2"
            :items="plantelesVendedora"
            class="elevation-0"
            :mobile-breakpoint="100"
            dense
          >

            <template v-slot:item.actions="{ item }">
              <v-btn 
                color="primary" 
                x-small
                @click="editItem(item)"
                class="mr-2"
              >
                <v-icon small>mdi-magnify</v-icon>
              </v-btn>

              <v-btn 
                color="error" 
                x-small
                @click="deleteItem(item)"
                class="mr-2"
              >
                <v-icon small> mdi-delete</v-icon>
              </v-btn>

            </template>


            <template v-slot:no-data>
              <v-btn
                color="primary"
                @click="initialize"
                small
              >
                Actualizar
              </v-btn>
            </template>

          </v-data-table>
        </v-card-text>

        <v-card-actions>
          <v-btn
            icon
            small
            @click="dialogTwiliosVendedora = false"
            tile
            dense
            absolute
            top
            right
          >
            <v-icon left small>mdi-close</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialogVendedoras"
      overlay
      max-width="1200px"
      transition="dialog-transition"
    >
      <v-card>
        <v-card-title class="text-subtitle-1">
          Vendedoras asignadas al plantel
          <v-spacer></v-spacer>
          <v-btn
            color="orange"
            dark
            class="mr-2"
            @click="dialog = true"
            small
            tile
          >
            <v-icon small>mdi-plus</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-data-table
            :headers="headersVendedoras"
            :items="filterVendedoras"
            class="elevation-0"
            :search="search"
            :mobile-breakpoint="100"
            dense
          >
            
            <template v-slot:item.nombre="{ item }">
              <span>
                <v-icon v-if="item.sobrecarga" color="red" small left>mdi-checkbox-blank-circle</v-icon>
                {{ item.nombre }}
              </span>
            </template>

            <template v-slot:item.lada="props">
              <v-edit-dialog
                :return-value.sync="props.item.lada"
                @save="saveLada( props.item )"
                large
              >
                <span v-if="props.item.lada == 81">[81, 82, 83, 84, 85, 86]</span>
                <span v-if="props.item.lada == 33">[33, 37, 38]</span>
                <span v-if="props.item.lada == 55">[55, 56, 59]</span>
                <span v-if="props.item.lada == 0">Sin LADA</span>
                <span v-if="props.item.lada && !['81','33','55','0'].includes( props.item.lada )">ERROR EN LADA -> {{ props.item.lada }}</span>
                <template v-slot:input>
                  <v-text-field
                    v-model="props.item.lada"
                    label="Agrega la lada, no debe tener espacios"
                    single-line
                    counter
                  ></v-text-field>


                  <v-radio-group
                    v-model="props.item.lada"
                    column
                    @change="saveLada( props.item )"
                  >
                    <v-radio
                      label="Nuevo león"
                      :value="'81'"
                    ></v-radio>
                    <v-radio
                      label="CDMX"
                      :value="'55'"
                    ></v-radio>
                    <v-radio
                      label="GDL"
                      :value="'33'"
                    ></v-radio>
                    <v-radio
                      label="Sin LADA"
                      :value="'0'"
                    ></v-radio>
                  </v-radio-group>

                </template>
              </v-edit-dialog>
            </template>

            <template v-slot:item.nombresTwilios="{ item }">
              <span v-for="(tw, i) in item.nombresTwilios" @click="abrirTablaTwilio( item )" v-if="item.twilios.length > 0 ">
                {{ tw }}<br/>
              </span>

              <v-btn color="primary" small @click="abrirTablaTwilio( item )" v-if="!item.twilios.length" >Agregar</v-btn>
            </template>

            <template v-slot:item.reloj="{ item }">
              <v-chip v-if="item.reloj"  color="green" small dark>En sucursal</v-chip>
              <v-chip v-if="!item.reloj" color="red"   small dark>Salió</v-chip>
            </template>

            <template v-slot:item.porcentaje_plantel="props">
              <v-edit-dialog
                :return-value.sync="props.item.porcentaje_plantel"
                @save="savePorcentaje( props.item, 1 )"
              >
                {{ props.item.porcentaje_plantel }}
                <template v-slot:input>
                  <v-text-field
                    v-model="props.item.porcentaje_plantel_nuevo"
                    label="Agregar el % con plantel"
                    single-line
                    counter
                  ></v-text-field>
                </template>
              </v-edit-dialog>
            </template>

            <template v-slot:item.porcentaje_sin_plantel="props">
              <v-edit-dialog
                :return-value.sync="props.item.porcentaje_sin_plantel"
                @save="savePorcentaje( props.item, 2 )"
              >
                {{ props.item.porcentaje_sin_plantel }}
                <template v-slot:input>
                  <v-text-field
                    v-model="props.item.porcentaje_sin_plantel_nuevo"
                    label="Agregar el % con plantel"
                    single-line
                    counter
                  ></v-text-field>
                </template>
              </v-edit-dialog>
            </template>

            <template v-slot:item.actions="{ item }">
              <v-btn 
                color="error" 
                x-small
                @click="deleteItem(item)"
                class="mr-2"
              >
                <v-icon small> mdi-delete</v-icon>
              </v-btn>
            </template>

          </v-data-table>
        </v-card-text>
      </v-card>
    </v-dialog>


    <!-- Dialogo para eliminar -->
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
      	<v-card-text>
	      	<v-row justify="space-around">
	      		<v-col cols="6">
	      			<v-card class="elevation-0" height="100%" style="display: table;">
		      			<div class="text-subtitle-1" style="display: table-cell; vertical-align: middle;">
		      				<strong>Eliminar</strong> ¿Estás seguro que deseas eliminar los datos?
			      			<v-card-actions class="pt-6">
			      				<!-- Cerrar dialogo de confirmación y abrir el dialogo de editar la clase -->
			      			  <v-btn color="error" text  class="mr-2" @click="closeDelete()">No, cancelar</v-btn>
			      			  <!-- Guardar la información  -->
			      			  <v-btn color="#5C5C5C"  dark class="elevation-6"  @click="deleteItemConfirm()">Si</v-btn>
			      			</v-card-actions>
		      			</div>
	      			</v-card>
	      		</v-col>
	      		<v-col cols="5">
	      			<v-img src="@/assets/borrar.png"></v-img>
	      		</v-col>
		      </v-row>
      	</v-card-text>
      </v-card>
    </v-dialog>

    <!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>

  </v-dialog>
</template>
<script>
import axios from 'axios';
import { mapGetters } from "vuex";
// Componentes
import Alerta from "@/components/alertas/Alerta.vue";
import carga from "@/components/alertas/carga.vue";

import validarErrores from "@/mixins/validarErrores";
import funcionesExcel from "@/mixins/funcionesExcel";

export default {

  mixins: [ validarErrores, funcionesExcel ],

  props: ['dialogTwilio', 'nivel','leccion'],

  components: {
    Alerta,
    carga,
  },

  data: () => ({
      // Alertas
      parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      loader: true,
      cargar: false,

      dialog: false,
      dialogDelete: false,

      editedIndex: -1,

      editedItem: {
        idtwilio_vendedoras:0,
				prioridad:0,
        id_vendedora:0,
				deleted:0,
				fecha_creacion: null,
				nombre: '',
        porcentaje_plantel:0,
        porcentaje_sin_plantel:0,
      },

      defaultItem: {
        idtwilio_vendedoras:0,
				prioridad:0,
        id_vendedora:0,
				deleted:0,
				fecha_creacion: null,
				nombre: '',
        porcentaje_plantel:0,
        porcentaje_sin_plantel:0,
      },

      twilios: null,

			search:'',
      vendedoras: [],
      usuarios: [],
      planteles:[],
      
      headersVendedoras: [
        { text: 'ID'                 , value: 'idtwilio_vendedoras'    },
        { text: 'Vendedora'          , value: 'nombre'                 },
        { text: 'Reloj'              , value: 'reloj'                  },
        { text: 'Plantel'            , value: 'plantel'                },
        { text: 'Lada'               , value: 'lada'                   },
        // { text: '% Ventas'        , value: 'ventas'                 },
        { text: '% de parti.' , value: 'porcentaje_plantel'     },
        // { text: 'Twilios'            , value: 'nombresTwilios'         },
        { text: 'Actions'            , value: 'actions', sortable: false },
      ],

      headers: [
        { text: 'ID'            , value: 'id_plantel'    },
        { text: 'Plantel'       , value: 'plantel'       },
        { text: 'Match'         , value: 'comentarios'   },
        { text: 'Vendedoras'    , value: 'vendedoras_2'  },
        // { text: 'Iniciales'     , value: 'iniciales'     },
        { text: 'Actions'       , value: 'actions', sortable: false },
      ],

      headers2: [
        { text: 'Plantel'    , value: 'plantel'              },
        { text: 'Zona'       , value: 'zona'                 },
        { text: 'Prioridad'  , value: 'prioridad'            },
        { text: 'Actions'    , value: 'actions', sortable: false },
      ],

      headersTwilios: [
        { text: 'Vendedora'  , value: 'crm'                  },
        { text: 'Actions'    , value: 'actions', sortable: false },
      ],

      dialogTwilios: false,
      usuarioAreemplazar: null,

      contador : 0,
      vendedorasAll:[],
      plantelesVendedora:[],
      dialogTwiliosVendedora: false,
      plantelesSinVendedora: [],
      escuela: 3,

      plantelSeleccionado: null,
      dialogVendedoras: false,
      porcentaje_plantel: 0,
    }),

    computed: {
    	...mapGetters('login',['getdatosUsuario']),
      formTitle () {
        return this.editedIndex === -1 ? 'Agregar Diapositiva' : 'Editar Diapositiva'
      },

      filterPlanteles( ){

        let data  = this.planteles

        // Si la escuela es diferente a 3 que es todas, hacemos match del plantel
        if( this.escuela != 3 ){
          
          // Hacemos el filtro por escuela, solo 1 y 2 
          data = this.escuela == 1 ? data.filter( el => !el.plantel.match('FAST') ) : data.filter( el => el.plantel.match('FAST') )

        } 
        

        return data
      },

      // Filtramos los twilios por plantel y retornamos las vendedoras
      filterVendedoras( ){

        return this.vendedorasAll.filter( el => el.id_plantel == this.plantelSeleccionado )

      }
    },

    watch: {
      dialogDelete (val) {
        val || this.closeDelete()
      },

    },

    async created () {
      await this.initialize()
      await this.getPlanteles()
    },

    methods: {


	    initialize() {
        this.cargar = true;
        this.vendedoras = []

        return axios.post('twilio.vendedoras').then(response => {
          this.planteles             = response.data.planteles
          this.vendedoras            = response.data.vendedorasAll
          this.usuarios              = response.data.usuarios
          this.vendedorasAll         = response.data.vendedorasAll
          this.plantelesSinVendedora = response.data.plantelesSinVendedora

          this.vendedoras = this.vendedoras.filter((persona, index, self) =>
            index === self.findIndex((p) => p.nombre === persona.nombre)
          );

          this.cargar = false;
        }).catch(error => {
          this.validarError(error.response.data.message);
        }).finally(() => {
          this.cargar = false;
        });
      },

      // Función para obtener los planteles
      getPlanteles() {
        this.cargar = true; // iniciamos el loader
        // this.planteles = [] // Limpiar el arreglo de planteles

        // Realizamosla consulta
        return axios.get('planteles.list.erp').then(response => {

          // Limpiamos la basura de la consulta
          // this.planteles = response.data.filter( el => el.estado > 0 && ![10000,10001,30].includes( el.id_plantel ) )
          
          this.cargar = false; // Cerramos el loader
        }).catch(error => {
          // Validamos los errores que rebota la consulta
          this.validarError(error.response.data.message);
        }).finally(() => {
          // erramos el loader
          this.cargar = false;
        });
      },


      editItem (item) {
        this.editedIndex = this.vendedoras.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      },

      deleteItem (item) {
        this.editedIndex = this.vendedoras.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },

      deleteItemConfirm () {
      	this.cargar = true
        // Lo mandapos por el EP
        this.editedItem.deleted = 1
      	this.$http.post('twilio.vendedoras.update', this.editedItem).then(response=>{
        	this.initialize()
      		this.closeDelete()
        	this.cargar = false
        }).catch( error =>{
        	this.validarError( error.response.data.message )
      	}).finally( () => { this.cargar = false })
        	
      },

      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      exportExce( ){

        let data = this.filterPlanteles
        for( const i in data ){

          const { vendedoras_2 } = data[i]

          const texto = vendedoras_2.join(', '); // "manzana, banana, naranja"

          data[i]['distribucion'] = texto

        }

        this.exportExcel( data  , 'ASIGNACION_TWILIO')
      },

      // Función para poder ver las vendedoras de ese plantel
      verVendedoras( item ){

        // Guardamos el plantel seleccionado
        this.plantelSeleccionado = item.id_plantel
        // Abrir el dialog de vendedoras
        this.dialogVendedoras = true

      },

      async save () {
      	// Cargamos al usuario que hizo el cambio
	      this.cargar = true

        if (this.editedIndex > -1) {
	        const buscarVendedora = this.usuarios.find( el => el.id_usuario == this.editedItem.id_vendedora )

        	if( !buscarVendedora ){

        		return this.validarErrorDirecto('Favor de seleccionar una vendedora')

        	}

        	// Aquí hay que generar los datos para enviar, como lo son.... 
        	const payload = {
        		idtwilio_vendedoras: this.editedItem.idtwilio_vendedoras,
        		id_vendedora       : this.editedItem.id_vendedora,
        		id_plantel         : this.editedItem.id_plantel,
        		prioridad          : this.editedItem.prioridad,
        		nombre             : buscarVendedora ? buscarVendedora.nombre_completo : 'Sin nombre',
        		deleted            : 0
        	}

	        // Lo mandapos por el EP
          this.$http.post('twilio.vendedoras.update', payload).then(response=>{
          	this.initialize()
        		this.close()
	        	this.cargar = false
	        }).catch( error =>{
          	this.validarError( error.response.data.message )
        	}).finally( () => { this.cargar = false })

        } else {

        	const buscarVendedora = this.usuarios.find( el => el.id_usuario == this.editedItem.id_vendedora )

        	if( !buscarVendedora ){

        		return this.validarErrorDirecto('Favor de seleccionar una vendedora')

        	}
        	// Aquí hay que generar los datos para enviar, como lo son.... 
        	const payload = {
        		id_vendedora: this.editedItem.id_vendedora,
        		id_plantel  : this.editedItem.id_plantel,
        		prioridad   : this.editedItem.prioridad,
        		nombre      : buscarVendedora ? buscarVendedora.nombre_completo : 'Sin nombre'
        	}

	        // Lo mandapos por el EP
          this.$http.post('twilio.vendedoras.add', payload).then(response=>{
          	this.initialize()
        		this.close()
	        	this.cargar = false
	        }).catch( error =>{
          	this.validarError( error.response.data.message )
        	}).finally( () => { this.cargar = false })

        }
      },


      abrirTablaTwilio( item ){

        this.twilios = item

        this.dialogTwilios = true
      
      },


      saveLada( item ){
        // Aquí hay que generar los datos para enviar, como lo son.... 
        const payload = {
          idtwilio_vendedoras: item.idtwilio_vendedoras,
          lada               : item.lada,
        }

        // Lo mandapos por el EP
        this.$http.post('twilio.vendedoras.lada', payload).then(response=>{
          this.initialize()
          this.close()
          this.cargar = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      abrirPlanteles( item ){

        const { id_vendedora } = item

        this.plantelesVendedora = this.vendedorasAll.filter( el => el.id_vendedora == id_vendedora )

        this.dialogTwiliosVendedora = true
      
      },

      addDetalle( ){

        if( this.usuarioAreemplazar ){

          // Buscar ese usuario a reemplazar en otros usuarios
          const { id_usuario } = this.usuarioAreemplazar

          for( const i in this.vendedoras ){

            const { twilios, nombre } = this.vendedoras[i]

            for( const j in twilios ){

              const { usuario_crm } = twilios[j]

              if( usuario_crm == id_usuario ){

                if( this.contador == 0 ){
                  
                  this.contador = 1
                  return this.validarErrorDirecto(`La vendedora ${nombre} ya cuenta con ese acceso, si estás consciente de eso, solo cierra está ventana e intenta nuevamente!`)

                }
              }

            }
          }


          this.cargar = true

          const payload = {

            idusuario  : this.twilios.id_vendedora,
            usuario_crm: this.usuarioAreemplazar.id_usuario,

          }

          // Lo mandapos por el EP
          this.$http.post('twilio.acceso.crm', payload).then(response=>{
            this.dialogTwilios      = false
            this.usuarioAreemplazar = null
            this.twilios            = null
            this.cargar = false
            this.contador = 0
            this.initialize()
            this.close()
          }).catch( error =>{
            this.validarError( error.response.data.message )
          }).finally( () => { this.cargar = false })


        }

      },

      savePorcentaje( item, op ){

        let { porcentaje_plantel, id_plantel, idtwilio_vendedoras, porcentaje_plantel_nuevo, porcentaje_sin_plantel, porcentaje_sin_plantel_nuevo, lada } = item

        let vendedoras = op == 1 ? this.filterVendedoras : this.vendedorasAll

        vendedoras = vendedoras.filter( el => el.lada == lada )

        console.log( vendedoras , lada )

        let suma = 0

        for( const i in vendedoras ){

          if( op == 1 ){
            
            suma += vendedoras[i].idtwilio_vendedoras == idtwilio_vendedoras ? parseFloat( porcentaje_plantel_nuevo ) : parseFloat( vendedoras[i].porcentaje_plantel )

          }else{

            suma += vendedoras[i].idtwilio_vendedoras == idtwilio_vendedoras ? parseFloat( porcentaje_sin_plantel_nuevo ) : parseFloat( vendedoras[i].porcentaje_sin_plantel )

          }


        }

        // Si el 
        if( suma > 100 ){

          // Calulamos el porcentaje que ya tenía anteriormente
          return this.validarErrorDirecto(`Porcentajes incorrectos, favor de actualizarlos, la suma da: ${suma}`)

        }

        item['op'] = op

        item.porcentaje_plantel = porcentaje_plantel_nuevo

        // Lo mandapos por el EP
        this.$http.post('twilio.vendedoras.porcentajes', item).then(response=>{
          this.validarSuccess( response.data.message )
          this.initialize()
          this.close()
          this.cargar = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })


      },

      deleteReemplazo( item ){

        // Lo mandapos por el EP
        this.$http.post('twilio.acceso.crm.delete', item).then(response=>{
          this.dialogTwilios      = false
          this.usuarioAreemplazar = null
          this.twilios            = null
          this.initialize()
          this.close()
          this.cargar = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })

      },

      saveMatch( item ){
        // Lo mandapos por el EP
        this.$http.post('twilio.matches.plantel', item).then(response=>{
          this.dialogTwilios      = false
          this.usuarioAreemplazar = null
          this.twilios            = null
          this.validarSuccess('Datos actualizados correctamente')
          this.initialize()
          this.close()
          this.cargar = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })

      },


      deleteVendedora( item ){

        console.log( item )


      }

    },
  }
</script>
<style scoped>
  .dropZone {
    width: 80%;
    height: 150px;
    position: relative;
    border: 2px dashed #eee;
  }

  .dropZone:hover {
    border: 2px solid #D8D8D8;
  }

  .dropZone:hover .dropZone-title {
    color: #1975A0;
  }

  .dropZone-info {
    color: #A8A8A8;
    position: absolute;
    top: 50%;
    width: 100%;
    transform: translate(0, -50%);
    text-align: center;
  }

  ..dropZone-title {
    color: #787878;
  }

  .dropZone input {
    position: absolute;
    cursor: pointer;
    top: 0px;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
  }

  .dropZone-upload-limit-info {
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
  }

  .dropZone-over {
    background: #D8D8D8;
    opacity: 0.8;
  }

  .dropZone-uploaded {
    width: 80%;
    height: 200px;
    position: relative;
    border: 2px dashed #eee;
  }

  .dropZone-uploaded-info {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #D8D8D8;
    position: absolute;
    top: 50%;
    width: 100%;
    transform: translate(0, -50%);
    text-align: center;
  }

  .removeFile {
    width: 200px;
  }
</style>