<template>
	<v-container class="mt-4 px-5" fluid>
	  <v-row>
	  	<v-col cols="12">
	  		<v-card class="shadowCard">
	  		  <v-card-title primary-title>
	  		    <span class="text-subtitle-1">Ciclos</span>
	  		    <v-spacer></v-spacer>

	  		    <v-btn  class="mr-2"  small dark color="green" @click="exportExcel( ciclos  , 'CICLOS_ERP')" tile >
              <v-icon small>mdi-microsoft-excel</v-icon>
            </v-btn>

            <v-btn color="orange" dark class="mr-2" @click="dialog = true" small tile>
              <v-icon left small>mdi-plus</v-icon>
              Agregar
            </v-btn>

            <v-btn color="primary" dark class="text-capitalize" @click="initialize()" small tile>
              <v-icon left small>mdi-refresh</v-icon>
              Actualizar
            </v-btn>

	  		  </v-card-title>
	  		  <v-card-text>s
	  		  	<v-row justify="end">
	  		  		<v-col cols="12" md="4" lg="3">
	  		  			<v-text-field
	  		  			  name="name"
	  		  			  label="Inicio"
	  		  			  id="id"
	  		  			  filled
	  		  			  dense
	  		  			  hide-details
	  		  			  type="date"
	  		  			  v-model="incio"
	  		  			></v-text-field>
	  		  		</v-col>
	  		  		<v-col cols="12" md="4" lg="3">
	  		  			<v-text-field
	  		  			  name="name"
	  		  			  label="fin"
	  		  			  id="id"
	  		  			  filled
	  		  			  dense
	  		  			  hide-details
	  		  			  type="date"
	  		  			  v-model="fin"
	  		  			></v-text-field>
	  		  		</v-col>
	  		  		<v-spacer></v-spacer>

	  		  		<v-col cols="12" md="4" lg="3">
	  		  			<v-text-field
	  		  			  name="name"
	  		  			  label="Buscar"
	  		  			  id="id"
	  		  			  filled
	  		  			  dense
	  		  			  hide-details
	  		  			  append-icon="mdi-magnify"
	  		  			  v-model="search"
	  		  			></v-text-field>
	  		  		</v-col>
	  		  	</v-row>
	  		    <v-row>
	  		    	<v-col cols="12">
							  <v-data-table
							    :headers="headers"
							    :items="ciclos"
							    class="elevation-0"
							    :search="search"
							    :page.sync="page"
			            @page-count="pageCount = $event"
							    :mobile-breakpoint="100"
			            hide-default-footer
			            dense
							  >
							    <!-- Valores que ocupan botons, chips, iconos, etc -->
							    <template v-slot:item.activo_sn="{ item }">
							      <v-chip 
							      	v-if="item.activo_sn == 1"
							      	small
							      	color="green"
							      	dark
							      >Si</v-chip>

							      <v-chip 
							      	v-else
							      	small
							      	color="error"
							      	dark
							      >No</v-chip>
							    </template>

                  <template v-slot:item.activo_ventas="{ item }">
                    <v-chip 
                      v-if="item.activo_ventas == 1"
                      small
                      color="green"
                      dark
                    >Si</v-chip>

                    <v-chip 
                      v-else
                      small
                      color="error"
                      dark
                    >No</v-chip>
                  </template>

							    <template v-slot:item.ciclo_abierto_sn="{ item }">
							      <v-chip 
							      	v-if="item.ciclo_abierto_sn == 1"
							      	small
							      	color="green"
							      	dark
							      >Si</v-chip>

							      <v-chip 
							      	v-else
							      	small
							      	color="error"
							      	dark
							      >No</v-chip>
							    </template>

							    <template v-slot:item.en_grupo="{ item }">
							      <v-chip 
							      	v-if="item.en_grupo == 1"
							      	small
							      	color="green"
							      	dark
							      >Si</v-chip>

							      <v-chip 
							      	v-else
							      	small
							      	color="error"
							      	dark
							      >No</v-chip>
							    </template>

							    <template v-slot:item.actions="{ item }">
							    	<v-btn 
							    		color="primary" 
							    		x-small
							    		@click="editItem(item)"
							    		class="mr-2"
							    	>
								      <v-icon small>mdi-magnify</v-icon>
							    	</v-btn>

							    	<v-btn 
							    		color="error" 
							    		x-small
							    		@click="deleteItem(item)"
							    		class="mr-2"
							    	>
								      <v-icon small> mdi-delete</v-icon>
							    	</v-btn>

							    	<v-btn 
							    		color="yellow" 
							    		x-small
							    		@click="relacionarCiclo(item)"
							    	>
								      <v-icon small>mdi-compare-horizontal</v-icon>
							    	</v-btn>
							    </template>

							    <template v-slot:no-data>
							      <v-btn
							        color="primary"
							        @click="initialize"
							        small
							      >
							        Actualizar
							      </v-btn>
							    </template>

							  </v-data-table>
	  		    	</v-col>
	  		    </v-row>
	  		  </v-card-text>
	  		  <v-card-actions>
            <v-spacer></v-spacer>
            <v-pagination
              circle
              v-model="page"
              :length="pageCount"
              :total-visible="7"
            ></v-pagination>
          </v-card-actions>
	  		</v-card>
	  	</v-col>
	  </v-row>

	  <!-- Dialogo de editar o agregar-->
	  <v-dialog
      v-model="dialog"
      max-width="1000px"
      persistent
    >
      <v-card>
        <v-card-title>
          <span class="text-subtitle-1">{{ formTitle }}</span>
          <v-spacer></v-spacer>
          <span class="text-subtitle-1">
          	<strong>ID: {{ editedItem.id_ciclo }}</strong>
          </span>
        </v-card-title>

        <v-card-text>
          <v-row>
          	<v-col cols="12" md="6" lg="4">
              <v-text-field
                v-model="editedItem.ciclo"
                label="* Ciclo"
                filled
                hide-details
                dense
                :readonly="editedIndex === -1 ? false : true"
              ></v-text-field>
            </v-col>

            <v-spacer></v-spacer>

            <v-col cols="12" md="6" lg="3">
              <v-text-field
                v-model="editedItem.fecha_inicio_ciclo"
                label="* Fecha inicio"
                filled
                hide-details
                dense
                type="date"
                :readonly="editedIndex === -1 ? false : true"
              ></v-text-field>
            </v-col>

            <v-col cols="12" md="6" lg="3">
              <v-text-field
                v-model="editedItem.fecha_fin_ciclo"
                label="* Fecha final"
                filled
                hide-details
                dense
                type="date"
                :readonly="editedIndex === -1 ? false : true"
              ></v-text-field>
            </v-col>

            <v-col cols="12">
              <v-textarea
                v-model="editedItem.comentarios"
                label="Comentarios"
                outlined
                hide-details
                dense
                auto-grow
              ></v-textarea>
            </v-col>

            <v-col cols="12" sm="6" class="pt-0">
            	<v-switch 
            		label="¿Ciclo Abierto Si/No?"
                hide-details
            		v-model="editedItem.ciclo_abierto_sn">
            	</v-switch>
            	
            	<v-switch 
            		label="¿Activo Si/No?"
                hide-details
            		v-model="editedItem.activo_sn">
            	</v-switch>

              <v-switch 
                label="¿Acepta pagos Si/No?"
                hide-details
                v-model="editedItem.activo_ventas">
              </v-switch>
            </v-col>

            <v-col cols="12" sm="6" class="pt-0">
            	<div class="text-subtitle-1"><b>Fecha alta:</b> {{ editedItem.fecha_alta }}</div>
            	<div class="text-subtitle-1"><b>Fecha baja:</b> {{ editedItem.fecha_baja }}</div>
            </v-col>

          </v-row>
          <v-row>
            <v-col cols="4" class="pa-2">
              <v-card>
                <v-card-title>
                  <span class="text-subtitle-1">Descuento #1 - 20%</span>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text>
                  <v-text-field label="Fecha Inicial" type="date" filled dense hide-details v-model="editedItem.fechaIniDesc1" class="pb-2"></v-text-field>
                  <v-text-field label="Fecha Final" type="date" filled dense hide-details v-model="editedItem.fechaFinDesc1"></v-text-field>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="4" class="pa-2">
              <v-card>
                <v-card-title>
                  <span class="text-subtitle-1">Descuento #2 - 8%</span>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text>
                  <v-text-field label="Fecha Inicial" type="date" filled dense hide-details v-model="editedItem.fechaIniDesc2" class="pb-2"></v-text-field>
                  <v-text-field label="Fecha Final" type="date" filled dense hide-details v-model="editedItem.fechaFinDesc2"></v-text-field>
                </v-card-text>
              </v-card>
            </v-col>
            <v-col cols="4" class="pa-2">
              <v-card >
                <v-card-title>
                  <span class="text-subtitle-1">Descuento #3 - 0%</span>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text>
                  <v-text-field label="Fecha Inicial" type="date" filled dense hide-details v-model="editedItem.fechaIniDesc3" class="pb-2"></v-text-field>
                  <v-text-field label="Fecha Final" type="date" filled dense hide-details v-model="editedItem.fechaFinDesc3"></v-text-field>
                </v-card-text>
              </v-card>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="close"
          >
            Cancelar
          </v-btn>
          <v-btn
            color="blue darken-1"
            class="elevation-6"
            dark
            @click="save"
          >
            Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Dialogo para eliminar -->
    <v-dialog v-model="dialogDelete" max-width="500px">
      <v-card>
      	<v-card-text>
	      	<v-row justify="space-around">
	      		<v-col cols="6">
	      			<v-card class="elevation-0" height="100%" style="display: table;">
		      			<div class="text-subtitle-1" style="display: table-cell; vertical-align: middle;">
		      				<strong>Eliminar</strong> ¿Estás seguro que deseas eliminar el ciclo?
			      			<v-card-actions class="pt-6">
			      				<!-- Cerrar dialogo de confirmación y abrir el dialogo de editar la clase -->
			      			  <v-btn color="error" text  class="mr-2" @click="closeDelete()">No, cancelar</v-btn>
			      			  <!-- Guardar la información  -->
			      			  <v-btn color="#5C5C5C"  dark class="elevation-6"  @click="deleteItemConfirm()">Si</v-btn>
			      			</v-card-actions>
		      			</div>
	      			</v-card>
	      		</v-col>
	      		<v-col cols="5">
	      			<v-img src="@/assets/borrar.png"></v-img>
	      		</v-col>
		      </v-row>
      	</v-card-text>
      </v-card>
    </v-dialog>

    <!-- Dialogo para relacionar -->
	  <v-dialog
      v-model="dialogRelacionar"
      max-width="400px"
      persistent
    >
      <v-card>
        <v-card-title>
          <span class="text-subtitle-1">{{ formTitle }}</span>
          <v-spacer></v-spacer>
          <span class="text-subtitle-1">
          	<strong>ID: {{ editedItem.id_ciclo }}</strong>
          </span>
        </v-card-title>

        <v-card-text>
        	<v-autocomplete
        	  :items="ciclosActivos"
        	  v-model="editedItem.id_ciclo_relacionado"
        	  label="Ciclo relaciones"
        	  item-text="ciclo"
        	  item-value="id_ciclo"
        	  outlined
        	  dense
        	></v-autocomplete>
        </v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue darken-1"
            text
            @click="closeRelacionar"
          >
            Cancelar
          </v-btn>
          <v-btn
            color="blue darken-1"
            class="elevation-6"
            dark
            @click="saveRelacionar"
          >
            Guardar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>

	</v-container>
</template>
<script>
import axios from 'axios';
	import { mapGetters } from 'vuex'
	// Componentes
  import Alerta                from '@/components/alertas/Alerta.vue'
  import carga                 from '@/components/alertas/carga.vue';
  
  import validarErrores        from '@/mixins/validarErrores'
  import funcionesExcel        from '@/mixins/funcionesExcel'

  export default {

    mixins: [ validarErrores, funcionesExcel ],
    
  	components:{
      Alerta,
      carga,
    },

    data: () => ({
    	// Alertas
    	parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      loader: true,
      cargar: false,

      dialog: false,
      dialogDelete: false,
      dialogRelacionar:false,
      ciclosActivos:[],

      
      editedIndex: -1,

      editedItem: {
        id_ciclo:0,
				ciclo:'',
				fecha_inicio_ciclo:null,
				fecha_fin_ciclo:null,
				comentarios:'',
				fecha_alta:null,
				activo_sn:0,
				fecha_baja:null,
				id_usuario_ultimo_cambio:0,
				fecha_ultimo_cambio:null,
				ciclo_abierto_sn:0,
				id_ciclo_relacionado:0,
        activo_ventas:0
      },

      defaultItem: {
        id_ciclo:0,
				ciclo:'',
				fecha_inicio_ciclo:null,
				fecha_fin_ciclo:null,
				comentarios:'',
				fecha_alta:null,
				activo_sn:0,
				fecha_baja:null,
				id_usuario_ultimo_cambio:0,
				fecha_ultimo_cambio:null,
				ciclo_abierto_sn:0,
				id_ciclo_relacionado:0,
        activo_ventas:0,
        fechaIniDesc1: 0,
        fechaFinDesc1: 0,
        fechaIniDesc2: 0,
        fechaFinDesc2: 0,
        fechaIniDesc3: 0,
        fechaFinDesc3: 0,
      },

      // Filtros: 
      incio:null,
			fin:null,

      // tabla
			search:'',
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
      ciclos: [],
      headers: [
        { text: 'ID'           , value: 'id_ciclo'           },
        { text: 'Ciclo'        , value: 'ciclo'              },
        { text: 'Inicio'       , value: 'fecha_inicio_ciclo' },
        { text: 'Fin'          , value: 'fecha_fin_ciclo'    },
        { text: '¿Abierto?'    , value: 'activo_sn'          },
        { text: 'Acepta pagos' , value: 'activo_ventas'      },
        { text: 'Alta'         , value: 'fecha_alta'         },
        { text: 'Activo'       , value: 'ciclo_abierto_sn'   },
        { text: 'Baja'         , value: 'fecha_baja'         },
        { text: '¿En grupo?'   , value: 'en_grupo'           },
        { text: 'Actions'      , value: 'actions', sortable: false },
      ],

    }),

    computed: {
    	...mapGetters('login',['getdatosUsuario']),
      formTitle () {
        return this.editedIndex === -1 ? 'Agregar ciclo' : 'Editar ciclo'
      },
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },

      dialogRelacionar(val) {
        val || this.closeRelacionar()
      },
    },

    async created () {
      await this.initialize()
      await this.getCiclosActivos()
    },

    methods: {
      initialize () {
      	this.cargar = true
        this.ciclos = []
        return this.$http.get('ciclos.list').then(response=>{
        	this.ciclos = response.data
        	this.cargar      = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      getCiclosActivos() {
        this.ciclosActivos = []
        return this.$http.get('ciclos.activos').then(response=>{
        	this.ciclosActivos = response.data
        	this.cargar      = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      async editItem (item) {
        this.editedIndex = this.ciclos.indexOf(item)
        item.fechaIniDesc1 = 0;
        item.fechaFinDesc1 = 0;
        item.fechaIniDesc2 = 0;
        item.fechaFinDesc2 = 0;
        item.fechaIniDesc3 = 0;
        item.fechaFinDesc3 = 0;
        if(this.editedIndex != -1){
          await this.$http.get('ciclos.descuentos/'+ item.id_ciclo).then(response=>{
            let descuento1 = response.data.find(el=>el.numero_descuento == 1)
            let descuento2 = response.data.find(el=>el.numero_descuento == 2)
            let descuento3 = response.data.find(el=>el.numero_descuento == 3)
            if(descuento1){
              item.fechaIniDesc1 = descuento1.fecha_inicio
              item.fechaFinDesc1 = descuento1.fecha_final
            }
            if(descuento2){
              item.fechaIniDesc2 = descuento2.fecha_inicio
              item.fechaFinDesc2 = descuento2.fecha_final
            }
            if(descuento3){
              item.fechaIniDesc3 = descuento3.fecha_inicio
              item.fechaFinDesc3 = descuento3.fecha_final
            }
          }).catch( error =>{
            this.validarError( error.response.data.message )
          }).finally( () => { 
            this.cargar = false
          })
        }
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      },

      deleteItem (item) {
        this.editedIndex = this.ciclos.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },

      relacionarCiclo (item) {
        this.editedIndex = this.ciclos.indexOf(item)
        this.editedItem  = Object.assign({}, item)
        this.dialogRelacionar = true
      },

      deleteItemConfirm () {
      	this.cargar = true
        // Lo mandapos por el EP
        this.editedItem['id_usuario'] = this.getdatosUsuario.iderp
        this.$http.put('ciclos.eliminar/' + this.editedItem.id_ciclo, this.editedItem).then(response=>{
        	this.cargar = true
        	this.closeDelete()
        	this.initialize()
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      closeRelacionar() {
        this.dialogRelacionar = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },

      async save () {
      	if(this.editedItem.ciclo == ''){
      		this.validarErrorDirecto('Favor de llenar todos los campos')
      	}
      	// Cargamos al usuario que hizo el cambio
      	this.editedItem['id_usuario'] = this.getdatosUsuario.iderp
	      this.cargar = true

        if (this.editedIndex > -1) {
          console.log(this.editedItem)
          if(this.editedItem.fechaFinDesc1 && this.editedItem.fechaIniDesc1 && this.editedItem.fechaFinDesc2 
          && this.editedItem.fechaIniDesc2 && this.editedItem.fechaFinDesc3 && this.editedItem.fechaIniDesc3){
            this.cargar = true
            await this.$http.put('ciclos.descuentos.update', this.editedItem).then(response=>{
              console.log(response.data)
              this.validarSuccess( response.data.message )
            }).catch( error =>{
              this.validarError( error.response.data.message )
            }).finally( () => { this.cargar = false })

            // Lo mandapos por el EP
            this.$http.put('ciclos.update/' + this.editedItem.id_ciclo, this.editedItem).then(response=>{
            	this.validarSuccess( response.data.message )
            	this.initialize()
            }).catch( error =>{
              this.validarError( error.response.data.message )
            }).finally( () => { this.cargar = false })
          } else {
            this.validarErrorDirecto( 'Faltan fechas de los descuentos' )
          }
        } else {
	        // Lo mandapos por el EP
          if(this.editedItem.fechaFinDesc1 && this.editedItem.fechaIniDesc1 && this.editedItem.fechaFinDesc2 
          && this.editedItem.fechaIniDesc2 && this.editedItem.fechaFinDesc3 && this.editedItem.fechaIniDesc3){
            let insertID = 0;
            await this.$http.post('ciclos.add', this.editedItem).then(async response=>{
              console.log(response.data)
              insertID = response.data.id
              this.editedItem.id_ciclo = insertID
              await this.$http.put('ciclos.descuentos.update', this.editedItem).then(response=>{
              
              }).catch( error =>{
                this.validarError( error.response.data.message )
              }).finally( () => { })
              this.validarSuccess( 'Se inserto correctamente el ciclo' )
            	this.initialize()
            }).catch( error =>{
            	this.validarError( error.response.data.message )
            }).finally( () => { this.cargar = false })
            
          } else {
            this.validarErrorDirecto( 'Faltan fechas de los descuentos' )
          }
        }
        this.close()
      },

      saveRelacionar( ){
      	// Activamos el loader
        this.cargar = true
        // Lo mandapos por el EP
        this.editedItem['id_usuario'] = this.getdatosUsuario.iderp
        this.$http.put('ciclos.relacionar/' + this.editedItem.id_ciclo, this.editedItem).then(response=>{
        	this.initialize()
        	this.cargar = false
        }).catch( error =>{
        	this.validarError( error.response.data.message )
      	}).finally( () => { this.cargar = false })

      	this.closeRelacionar()
      },
    },
  }
</script>


