import Vue from 'vue'
import store from '@/store'
import {mapGetters, mapActions}    from 'vuex'

var moment   = require('moment');

moment.locale();

export default {
	data () {
		return {
			descripClima:'',
			temperatura:0,
			iconClima:null,
			tareas_programadas:[],
      countTareas:0,
		}
	},
	computed: {
		...mapGetters('Carrito', ['getCarrito']),
		...mapGetters('login', ['getdatosUsuario','getLogeado', 'getEscuela','getEstatusLlamada','getTareas','getNuevosProspectos']),
    ...mapGetters('tema', ['getTema']),
    ...mapGetters('Carrito',['getCarrito']),
	},

	methods: {
    ...mapActions('login', ['salirLogin','cambiarEscuela','abrirProspecto','cambiarEstatusLlamada','addTareas','traerProspectosNuevos']),


		getClima () {
      var f = new Date();
      f.getHours()

      const axios2  = require('axios');
      return axios2.get('https://api.openweathermap.org/data/2.5/weather?lat=25.737653954303077&lon=-100.30381858348848&appid=1529b6ff874632e5a53e60b69fb98e58&units=metric&lang=es').then((response) => {
        // handle success
        this.temperatura  = parseInt(response.data.current.temp);
        this.descripClima = response.data.current.weather[0].description
        switch(response.data.current.weather[0].icon){
          case '01d':
            this.iconClima = 'https://web-back-inbi.club/iconosclima/soleado.png'
          break;

          case '01n':
            this.iconClima = 'https://web-back-inbi.club/iconosclima/noche.png'
          break;

          case '02d':
            this.iconClima = 'https://web-back-inbi.club/iconosclima/pocas_nubes.png'
          break;

          case '02n':
            this.iconClima = 'https://web-back-inbi.club/iconosclima/noche_pocas_nubes.png'
          break;

          case '03d':
            this.iconClima = 'https://web-back-inbi.club/iconosclima/nubes_dispersas.png'
          break;

          case '03n':
            this.iconClima = 'https://web-back-inbi.club/iconosclima/nubes_dispersas.png'
          break;

          case '04d':
            this.iconClima = 'https://web-back-inbi.club/iconosclima/nubes_rotas.png'
          break;

          case '04n':
            this.iconClima = 'https://web-back-inbi.club/iconosclima/nubes_rotas.png'
          break;

          case '09d':
            this.iconClima = 'https://web-back-inbi.club/iconosclima/aguacero.png'
          break;

          case '09n':
            this.iconClima = 'https://web-back-inbi.club/iconosclima/aguacero.png'
          break;

          case '10d':
            this.iconClima = 'https://web-back-inbi.club/iconosclima/lluvia.png'
          break;

          case '10n':
            this.iconClima = 'https://web-back-inbi.club/iconosclima/lluvia_noche.png'
          break;

          case '11d':
            this.iconClima = 'https://web-back-inbi.club/iconosclima/tormenta.png'
          break;

          case '11n':
            this.iconClima = 'https://web-back-inbi.club/iconosclima/tormenta.png'
          break;

          case '13d':
            this.iconClima = 'https://web-back-inbi.club/iconosclima/nieve.png'
          break;

          case '13n':
            this.iconClima = 'https://web-back-inbi.club/iconosclima/nieve.png'
          break;

          case '50d':
            this.iconClima = `http://openweathermap.org/img/wn/${response.data.current.weather[0].icon}@2x.png`
          break;

          case '50n':
            this.iconClima = `http://openweathermap.org/img/wn/${response.data.current.weather[0].icon}@2x.png`
          break;
        }
        // this.iconClima = `http://openweathermap.org/img/wn/${response.data.current.weather[0].icon}@2x.png`
      })
      .catch((error) => {
        // handle error
        console.log(error);
      })
      .then(()  => {
        // always executed
      });
    },

    // initReloj(){
    //   const momentoActual = new Date()
    //   const horaLocal = momentoActual.getHours()
    //   const minutoLocal = momentoActual.getMinutes()
    //   const segundoLocal = momentoActual.getSeconds()
    //   const diaActual = moment(momentoActual).format().substr(0,10)
    //   let tareas  = this.getTareas
    //   for(const i in tareas){
    //     const { hora, notificada, motivo, folio, idformulario, dia } = tareas[i]
    //     const horaTarea   = moment(hora,'HH:mm:ss')
    //     const horaSistema = moment(horaLocal + ':' + minutoLocal + ':00','HH:mm:ss')
    //     if(horaTarea <= horaSistema && !notificada && dia == diaActual){

    //       const folio2 = folio ? folio : idformulario
    //       const greeting = new Notification(folio2,{
    //         body: motivo,
    //         icon: 'https://web-back-inbi.club/iconosclima/info.png',
    //         vibrate: [200, 100, 200],
    //         requireInteraction: true
    //       });
    //       tareas[i].notificada = true
    //     }
    //   }

    //   this.addTareas(tareas)
    //   setTimeout(this.initReloj,1000)
    // },

    getMiCalendario( ){
      // Obtener todos los ciclos
      this.$http.get('micalendario/' + this.getdatosUsuario.iderp ).then(response=>{
        this.events = response.data
        this.cargar = false
        const momentoActual = new Date();
        const horaLocal = momentoActual.getHours();
        const minutoLocal = momentoActual.getMinutes();
        const segundoLocal = momentoActual.getSeconds();
        const diaActual = moment(momentoActual).format().substr(0, 10);

        let tareas  = response.data.filter( el => el.hoy == 1 )
        this.actividadesHoy = response.data.filter( el => el.hoy == 1 ).length

        for (const tarea of tareas ) {
          const { hora, notificada, nota, dia, name } = tarea;
          const horaTarea = moment(hora, 'HH:mm:ss');
          const horaSistema = moment(`${horaLocal}:${minutoLocal}:00`, 'HH:mm:ss');

          // Calcular la diferencia en minutos entre la hora de la tarea y la hora actual
          const diferenciaMinutos = horaTarea.diff(horaSistema, 'minutes');

          // Notificar si la diferencia es 0 (hora exacta) o 5 minutos antes, y no se ha notificado antes
          if ((diferenciaMinutos === 0 || diferenciaMinutos === 5) && !notificada && dia === diaActual) {
            const greeting = new Notification(name, {
              body: nota,
              icon: 'https://web-back-inbi.club/iconosclima/info.png',
              vibrate: [200, 100, 200],
              requireInteraction: true
            });
          }
        }
      }).catch( error =>{
        this.validarError( error.response.data.message )
      }).finally( () => { this.cargar = false })
    },


    initReloj(){
      setInterval(() => {
        this.getMiCalendario()
      }, 60000);
    },

    getTareasProgramadas ( ) {
      this.addTareas([])

      this.tareas_programadas = []
      this.countTareas = 0
      this.$http.get('prospectos.tarea.usuario/' + this.getdatosUsuario.iderp).then((response) => {
        this.tareas_programadas = response.data
        // Vamos a agregar el campo de tarea programada
        for(const i in this.tareas_programadas){
          this.tareas_programadas[i].notificada = false
        }
        this.addTareas(this.tareas_programadas)
        this.drawer2 = !this.drawer2

        const tareasHoy = this.tareas_programadas.filter(el=> { return el.hoy == 1})
        this.countTareas = tareasHoy.length

        return
      }).catch(error=>{
        // INdicamos que hay un error en el back
        if(error.body.message){
          this.error = error.body.message
        }else{
          this.error = error.body
        }
        this.errorBack = true
        this.overlay = false
        this.dialogError = true
      })
    },

    getTareasProgramadasFormulario ( ) {
      this.addTareas([])

      this.tareas_programadas = []
      this.countTareas = 0
      this.$http.get('reclutadora.tarea.usuario/' + this.getdatosUsuario.iderp).then((response) => {
        this.tareas_programadas = response.data
        // Vamos a agregar el campo de tarea programada
        for(const i in this.tareas_programadas){
          this.tareas_programadas[i].notificada = false
        }
        this.addTareas(this.tareas_programadas)
        this.drawer2 = !this.drawer2

        const tareasHoy = this.tareas_programadas.filter(el=> { return el.hoy == 1})
        this.countTareas = tareasHoy.length

        return
      }).catch(error=>{
        // INdicamos que hay un error en el back
        if(error.body.message){
          this.error = error.body.message
        }else{
          this.error = error.body
        }
        this.errorBack = true
        this.overlay = false
        this.dialogError = true
      })
    },

    getComisionesVendedora( ){

      if( this.getdatosUsuario.idpuesto == 18 ){


        this.overlay = true;
        const payload = {
          fecha     : (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
          id_usuario: this.getdatosUsuario.iderp,
        }

        this.$http.post("encargadas_comisiones.get.comisionesestatus", payload).then((response) => {

          let estatus = 0

          if ( response.data.length > 0){

            estatus = response.data[0].estatus

          } else {

            estatus = 0

          }

          if( [0,4].includes( estatus ) ){

            this.paramsComisiones.estatus = true

          }
        }).catch((error) => {
          // INdicamos que hay un error en el back
          if (error.body.message) {
            this.error = error.body.message;
          } else {
            this.error = error.body;
          }
          this.errorBack = true;
          this.overlay = false;
          this.dialogError = true;
        });

      }
    },



    getTicketsPorUsuario( ){

      this.overlay = true;

      if( this.getLogeado ){
        this.$http.get("tickets.usuario.erp/" + this.getdatosUsuario.iderp ).then((response) => {

          if( response.data.length > 5 && ![334,31,28,1932].includes( this.getdatosUsuario.iderp ) ){

            this.paramsTickets.estatus = true

          }


        }).catch((error) => {

          console.log( error )
          // INdicamos que hay un error en el back
          if (error.data.message) {
            this.error = error.data;
          } else {
            this.error = error.data;
          }
          this.errorBack = true;
          this.overlay = false;
          this.dialogError = true;
        });
      }


    }

  }
}
