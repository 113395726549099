<template>
  <!-- <v-snackbar
    v-model="alertaNuevaVenta.estatus"
    right
    top
    vertical
    color="white"
    class="text-center mb-0 pt-2 black--text"
    :timeout="40000"
  > -->
  <transition name="slide-y">
    <v-snackbar
      v-model="alertaNuevaVenta.estatus"
      right
      top
      vertical
      color="white"
      class="text-center mb-0 pt-2 black--text"
      :timeout="40000"
      @input="handleSnackbarChange"
    >

      <div class="confetti-container" v-if="showConfetti">
        <div v-for="n in 50" :key="n"
          class="confetti"
          :style="getRandomStyle(n)">
        </div>
      </div>

  	  <v-card class="elevation-0 white textoPoppins">
  	  	<v-card-title primary-title>
  	  		<v-spacer></v-spacer>
  	  		<v-img src="@/assets/nueva_venta.png" max-width="300"></v-img>
  	  		<v-spacer></v-spacer>
  	  	</v-card-title>
  	  	<v-card-text class="black--text text-center text-h6 textoPoppins">
	        <div
	        	v-if="alertaNuevaVenta.mensaje"
	          style="white-space: pre-line"
            class="textoPoppins"
	        >
	          {{ alertaNuevaVenta.mensaje.mensaje }}
	        </div>
  	  	</v-card-text>
  	  </v-card>
      <v-btn
        color="red"
        absolute
        top
        small
        fab
        right
        @click="alertaNuevaVenta.estatus = false"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-snackbar>
  </transition>
</template>
<script>
import axios from 'axios';

	// Componentes
  import Alerta                from '@/components/alertas/Alerta.vue'
  import carga                 from '@/components/alertas/carga.vue';

  import validarErrores        from '@/mixins/validarErrores'
  import { mapGetters, mapActions }  from 'vuex';
  import funcionesExcel        from '@/mixins/funcionesExcel'


	export default {

		components:{
      Alerta,
      carga,
    },

    mixins: [ validarErrores, funcionesExcel ],

		props:[
			'alertaNuevaVenta',
	  ],

    computed: {
      ...mapGetters('login', ['getdatosUsuario', 'getLogeado', 'getEscuela','drawer2','getEstatusLlamada','getNuevosProspectos']),
    },

    data: () => ({
    	// Alertas
    	parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      showConfetti: false,

      respuestaAlerta:false,
      loader: true,
      cargar: false,

      motivo: '',
      sonido: null,
      volumen: 0.1
    }),

    watch: {
      'alertaNuevaVenta.estatus': {
        immediate: true,
        handler(newVal) {
          if (newVal) {
            this.iniciarConfetti();
            this.reproducirSonido();
          }
        }
      }
    },

    created() {
      this.sonido = document.createElement("audio");
      this.sonido.src = "/cash-register-fake.mp3";
      this.sonido.setAttribute("preload", "auto");
      this.sonido.setAttribute("controls", "none");
      this.sonido.style.display = "none";
      this.sonido.volume = this.volumen;
      document.body.appendChild(this.sonido);
    },

    beforeDestroy() {
      // Limpieza del elemento de audio cuando el componente se destruye
      if (this.sonido) {
        document.body.removeChild(this.sonido);
        this.sonido = null;
      }
    },

    methods:{

      handleSnackbarChange(value) {
        if (value) {
          this.reproducirSonido();
        }
      },

      reproducirSonido() {
        if (this.sonido) {
          this.sonido.volume = this.volumen;
          this.sonido.play().then(() => {
          }).catch((error) => {
            console.error("Error al reproducir el audio:", error);
          });
        }
      },

      cerrar() {
        if (this.sonido) {
          this.sonido.pause();
          this.sonido.currentTime = 0;
        }
        this.showConfetti = false;
        this.alertaNuevaVenta.estatus = false;
      },

      getRandomStyle(i) {
        const random = () => Math.random();
        return {
          transform: `translate3d(${random() * 100}px, ${random() * 100}px, 0)`,
          backgroundColor: `hsl(${random() * 360}deg, 50%, 50%)`,
          animationDelay: `${random() * 2}s`,
          left: `${random() * 100}%`
        };
      },

      iniciarConfetti() {
        this.showConfetti = true;
        setTimeout(() => {
          this.showConfetti = false;
        }, 6000);
      }
    }
	}
</script>

<style scoped>
  .textoPoppins{
    font-family: 'Poppins', sans-serif !important;
    font-size: 18px;
    font-weight: 400;
  }

  .slide-y-enter-active, .slide-y-leave-active {
    transition: transform 0.5s ease;
  }

  .slide-y-enter, .slide-y-leave-to {
    transform: translateY(100%);
  }

  .confetti-container {
    position: absolute;
    width: 100%;
    height: 100%;
    overflow: hidden;
    z-index: 1000;
  }

  .confetti {
    position: absolute;
    width: 10px;
    height: 10px;
    background-color: #f00;
    animation: confetti-fall 3s linear forwards;
  }

  @keyframes confetti-fall {
    0% {
      transform: translateY(-100%) rotate(0deg);
      opacity: 1;
    }
    100% {
      transform: translateY(1000%) rotate(360deg);
      opacity: 0;
    }
  }
</style>
